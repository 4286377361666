import React, { useEffect, useRef, useState } from "react";

import AppContext from "../contexts/appcontext";

import * as Animate from "../base/animate";
import ModalContainerView from "./modalcontainerview";
import ProgressUpdater from "./../base/progressupdater";
import RemoteImage from "../controls/remoteimage";
import ShareMenuView from "./sharemenuview";
import Vector2 from "./../base/math/vector2";
import YouTubePlayer from "./../controls/youtubeplayer";
import { VisibilityState } from "./view";

import "./../../css/workitemview.css";

/**
 * Returns whether the string is null or empty.
 * @returns {Boolean}
 */
function isNullOrEmpty( text ) {
   return text == null || typeof text === "undefined" || /^\s*$/.test( text );
}

/**
 * View for displaying a WorkItem.
 */
const WorkItemView = React.forwardRef( ( props, forwardedRef ) => {
   const appContext = new AppContext();
   
   const contentRef = ( typeof forwardedRef !== "undefined" && forwardedRef != null ) ? forwardedRef : useRef( null );
   
   const headerRef = useRef( null );
   
   const rightSideRef = useRef( null );
   
   /**
    * Reference to the hero element.
    */
   const heroRef = useRef( null );
   
   /**
    * True if the video is ready to be played, false otherwise.
    */
   const [ videoIsReady, setIsVideoReady ] = useState( false );
   
   /**
    * True if the video should be playing (when it's ready), false otherwise.
    * @type {Boolean}
    */
   const [ isVideoPlaying, setIsVideoPlaying ] = useState( true );
   
   /**
    * True if all the images are read to be displayed, false otherwise.
    */
   const [ imagesReady, setImagesReady ] = useState( false );
   
   /**
    * Used to track total image loading progress and synchronize unhiding.
    */
   const [ imageProgress ] = useState( new ProgressUpdater() );
   
   const item = props.item;
   
   /**
    * Updates the images loading progress.
    */
   function update_progress( progress, id ) {
      imageProgress.use( progress, id );
      
      if ( imageProgress.isComplete ) {
         setImagesReady( true );
      }
   }
   
   /**
    * Process the share button
    */
   function handle_share( event ) {
      const rect = event.target.getBoundingClientRect();
      const location = new Vector2( rect.left + rect.width / 2, rect.top + rect.height / 2 );
      
      const url = `${document.location.origin}/work/${ item.slug }`;
      
      const icon = event.target.querySelector( ".icon-share-container" );
      if ( icon ) {
         Animate.animation( icon, "bounce" );
      }
      
      const dismissMenu = () => {
         appContext.removeOverlay( "sharemenu" );
      };
      
      appContext.addOverlay( (
         <ModalContainerView onDismiss={ dismissMenu }>
            <ShareMenuView start={ location } url={ url }/>
         </ModalContainerView>
      ), "sharemenu" );
   }
   
   /**
    * Returns a component consisting of a header and a list.
    *
    * @param {String} title Title for the list.
    * @param {Array<String>} elements Elements to be displayed in the list.
    *
    * @returns {React.Component}
    */
   function titledList( title, elements ) {
      const hasElements = elements.reduce( ( element, result ) => {
         return result && !isNullOrEmpty( element );
      }, elements.length > 0 );
      
      return (
         <>
            { hasElements &&
               <>
                  <h2>{ title }</h2>
                  <ul>
                     {
                        elements.map( element => (
                           <li key={ element }>{ element }</li>
                        ) )
                     }
                  </ul>
               </>
            }
         </>
      );
   }
   
   useEffect( () => {
      if ( heroRef.current && videoIsReady ) {
         Animate.transition( heroRef.current, "-hidden" );
      }
   }, [ heroRef, videoIsReady ] );
   
   useEffect( () => {
      if ( headerRef.current && rightSideRef.current ) {
         Animate.transition( headerRef.current, "-no-border" );
         Animate.transition( rightSideRef.current, "-no-border" );
      }
   }, [ headerRef, rightSideRef ] );
   
   useEffect( () => {
      if ( contentRef.current ) {
         const view = contentRef.current;
         appContext.ignoreElementForSectionScrolling( view, true );
         
         return function cleanup() {
            appContext.ignoreElementForSectionScrolling( view, false );
         }
      }
   }, [ contentRef ] );
   
   const hasVideo = item && !/^\s*$/.test( item.videoUrl );
   const hasImage = item.imageUrls && item.imageUrls.length > 0;
   
   const canVideoPlay = videoIsReady && isVideoPlaying && ( props.visibility == VisibilityState.MOVING_IN || props.visibility == VisibilityState.IN );
   
   return (
      <div ref={ contentRef } className="workitemview">
         <div ref={ heroRef } className="header hidden">
            { hasVideo &&
               <YouTubePlayer
                  src={ item.videoUrl }
                  onReady={ () => setIsVideoReady( true ) }
                  playing={ canVideoPlay }
                  controls={ true }
                  onTogglePlayback={ () => setIsVideoPlaying( prev => !prev ) } />
            }
         </div>
         
         <section className="details">
            <div>
               <h1 ref={ headerRef }>{ item.name }</h1>
               {
                  item.description.map( ( text, index ) => (
                     <p key={ index }>{ text }</p>
                  ) )
               }
            </div>
            
            <div ref={ rightSideRef }>
               <div>
                  { titledList( `client${ ( item.clients.length > 1 ) ? "s" : "" }`, item.clients ) }
                  
                  { titledList( "type", item.types ) }
                  
                  { titledList( "technology", item.services ) }
               </div>
               
               <div>
                  { navigator.clipboard &&
                     <button onClick={ handle_share }>
                        <div className="icon-share-container">
                           <div className="icon-share"></div>
                           <p>share</p>
                        </div>
                     </button>
                  }
               </div>
            </div>
         </section>
         
         { hasImage &&
            <section>
               {
                  item.imageUrls.map( url => (
                     <RemoteImage key={ url } src={ url } visible={ imagesReady } onProgress={ update_progress } />
                  ) )
               }
            </section>
         }
      </div>
   );
} );

export default WorkItemView;
