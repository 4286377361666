import React, { useState } from "react";

import * as AppContext from './contexts/appcontext';

import AppView from './views/appview';

import './../css/theme.css';
import './../css/app.css';
import theme from './theme';

const initialAppState = Object.freeze({
  ...AppContext.initialState,
});

function App() {
   const [ appState, appStateDispatch ] = React.useReducer( AppContext.reducer, initialAppState );
   
   useState( () => {
      const buildDate = new Date( BUILD_ENV_BUILDTIME );
      const buildDateText = buildDate.toLocaleString( navigator.language, {
         dateStyle: "long",
         timeStyle: "short"
      } );
      
      const year = ( new Date() ).getFullYear();
      
      console.log( `Build: ${ buildDateText }\n© Pixel and Texel, 2011-${ year }.` );
   }, [] );
   
   return (
      <div className="app">
         <AppContext.context.Provider value={ [ appState, appStateDispatch ] }>
            <AppView />
         </AppContext.context.Provider>
      </div>
   );
}

export default App;
