class CompanyItem {
  constructor(name, imageUrl, height, width) {
    this.name = name;
    this.imageUrl = imageUrl;
    this.height = height;
    this.width = width;
  }
}

export default CompanyItem;
