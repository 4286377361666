import React, { useEffect, useRef, useState } from "react";

import * as Animate from "./../base/animate";
import * as Delay from "./../base/delay";

import LogoHopeFoundation1x from "./../../media/causes/hope_foundation/bitmap.png";
import LogoHopeFoundation2x from "./../../media/causes/hope_foundation/bitmap@2x.png";
import LogoHopeFoundation3x from "./../../media/causes/hope_foundation/bitmap@3x.png";

import LogoLoveLandFoundation1x from "./../../media/causes/love_land_foundation/bitmap.png";
import LogoLoveLandFoundation2x from "./../../media/causes/love_land_foundation/bitmap@2x.png";
import LogoLoveLandFoundation3x from "./../../media/causes/love_land_foundation/bitmap@3x.png";

import LogoOperationKindness1x from "./../../media/causes/operation_kindness/bitmap.png";
import LogoOperationKindness2x from "./../../media/causes/operation_kindness/bitmap@2x.png";
import LogoOperationKindness3x from "./../../media/causes/operation_kindness/bitmap@3x.png";

import LogoTrevorProject1x from "./../../media/causes/trevor_project/TrevorProject.png";
import LogoTrevorProject2x from "./../../media/causes/trevor_project/TrevorProject@2x.png";
import LogoTrevorProject3x from "./../../media/causes/trevor_project/TrevorProject@3x.png";

import LogoUnicef1x from "./../../media/causes/unicef/Unicef.png";
import LogoUnicef2x from "./../../media/causes/unicef/Unicef@2x.png";
import LogoUnicef3x from "./../../media/causes/unicef/Unicef@3x.png";

import ClutchLogo from "./../../media/awards/clutch-logo.png";
import IndigoLogo from "./../../media/awards/indigo-logo.png";
import ManifestLogo from "./../../media/awards/manifest-logo.png";
//import TSCLogo from "./../../media/awards/tsc-logo.png";
import WebExcellenceLogo from "./../../media/awards/web-excellence-logo.png";
import DrumLogo from "./../../media/awards/drum-logo.png";

import "./../../css/aboutview.css";

const causeDefs = [
   {
      name: "Hope Foundation",
      url: "https://www.projecthopesc.org",
      images: [
         LogoHopeFoundation1x,
         LogoHopeFoundation2x,
         LogoHopeFoundation3x
      ]
   },
   {
      name: "Love Land Foundation",
      url: "https://thelovelandfoundation.org",
      images: [
         LogoLoveLandFoundation1x,
         LogoLoveLandFoundation2x,
         LogoLoveLandFoundation3x
      ]
   },
   {
      name: "Operation Kindness",
      url: "https://www.operationkindness.org",
      images: [
         LogoOperationKindness1x,
         LogoOperationKindness2x,
         LogoOperationKindness3x
      ]
   },
   {
      name: "Trevor Project",
      url: "https://www.thetrevorproject.org/",
      images: [
         LogoTrevorProject1x,
         LogoTrevorProject2x,
         LogoTrevorProject3x
      ]
   },
   {
      name: "Unicef",
      url: "https://www.unicef.org/",
      images: [
         LogoUnicef1x,
         LogoUnicef2x,
         LogoUnicef3x
      ]
   }
];

const awardDefs = [
   {
      name: "Indigo",
      url: "https://www.indigoawards.com/winners/4722",
      image: IndigoLogo
   },
   {
      name: "The Manifest",
      url: "https://themanifest.com/app-development/gaming/companies/dallas",
      image: ManifestLogo
   },
   {
      name: "Clutch",
      url: "https://clutch.co/press-releases/awards-top-b2b-companies-texas-2021",
      image: ClutchLogo
   },
   {
      name: "Web Excellence Awards",
      url: "https://we-awards.com/winner/gm-chevrolet-silveradoev-ar/",
      image: WebExcellenceLogo
   },
   // {
   //    name: "Top Software Companies",
   //    url: "https://topsoftwarecompanies.co/local-firms/ar-app-development",
   //    image: TSCLogo
   // },
   {
      name: "The Drum",
      url: "https://www.thedrum.com/news/2022/12/06/why-marriott-used-digital-screens-teleport-its-hq-lobby-new-climes",
      image: DrumLogo
   }

];

const SourceImageSet = React.forwardRef( ( props, forwardedRef ) => {
   if ( props.images.length > 1 ) {
      return (
         <img ref={ forwardedRef } alt={ props.alt } src={ props.images[ 0 ] } srcSet={
            props.images.map( ( source, index ) => `${ source } ${ index + 1 }x` )
            .join( ", " ) 
         }/>
      );
   }
   else {
      return (
         <img ref={ forwardedRef } alt={ props.alt } src={ props.images[ 0 ] }/>
      );  
   }
} );

const AboutView = React.forwardRef( ( props, forwardedRef ) => {
   const [ intersectionObserver ] = useState( new IntersectionObserver( handle_interaction, {
      root: null,
      rootMargin: "0px",
      threshold: 0.5
   } ) );
   
   /**
    * References to the stats page element.
    */
   const statsRef = useRef( null );
   
   function handle_interaction( entries, observer ) {
      entries.forEach( entry => {
         if ( entry.intersectionRatio > 0 ) {
            observer.unobserve( entry.target );
            
            const categories = Array.from( entry.target.querySelectorAll( ":scope > div" ) );
            
            if ( categories.length > 1 ) {
               const onStep = function onstep( element ) {
                  const p = element.querySelector( ":scope > p:first-of-type" );
                  const plus = p.querySelector( ":scope > span > div" );
                  
                  Animate.transition( plus, "-out" );
                  Animate.transition( p, "-hidden" );
                  // Delay.wait( 0.1 )
                  // .then( () => Animate.transition( p, "-hidden" ) );
               }
               
               const remainingCategories = categories.slice( 1 );
               let index = 0;
               
               const intervalId = setInterval( () => {
                  onStep( remainingCategories[ index ] );
                  
                  index += 1;
                  
                  if ( index >= remainingCategories.length ) {
                     clearInterval( intervalId );  
                  }
               }, 200 );
               
               onStep( categories[ 0 ] );
            }
         }
      } );
   }
   
   useEffect( () => {
      if ( statsRef.current ) {
         const stats = statsRef.current;
         
         intersectionObserver.observe( stats );
         
         return function cleanup() {
            intersectionObserver.unobserve( stats );
         }
      }
   }, [ statsRef ] );
   
   return (
      <div ref={ forwardedRef } className="aboutview">
         <section>
            <div>
               <h2>we are</h2>
               <p>
                  A creative digital studio that produces state-of-the-art solutions and engagement for top brands. Employing emerging XR, WebGL, Gaming and Mobile technology, we push the limits of what is possible across experiential design, entertainment, marketing, architecture, e-commerce and enterprise platforms.<br/><br/>
                  
                  Our leadership is born out of the gaming industry, working for Nintendo, Ubisoft, Hasbro, Meta, Oculus, Qualcomm, Reel FX, Flight School, ID software, Konami, Zynga, and EA Sports.
               </p>
            </div>
            
            <div>
               <div>
                  <h3>Our Story</h3>
                  <ul>
                     <li>Founded in 2011</li>
                  </ul>
               </div>
               
               <div>
                  <h3>Headquaters</h3>
                  <ul>
                     <li>Dallas, TX</li>
                  </ul>
               </div>
            </div>
         </section>
         
         <section>
            <h2>we have</h2>
            
            <div ref={ statsRef } className="stats">
               {
                  [
                     [ 160, "Engineering Experience" ],
                     [ 101, "Gaming Experience" ],
                     [ 75, "3D Art Experience" ]
                  ].map( def => (
                     <div key={ def[ 1 ] }>
                        <p className="hidden">{ def[ 0 ] }<span><div className="plus-container out"><div className="plus"></div></div></span></p>
                        <p>{ `Years of Combined ${ def[ 1 ] }` }</p>
                     </div>
                  ) )
               }
            </div>
         </section>
         
         <section>
            <h2>awards</h2>
            
            <div className="logos">
               {
                  awardDefs.map( def => (
                     <div key={ def.name }>
                        <a href={ def.url }>
                           <img alt={ `${ def.name } Logo` } src={ def.image }/>
                        </a>
                     </div>
                  ) )
               }
            </div>
         </section>
         
         <section>
            <h2>we believe in</h2>
            
            <h3>Building Top-Of-The-Line experiences with cutting-edge technology, especially those with interesting technical challenges.</h3>
            <p>
               Our staff includes software and game engineers, 3D artists, project managers, producers, mathematicians, video editors, and more. A varied team with broad experience offers you complete product creation, in one shop.
            </p>
            
            <h3>Investing in ourselves to challenge and grow both our people and our experiences.</h3>
            <p>
               From engineering to admin, we push ourselves to constantly improve ourselves and our processes. By keeping ourselves cutting-edge in all things, we know and use the right tools for building the best products.
            </p>
            
            <h3>Fostering a diverse, inclusive, healthy workplace filled with smart, passionate people.</h3>
            <p>
               When we say we value our employees, we don’t mean Pizza Fridays and then back to the grindstone. We curate a great working environment where our employees can create the kind of career they envision in an inclusive workplace. Our dedication to our team includes not just great benefit and compensation packages, but also employee-led flexible work schedules, varied training and development opportunities, and more.
            </p>
         </section>
         
         <section>
            <h2>causes we support</h2>
            
            <div className="logos">
               {
                  causeDefs.map( def => (
                     <div key={ def.name }>
                        <a href={ def.url }>
                           <SourceImageSet alt={ `${ def.name } Logo` } images={ def.images }/>
                        </a>
                     </div>
                  ) )
               }
            </div>
         </section>
      </div>
   );
} );

export default AboutView;
