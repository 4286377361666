class Publisher {
   constructor() {
      this._listeners = [];
   }
   
   addListener( handler ) {
      const index = this._listeners.indexOf( handler );
      
      if ( index == -1 ) {
         this._listeners.push( handler );
      }
   }
   
   removeListener( handler ) {
      const index = this._listeners.indexOf( handler );
      
      if ( index != -1 ) {
         this._listeners.splice( index, 1 );
      }
   }
   
   publish( ...args ) {
      this._listeners.forEach( l => l( ...args ) );
   }
}

export default Publisher;
