import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";

import AppContext, { LayoutSizeClass } from "./../../contexts/appcontext";
import { VisibilityState } from "./../view";

const Container = styled.div`
  --bold-selected-weight: 700;
  --selected-font-color: #eadc5c;
  --dimmed-font-color: #575757;

  display: flex;
  font-family: Futura PT;
  
  background-color: black;
  height: 100%;
  justify-content: end;
  
  justify-content: center;
  align-items: end;
  
  overflow: scroll;
`;

const Items = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: 85px;
  
  @media screen and (max-width: 1024px) {
    :not(.compact &) {
      grid-auto-rows: 42px;
    }
  }
  
  @media screen and (min-width: 1025px) and (max-width: 1365px) {
    :not(.compact &) {
      grid-auto-rows: 50px;
    }
  }
  
  @media screen and (min-width: 1366px) and (max-width: 1919px) {
    :not(.compact &) {
      grid-auto-rows: 62px;
    }
  }
  
  @media screen and (min-width: 450px) {
    .compact & {
      grid-auto-rows: 48px;
    }
  }
  
  width: 50%;
  margin-bottom: 5vh;
  
  .compact & {
    width: 70%;
    margin-bottom: 8vh;
  }
  
  @supports (margin-bottom: max(0px)) {
    .compact & {
      margin-bottom: max(8vh, calc(env(safe-area-inset-bottom) + 20px));
    }
  }
  
  & > div {
    display: flex;
    align-items: center;
  }
  
  .compact & > div {
    height: 10vh;
  }
`;

const InfoItem = styled.p`
  font-size: 46pt;
  font-weight: ${(props) => (props.descriptionViewIsVisible || props.selectedItem !== props.item ? '200' : 'var(--bold-selected-weight)')};
  color: ${(props) => (props.selectedItem === props.item ? 'var(--selected-font-color)' : props.descriptionViewIsVisible ? 'var(--dimmed-font-color)' : 'white' )};
  
  margin: 0;
  padding: 0;
  
  transition: font-weight 0.1s ease-in-out, color 0.1s ease-in;

  :hover {
    font-weight: ${(props) => (!props.descriptionViewIsVisible ? "var(--bold-selected-weight)" : "200")};
    
    ${(props) => {      
      if (props.selectedItem != props.item) {
        return css`
          color: white;
        `;
      }
    }}
  }
  
  @media screen and (max-width: 1024px) {
    :not(.compact &) {
      font-size: 20pt;
    }
  }
  
  @media screen and (min-width: 1025px) and (max-width: 1919px) {
    :not(.compact &) {
      font-size: 26pt;
    }
  }
  
  @media screen and (min-width: 450px) {
    .compact & {
      font-size: 30pt;
    }
  }
`;

const InfoList = React.forwardRef((props, forwardedRef) => {
  const appContext = new AppContext();
  
  // const [deselectOnVisible, setDeselectOnVisible] = useState( false );
  
  const selectedItem = (typeof props.selection !== "undefined" && props.items && props.selection < props.items.length) ? props.items[props.selection] : undefined;
  
  // un-select the current selection when returning while in compact navigation.
  
  useEffect(() => {
    if (appContext.layoutSizeClass == LayoutSizeClass.COMPACT && props.visibility == VisibilityState.MOVING_IN && props.selection >= 0 && props.pushed !== true) {
      // setDeselectOnVisible(true);
      props.setSection(-1);
    }
  }, [props.selectedIdx, props.visibility, props.pushed]);
  
  // useEffect(() => {
  //   if (appContext.layoutSizeClass == LayoutSizeClass.COMPACT && props.visibility == VisibilityState.IN && deselectOnVisible) {
  //     props.setSection(-1);
  //     setDeselectOnVisible(false);
  //   }
  // }, [deselectOnVisible, props.visibility]);
  
  return (
    <Container ref={forwardedRef}>
      <Items>
        { props.items &&
          props.items.map((name,index) => (
            <div key={name}>
              <InfoItem key={name} item={name} descriptionViewIsVisible={props.descriptionViewIsVisible} selectedItem={selectedItem} onClick={() => props.setSection(index)}>
                {name}
              </InfoItem>
            </div>
          ))
        }
      </Items>
    </Container>
  );
});

export default InfoList;
