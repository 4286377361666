import React, { useEffect, useRef, useState } from "react"

import { useRefState } from "./../reactutilities";

import "./../extensions/array";

import "./../../css/control-spinner.css";

const spinnerColors = [ "blue", "magenta", "yellow", "white" ];

/**
 * Component with a continuously spinning indicator.
 */
const Spinner = React.forwardRef( ( props, forwardedRef ) => {
   /**
    * Order of colors that will cycled through.
    * @type {Array<String>}
    */
   const [ colors ] = useState( () => spinnerColors.shuffle() );
   
   /**
    * Index of the current color.
    * @type {Number}
    */
   const [ colorIndexRef, setColorIndex ] = useRefState( 0 );
   
   /**
    * Reference to the last layer element.
    * @type {HTMLElement}
    */
   const lastLayerRef = useRef( null );
   
   /**
    * Called after each iteration of the spinner's animation.
    */
   function onAnimationIteration() {
      setColorIndex( ( colorIndexRef.current + 1 ) % 4 );
   }
   
   useEffect( () => {
      if ( lastLayerRef.current ) {
         const element = lastLayerRef.current;
         element.addEventListener( "animationiteration", onAnimationIteration );
         
         return function cleanup() {
            element.removeEventListener( "animationiteration", onAnimationIteration );
         }
      }
   }, [ lastLayerRef ] );
   
   const isActive = props.active === true ? true : false;
   const isSmall = props.small === true ? true : false;
   const className = `control spinner ${ colors[ colorIndexRef.current ] }${ (!isSmall) ? "" : " small" }${ ( isActive ? " active" : "" ) }`;
   
   return (
      <div ref={ forwardedRef } className={ className }>
         <div ref={ lastLayerRef } className="layer three">
            <div className="indicator-container">
               <div className="indicator"></div>
            </div>
         </div>
         <div className="layer two">
            <div className="indicator-container">
               <div className="indicator"></div>
            </div>
         </div>
         <div className="layer one">
            <div className="indicator-container">
               <div className="indicator"></div>
            </div>
         </div>
      </div>
   );
} );

export default Spinner;
