import React from 'react';
import styled from 'styled-components';

/*
    Two Components
   1. Scrollable list of work
   2. The work item

*/

const MainContainer = styled.div`
  background-color: blue;
  flex: 3;
  padding: 0;
  display: ${(props) => (props.$visible ? 'flex' : 'none')};
`;

const ContentContainer = styled.div`
  display: flex;
  width: 100%;
  background-color: red;
`;

const Title = styled.h2`
  margin: 0;
`;

const HorizontalDivider = styled.hr`
  width: 60px;
  justify-content: start;
  height: 4px;
  border: 0;
  margin: 0;
  background-color: #46a4d9;
`;

const VerticalDivider = styled.hr`
  height: 100%;
  border: 0;
  margin: 0;
  ${'' /* background-color: ${(props) => props.theme.colors.styleGuideBlue}; */}
  background-color: blue;
  width: 25px;
`;

const RightTitle = styled.h3``;

const Description = styled.p``;

export default function WorkSection(props) {
  // const [workDetail, setWorkDetail] = useState(detail)

  return (
    <MainContainer $visible={props.visible}>
      <ContentContainer>
        <div>
          <Title>Sections</Title>
          <HorizontalDivider />
          <Description>
            Project description project decriptionProject description project
            decriptionProject description project decriptionProject description
            project decriptionProject description project decriptionProject
            description project decriptionProject description project
            decriptionProject description project decriptionProject description
            project decriptionProject description project decriptionProject
            description project decriptionProject description project
            decriptionProject description project decriptionProject description
            project decriptionProject description project decriptionProject
            description project decriptionProject description project
            decriptionProject description project decription
          </Description>
        </div>
        <VerticalDivider props={props} />
        <div>
          <RightTitle>Client</RightTitle>
          <Description></Description>
          <RightTitle>Platform</RightTitle>
          <Description></Description>
          <RightTitle>Services</RightTitle>
          <Description></Description>
        </div>
      </ContentContainer>
    </MainContainer>
  );
}
