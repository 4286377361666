import React, { useEffect, useState, useRef } from "react";

import AppContext from "./../contexts/appcontext";

import { useRefState } from "./../reactutilities";

import "./../extensions/string-hash";

import "./../../css/control-youtubeplayer.css";
import "./../../css/videoplayer-controls.css";

/**
 * YouTube API.
 * @type {Object}
 */
let YouTubeApi = null;

/**
 * Default parameters applied to all videos.
 * @type {Map<String, Any>}
 */
const defaultParameters = new Map( [
   // [ "autoplay",        1 ],
   [ "cc_load_policy",  0 ],
   [ "controls",        0 ],
   [ "disablekb",       1 ],
   // [ "enablejsapi",     1 ],
   [ "fs",              0 ],
   [ "iv_load_policy",  0 ],
   [ "loop",            1 ],
   [ "modestbranding",  1 ],
   [ "origin",          window.location.host ],
   [ "playsinline",     1 ],
   [ "rel",             0 ],
   [ "showinfo",        0 ], // deprecated
   [ "quality",         "hd720" ] // not working?
] );

const EmbeddedPlayer = React.forwardRef( ( props, forwardedRef ) => {
   /**
    * Identifier for the the YouTube video.
    * @type {String}
    */
   const [ videoId, setVideoId ] = useState( null );
   
   /**
    * Unique hash generated for the video identifier.
    * @type {String}
    */
   const [ videoIdHash, setVideoIdHash ] = useState( undefined );
   
   function on_ready( event ) {
      if ( props.onReady ) {
         props.onReady( event );
      }
   }
   
   function on_state_change( event ) {
      if ( props.onStateChange ) {
         props.onStateChange( event );
      }
   }
   
   useEffect( () => {
      if ( props.src && !/^\s*$/.test( props.src ) ) {
         const youtubeUrlPattern = /^https?\:\/\/(?:(?:(?:w{3}\.)?youtube\.com\/watch\?v=)|(?:youtu\.be\/))([0-9a-zA-Z-_]+)$/;
         
         const match = youtubeUrlPattern.exec( props.src )
         
         if ( match ) {
            const id = match[1];
            setVideoId( id );
            
            setVideoIdHash( id.hashCode.toString() );
         }
         else if ( BUILD_ENV_DEBUG ) {
            console.warn( `'${ props.src }' is not a valid YouTube URL.` );
         }
      }
      else {
         setVideoIdHash( undefined );
      }
   }, [ props.src ] );
   
   useEffect( () => {
      if ( videoId && videoIdHash && YouTubeApi) {
         const parameters = Object.assign( {}, Object.fromEntries( defaultParameters.entries() ), { mute: 1 } );
         
         const player = new YouTubeApi.Player( videoIdHash, {
            videoId: videoId,
            host: "https://www.youtube-nocookie.com",
            playerVars: parameters,
            events: {
               onReady: on_ready,
               onStateChange: on_state_change
            }
         } );
      }
   }, [ videoId, videoIdHash, YouTubeApi ] );
   
   return (
      <div ref={ forwardedRef } id={ videoIdHash } className="video"></div>
   );
} );

/**
 * Video player for YouTube videos.
 */
const YouTubePlayer = React.forwardRef( ( props, forwardedRef ) => {
   const appContext = new AppContext();
   
   /**
    * Reference to the components root element.
    */
   const componentRef = ( typeof forwardedRef !== "undefined" && forwardedRef != null ) ? forwardedRef : useRef( null );
   
   /**
    * YouTube player.
    * @type {YT.Player}
    */
   const [ player, setPlayer ] = useRefState( null );
   
   /**
    * True if the video's audio is muted, false otherwise.
    * @type {Boolean}
    */
   const [ isMuted, setIsMuted ] = useState( appContext.videosMuted );
   
   /**
    * Returns whether the video is currently muted or not.
    *
    * @returns {Boolean} True if the video is muted, false otherwise.
    */
   function isVideoMuted() {
      return ( props.muted === false || isMuted ) ? false : true;
   }
   
   /**
    * Returns whether the video is paused or not.
    *
    * @returns {Boolean} True if paused, false otherwise.
    */
   function isVideoPaused() {
      return props.playing === false;
   }
   
   function handle_mute_button( event ) {
      event.stopPropagation();
      
      setIsMuted( prev => !prev );
      appContext.videosMuted = !isMuted;
   }
   
   function handle_playback_button() {
      if ( props.onTogglePlayback ) {
         props.onTogglePlayback();
      }
      else if ( BUILD_ENV_DEBUG ) {
         console.warn( `Can't handle pause, props.onTogglePlayback was not defined.` );
      }
   }
   
   /**
    * Called when the player is ready.
    */
   function on_ready( event ) {
      setPlayer( event.target );
      
      if ( props.onReady ) {
         props.onReady();
      }
   }
   
   /**
    * Called when the player changes state.
    */
   function on_state_change( event ) {
      if ( event.data != null && event.data === YouTubeApi.PlayerState.ENDED && player.current != null ) {
         player.current.playVideo();
      }
   }
   
   useEffect( () => {
      if ( player.current != null ) {
         if ( props.playing === true && player.current.getPlayerState() != YouTubeApi.PlayerState.PLAYING ) {
            player.current.playVideo();
         }
         else {
            player.current.pauseVideo();
         }
      }
   }, [ props.playing, player ] );
   
   useEffect( () => {
      if ( player.current != null ) {
         if ( isVideoMuted() ) {
            player.current.mute();
         }
         else {
            player.current.unMute();
         }
      }
   }, [ player, isMuted, props.muted ] );
   
   const playbackText = ( isVideoPaused() ) ? "play" : "pause";
   
   return (
      <div ref={ componentRef } className="youtube-player">
         <EmbeddedPlayer
            src={ props.src }
            onReady={ on_ready }
            onStateChange={ on_state_change }/>
            
         { ( props.controls === true ) &&
            <div className="videoplayer-controls">
               <button className={ `${ ( isVideoMuted() ) ? "" : "hidden" }` } onClick={ handle_mute_button }>
                  <div className="status">
                     <div className="icon-container">
                        <div className={ `icon audio-${ ( isVideoMuted() ) ? "off" : "on" }` }></div>
                        <p className="tooltip">{ ( isVideoMuted() ) ? "unmute" : "mute"  }</p>
                     </div>
                  </div>
               </button>
               
               { true &&
                  <button className={ ( !isVideoPaused() ) ? "hidden" : undefined } onClick={ handle_playback_button }>
                     <div className="status">
                        <div className="playback-icon-container">
                           <div className={ `icon ${ playbackText }` }></div>
                           <p className="tooltip">{ playbackText }</p>
                        </div>
                     </div>
                  </button>
               }
            </div>
         }
      </div>
   );
} );

/**
 * Initialize the usage of YouTubePlayers by downloading the YouTube API.
 *
 * @returns {Promise}
 */
function initialize() {
   return new Promise( ( resolve, reject ) => {
      if ( BUILD_ENV_DEBUG ) {
         console.log( "Registering for YouTube API callback…" );
      }
      
      window[ "onYouTubeIframeAPIReady" ] = function onYouTubeIframeAPIReady() {
         if ( "YT" in window ) {
            YouTubeApi = window[ "YT" ];
            
            if ( BUILD_ENV_DEBUG ) {
               console.log( "Registered YouTube API!" );
            }
            
            resolve();
         }
         else {
            reject( new Error( "YouTube API missing." ) );
         }
      }
      
      const ytScript = document.createElement( "script" );
      ytScript.src = "https://www.youtube.com/iframe_api";
      
      const firstScript = document.head.getElementsByTagName( "script" )[ 0 ];
      document.head.insertBefore( ytScript, firstScript );
      if ( BUILD_ENV_DEBUG ) {
         console.debug("onYouTubeIframeAPIReady: " + window[ "onYouTubeIframeAPIReady" ]);
      }
   } );
}

export default YouTubePlayer;

export {
   initialize
}