import React, { useEffect, useRef, useState } from "react";

import "./../../css/control-remoteimage.css";

// TODO: loading indicator? optional? (Billy 9-9-2022)

const RemoteImage = React.forwardRef( ( props, ref ) => {
   /**
    * True if the image has been loaded, false otherwise.
    */
   const [ isLoaded, setIsLoaded ] = useState( false );
   
   /**
    * Reference to the image element.
    */
   const imageRef = useRef( null );
   
   useEffect( () => {
      if ( imageRef.current ) {
         const image = imageRef.current;
         
         if ( !image.complete ) {
            image.addEventListener( "load", event => {
               setIsLoaded( true );
               
               if ( props.onProgress ) {
                  props.onProgress( 1, props.src );
               }
            } );
            
            if ( props.onProgress ) {
               props.onProgress( 0, props.src );
            }
         }
         else {
            setIsLoaded( true );
            
            if ( props.onProgress ) {
               props.onProgress( 1, props.src );
            }
         }
      }      
   }, [ imageRef ] );
   
   const isVisible = ( props.visible === false || !isLoaded ) ? false : true;
   
   return (
      <div ref={ ref } className="control remoteimage">
         <img ref={ imageRef } src={ props.src } className={ ( isVisible ) ? "" : "hidden" }  />
      </div>
   );
} );

export default RemoteImage;
