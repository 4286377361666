import React, { useState } from "react";
import styled from "styled-components";

import AppContext from "./../../contexts/appcontext";

import VideoPlayer from "../../controls/videoplayer";
import { VisibilityState } from "./../view";

import DividerImg from "./../../../media/plus-divider.svg";

const Container = styled.div`
  --bold-shadow-size: 2px;
  --bold-shadow-size-inner: 1px;
  --bold-shadow-blur: 1px;
  --bold-selected-weight: 700;
  --selected-font-color: #eadc5c;
  --background-color: white;

  @keyframes slideInFromLeft {
    0% {
      opacity: 0;
      transform: translateX(+20%);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }

  font-family: Futura PT, serif;
  animation: 1s ease-in-out 0s 1 slideInFromLeft;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  grid-row-gap: 10vh;
  overflow: scroll;
  
  padding-top: 20vh;
  padding-left: 5%;
  padding-right: 5%;
  
  @media screen and (min-width: 1024px) and (max-width: 1365px) {
    padding-left: 4%;
    padding-right: 4%;
  }
  
  .compact & {
    padding-left: unset;
    padding-right: unset;
    
    padding-bottom: 15vh;
  }
  
  @media screen and (min-width: 450px) {
    .compact & {
      padding-left: 10%;
      padding-right: 10%;
    }
  }
  
  box-sizing: border-box;
  
  .compact & {
    padding-top: unset;
  }

  svg {
    width: inherit;
    height: inherit;
  }

  img {
    width: 100%;
    height: auto;
  }
`;

const ServicesItemsContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 15%;
  grid-row-gap: 10vh;
  
  @media screen and (min-width: 1024px) and (max-width: 1365px) {
    grid-column-gap: 12%; 
  }
  
  @media screen and (max-width: 449px) {
    .compact & {
      grid-template-columns: 1fr;
    }
  }
  
  @media screen and (min-width: 451px) {
    .compact & {
      grid-column-gap: 5%;
    }
  }
`;

const ServicesItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const HeaderImage = styled.div`
  width: 100%;
  margin-bottom: 10px;
`;

const Title = styled.h2`
  font-size: 26pt;
  font-weight: 700;
  padding: 0;
  margin: 18px 0 0;
  
  @media screen and (min-width: 1024px) and (max-width: 1365px) {
    font-size: 15pt;
    margin: 10px 0 6px;
  }
  
  @media screen and (min-width: 1366px) and (max-width: 1919px) {
    font-size: 21pt;
  }
  
  @media screen and (max-width: 449px) {
    .compact & {
      padding: 0 30px;
      margin-bottom: 10px;
    }
  }
  
  @media screen and (min-width: 451px) {
    .compact & {
      font-size: 14pt;
    }
  }
`;

const Description = styled.p`
  font-size: 16pt;
  font-weight: 400;
  color: #bfbfbf;
  text-align: justify;
  margin: 0;
  
  @media screen and (min-width: 1024px) and (max-width: 1365px) {
    font-size: 12pt;
  }
  
  @media screen and (min-width: 1366px) and (max-width: 1919px) {
    font-size: 14pt;
  }
  
  @media screen and (max-width: 449px) {
    .compact & {
      padding: 0 30px;
    }
  }
  
  @media screen and (min-width: 450px) {
    .compact & {
      font-size: 17pt;
    }
  }
`;

const BluePixelLineContainer = styled.div`
  width: 100%;
  margin-top: 25px;
`;

const Top = styled.div``;

const Bottom = styled.div`
  @media screen and (max-width: 450px) {
    .compact & {
      display: none;
    }
  }
`;

const SizzleReel = styled.div`
  width: 100%;
`;

const Services = React.forwardRef((props, forwardedRef) => {
  const appContext = new AppContext();
  
  const [isSizzlePlaying, setIsSizzlePlaying] = useState(true);
  
  const canVideoPlay = isSizzlePlaying && (props.visibility == VisibilityState.MOVING_IN || props.visibility == VisibilityState.IN);
  
  return (
    <Container ref={forwardedRef} className="no-scrollbar">
      <SizzleReel>
        {props.sizzleReelVideoSrc && (
          <VideoPlayer
            src={props.sizzleReelVideoSrc}
            height="100%"
            playing={canVideoPlay}
            loop={true}
            controls={ true }
            controls={true}
            onTogglePlayback={() => setIsSizzlePlaying(prev => !prev)}
          />
        )}
      </SizzleReel>
      <ServicesItemsContainer>
        {props.items.map((item, idx) => {
          return (
            <ServicesItem key={idx} item={item}>
              <Top>
                <HeaderImage>
                  <img src={item.headerImage} />
                </HeaderImage>
                <Title>{item.title}</Title>
                <Description>{item.description}</Description>
              </Top>
              <Bottom>
                <BluePixelLineContainer>
                  <DividerImg />
                </BluePixelLineContainer>
              </Bottom>
            </ServicesItem>
          );
        })}
      </ServicesItemsContainer>
    </Container>
  );
});

export default Services;
