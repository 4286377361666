import * as Reducer from "./reducer";
import * as Net from "./../base/net";

/**
 * Base URL for the backend API.
 * @type {String}
 */
const apiUrl = BUILD_ENV_APIURL;

/**
 * Items that will be added to each request.
 * @type {Map}
 */
const defaultRequestOptions = new Map( [
   [ Net.RequestOptions.HEADER, new Map( [
      [ "Authorization", atob( "QmVhcmVyIEhyWDNweC1sNHMtREd5TTZwYXN4VTg1cTA2T3hjTkZ6aVhoUDd2TFZzclU=" ) ]
   ] ) ]
] );

/**
 * A portion of the an API end point.
 */
class EndPointComponent {
   /**
    * Creates a new EndPointComponent.
    *
    * @param {String} template String that defines the partial url for this component along with any required variables.
    * @param {String} method HTTP request method.
    * @param {String} responseType HTTP request response type.
    */
   constructor( template, method = "get", responseType ) {
      this.template = template;
      this.httpMethod = method.toUpperCase();
      this.httpResponseType = responseType;
      
      this.subEndPoints = new Map();
   }
}

// TODO: all these end-points are temporary are only as an example until we
// have our contentful end-points in place (Billy 9-7-2022).

const apiEndPoints = [
   // name,                     template,                          method, response-type
   [ "workitems",                "/entries?content_type=work",      "get" ],
   [ "workitemsorder",           "/entries/B59GAJVbNbk4y7ZGZJmGZ",  "get" ],
   [ "partners",                 "/entries/cWl2IwJZ4xpYPrCl2J96J",  "get" ],
   [ "companies",                "/entries?content_type=company",   "get" ],
   [ "services",                 "/entries?content_type=services",  "get" ],
   [ "servicesorder",            "/entries/6AZrKI5KDwzb4ZYYOa2mGH", "get" ],
   [ "servicessizzlereel",       "/entries/33qPvhTUqlr2bewVj2FA4p", "get" ],
   [ "logoletters",              "/entries/1T0ZQtfiVw2XKpTdgHojFc", "get" ],
   // [ "projects/search",          "/search/{terms}",                 "get" ],
   // [ "project",                  "/project/{project_id}",           "get" ],
   // [ "project/experiences",      "/experiences",                    "get" ],
   // [ "project/tutorials",        "/tutorials",                      "get" ],
   // [ "experience",               "/experience/{experience_id}",     "get" ],
   // [ "experience/content",       "/content?systemType={platform}",  "get",  "arraybuffer" ],
   // [ "experience/details",       "?include_data=true",              "get" ],
   // [ "experience/manualtrigger", "/manualtrigger",                  "get" ],
   // [ "markerimage",              "/markerimage/{image_id}",         "get" ],
   // [ "markerimage/content",      "/content",                        "get",  "blob" ],
   // [ "feeditem",                 "/feeditem",                       "get" ],
   // [ "orderedfeeditem",          "/feeditembyorder?limit=999",      "get" ],
   // [ "searchfeeditems",          "/feeditembyorder?limit=999&tagName={terms}", "get" ],
   // [ "validatepurchase",         "/purchases/validate",             "post" ]
].reduce( ( collection, def ) => {
   const path = def[0];
   const template = def[1];
   const httpMethod = def[2];
   const httpResponseType = def.length > 3 ? def[3] : undefined;
   
   const components = path.split( "/" );
   const container = components.reduce( ( result, curPath ) => {
      if ( result != null ) {
         const subEndPoint = result.subEndPoints.get( curPath );
         if ( subEndPoint ) {
            result = subEndPoint;
         }
         else {
            const newEndPoint = new EndPointComponent( "" );
            result.subEndPoints.set( curPath, newEndPoint );
            
            result = newEndPoint;
         }
      }
      else {
         const endPoint = collection.get( curPath );
         if ( endPoint ) {
            result = endPoint;
         }
         else {
            const newEndPoint = new EndPointComponent( "" );
            collection.set( curPath, newEndPoint );
            
            result = newEndPoint;
         }
      }
      
      return result;
   }, null );
   
   container.template = template;
   container.httpMethod = httpMethod;
   container.httpResponseType = httpResponseType;
   
   return collection;
}, new Map() );

class Request {
   /**
    * Creates a new request.
    *
    * @param {String} method HTTP request method.
    * @param {String} url URL to make the request.
    * @param {Map<Net.RequestOptions, Any>} options Options to pass to the request, such as headers, form data, etc.
    */
   constructor( method, url, options ) {
      this.method = method;
      this.url = url;
      this.options = options;
   }
}

/**
 * Creates a definition for an API request.
 *
 * @param {String} action Path style delimited string defining the type of request.
 * @param {Object} data Object containing variables for the request and form data if required.
 *
 * @return {Request}
 */
function build_request( action, data ) {
   /** @type {String} */
   let url = apiUrl;
   
   /** @Type {String} */
   let httpMethod = "get";
   
   /** @Type {String} */
   let httpResponseType;
   
   /** @type {String} */
   let currentAction;
   
   /** @type {String} */
   let remainingActions = action
   
   const templateVariablePattern = /\{([^}]+)\}/g;
   const usedDataKeys = new Set();
   
   let currentEndPoint = null;
   
   while ( typeof remainingActions !== "undefined" ) {
      [ currentAction, remainingActions ] = Reducer.actionComponents( remainingActions );      

      currentEndPoint = currentEndPoint ? currentEndPoint.subEndPoints.get( currentAction ) : apiEndPoints.get( currentAction );
      if ( currentEndPoint ) {
         let finalized = currentEndPoint.template;
         let offset = 0;
         
         for ( const match of currentEndPoint.template.matchAll( templateVariablePattern ) ) {
            const name = match[1];
            
            // project/{project_id}/tutorial/{tutorial_id}
            // 8 [12], 30 [13]
            // project/123/tutorial/{tutorial_id}
            // 21 [13]
            
            if ( typeof data === "object" && name in data ) {
               const value = data[name];
               usedDataKeys.add( value );
               
               const offsetIndex = match.index + offset;
               
               finalized = finalized.slice( 0, offsetIndex ) + value + finalized.slice( offsetIndex + match[0].length );
               
               offset += match[0].length - value.length;
            }
            else {
               throw new Error( `Could not find key: '${ name }' in data for action: '${ action }'.` );
            }
         }
         
         url += finalized;
         
         httpMethod = currentEndPoint.httpMethod;
         
         if ( typeof currentEndPoint.httpResponseType !== "undefined" ) {
            httpResponseType = currentEndPoint.httpResponseType;
         }
      }
      else {
         throw new Error( `'${ action }' is not a supported request: '${ currentAction }' is not valid.` );
      }
   }
   
   const requestOptions = new Map( defaultRequestOptions.entries() );
   
   // TODO: if the request method is post then clone the data object without the used keys
   // and set the remainder as the form data of the request.
   
   if ( httpMethod.toLowerCase() == "post" && typeof data === "object" && usedDataKeys.size < Object.keys( data ).length ) {
      const formData = Object.fromEntries(
         Object.entries( data ).filter( ( [ key ] ) => !usedDataKeys.has( key ) )
      );
      
      requestOptions.set( Net.RequestOptions.FORMDATA, JSON.stringify( formData ) );
      
      const headers = requestOptions.get( Net.RequestOptions.HEADER );
      headers.set( "Content-Type", "application/json" );
   }
   
   if ( typeof httpResponseType !== "undefined" ) {
      requestOptions.set( Net.RequestOptions.RESPONSE_TYPE, httpResponseType );
   }
   
   const request = new Request( httpMethod, url, requestOptions );
   return request;
}

/**
 * Returns the backend api url for a specific request.
 * 
 * @param {String} action Request to perform as a '/' delimited string.
 * @param {Object} data Object containing any required variables or form data to post.
 *
 * @return {String} URL for the request.
 */
function getRequestUrl( action, data ) {
   const request = build_request( action, data );
   return request.url;
}

/**
 * Performs the request to the backend api.
 *
 * @param {String} action Request to perform as a '/' delimited string.
 * @param {Object} data Object containing any required variables or form data to post.
 *
 * @return {Array} Request promise and abort function.
 */
function makeRequest( action, data, onProgress ) {
   const request = build_request( action, data );
   const [ pending, abort ] = Net.makeRequest( request.url, request.method, request.options, onProgress );
   
   return [ pending, abort ];
}

export {
   getRequestUrl,
   makeRequest
}
