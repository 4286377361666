import React, { useEffect, useRef, useState } from "react";

import { useRefState } from "./../reactutilities";

import AppContext from "./../contexts/appcontext";
import Spinner from "./spinner";

import "./../../css/control-videoplayer.css";
import "./../../css/videoplayer-controls.css";

/**
 * Returns the file extension from the path.
 *
 * @param {String} path Path to return file extension for.
 *
 * @returns {String|null} File extension (without period) if it exists or null otherwise.
 */
function path_file_extension(path) {
   /** @type {String} */
   let fileExtension;
   
   const match = /\.([^\s\.\/]+)$/.exec(path);
   if ( match ) {
      fileExtension = match[1];
   }
   else {
      // default to mp4
      fileExtension = "mp4";
   }
   
   return fileExtension;
}

/**
 * Returns the remaining time as a string for the specified video.
 *
 * @parma {HTMLVideoElement} video Video to get remaining time for.
 *
 * @returns {String} Remaining time as a string.
 */
function remaining_time( video ) {
   const remaining = video.duration - video.currentTime;
   
   const minutes = Math.floor( remaining / 60 );
   const seconds = remaining % 60;
   
   return `${ minutes.toString().padStart( 2, "0" ) }:${ seconds.toString().padStart( 2, "0" ) }`;
}

/**
 * Component that plays a video.
 */
const VideoPlayer = React.forwardRef( ( props, forwardedRef ) => {
   const appContext = new AppContext();
   
   const videoRef = useRef( null );
   
   /**
    * True if the video's audio is muted, false otherwise.
    * @type {Boolean}
    */
   const [ isMuted, setIsMuted ] = useState( appContext.videosMuted );
   
   /**
    * True if the loading indicator is being displayed, false otherwise.
    * @type {Boolean}
    */
   const [ isShowingLoadingIndicator, setIsShowingLoadingIndicator ] = useState( false );
   
   /**
    * Date at which the video player started loading.
    * @type {Date}
    */
   const [ loadStartDate, setLoadStartDate ] = useState( null );
   
   /**
    * Identifier for the loading interval.
    * @type {Number}
    */
   const [ loadingIntervalIdRef, setLoadingIntervalId ] = useRefState( null );
   
   const height = ( typeof props.height === "string" ) ? props.height : null;
   const isLooping = ( typeof props.loop !== "undefined" && props.loop === true ) ? true : false;
   
   const source = props.src;
   const fileExtension = path_file_extension( props.src );
   
   /**
    * Play the video and handle any playback error cases.
    */
   function play_video() {
      if ( videoRef.current ) {
         videoRef.current.play()
         .catch( error => {
            if ( error.name === "NotAllowedError" ) {               
               if ( props.onPermissionRequired ) {
                  const onSuccess = () => {
                     videoRef.current.play()
                     .then( () => {
                        if ( BUILD_ENV_DEBUG ) {
                           console.log( `Resuming video '${ videoRef.current.currentSrc }' at user's request.` );
                        }
                     } );
                  };
                  
                  props.onPermissionRequired( onSuccess );
               }
               else {
                  console.warn( `The video ${ ( BUILD_ENV_DEBUG ) ? `'${ videoRef.current.currentSrc }' ` : "" }requires the user's permission to play, but 'props.onPermissionRequested' was not defined.` );
               }
            }
            else {
               console.error( `Playback of the video is not supported: '${ error }'.` );
            }
         } );
      }
      else {
         console.warn( "There is no video reference to play." );
      }
   }
   
   /**
    * Called when the video can play through.
    */
   function videoCanPlayThrough( event ) {
      const video = event.target;
      
      if ( loadingIntervalIdRef.current != null ) {
         clearInterval( loadingIntervalIdRef.current );
         setLoadingIntervalId( null );
      }
      
      if ( props.playing && video.paused ) {
         play_video();
      }
      
      if ( props.onCanPlayThrough ) {
         props.onCanPlayThrough();
      }
   }
   
   /**
    * Called when the video updates it current time.
    */
   function videoTimeUpdate( event ) {
      const video = event.target;
      
      if ( props.onProgress ) {
         props.onProgress( video.duration, video.currentTime, video );
      }
   }
   
   /**
    * Called when the video is playing.
    */
   function videoPlaying( event ) {
      if ( loadingIntervalIdRef.current != null ) {
         clearInterval( loadingIntervalIdRef.current );
      }
      
      setLoadStartDate( null );
      setIsShowingLoadingIndicator( false );
   }
   
   /**
    * Called when the video enters a waiting state.
    */
   function videoWaiting() {
      setIsShowingLoadingIndicator( true );
   }
   
   /**
    * Called when the video encounters an error.
    */
   function videoError( event ) {
      const video = event.target;
      console.warn( `video '${ video.currentSrc }' error code: ${ video.error.code }, details: '${ video.error.message }'.` );
   }
   
   /**
    * Called when the video starts it's loading process.
    */
   function videoLoadStart( event ) {
      if ( BUILD_ENV_DEBUG ) {
         console.log( `video '${ event.target.currentSrc }' load started.` );
      }
   }
   
   /**
    * Called when the video stalls.
    */
   function videoStalled( event ) {
      // setIsShowingLoadingIndicator( true );
      // console.log( `video '${ event.target.currentSrc }' stalled.` );
   }
   
   /**
    * Returns whether the video is muted or not.
    *
    * @returns {Boolean} True if it's muted, false otherwise.
    */
   function isVideoMuted() {
      return ( props.muted === false || isMuted ) ? false : true;
   }
   
   /**
    * Returns whether the video is paused or not.
    *
    * @returns {Boolean} True if paused, false otherwise.
    */
   function isVideoPaused() {
      return props.playing === false;
   }
   
   function handle_mute_button( event ) {
      event.stopPropagation();
      
      setIsMuted( prev => !prev );
      appContext.videosMuted = !isMuted;
   }
   
   function handle_playback_button() {
      if ( props.onTogglePlayback ) {
         props.onTogglePlayback();
      }
      else {
         console.warn( `Can't handle pause, props.onTogglePlayback was not defined.` );
      }
   }
      
   useEffect( () => {
      /**
       * Cleanup function to be returned.
       * @type {Function}
       */
      let cleanup = undefined;
      
      if ( videoRef.current != null ) {
         const video = videoRef.current;
         
         const listenerDefs = new Map();
         
         if ( props.onCanPlayThrough ) {
            listenerDefs.set( "canplaythrough", videoCanPlayThrough );
         }
         
         if ( props.onProgress ) {
            listenerDefs.set( "timeupdate", videoTimeUpdate );
            listenerDefs.set( "ended", videoTimeUpdate );
         }
         
         listenerDefs.set( "playing", videoPlaying );
         listenerDefs.set( "waiting", videoWaiting );
         listenerDefs.set( "error", videoError );
         // listenerDefs.set( "loadstart", videoLoadStart );
         listenerDefs.set( "stalled", videoStalled );
         
         const startDate = new Date();
         setLoadStartDate( startDate );
         
         if ( loadingIntervalIdRef.current != null ) {
            clearInterval( loadingIntervalIdRef.current );
         }
         
         const intervalId = setInterval( () => {
            const now = new Date();
            const difference = now - startDate;
            
            if ( difference >= 2000 ) {
               setIsShowingLoadingIndicator( true );
               console.warn( `video need loading indicator. (${ difference / 1000 })` );
               
               clearInterval( intervalId );
            }
         }, 500 );
         
         setLoadingIntervalId( intervalId );
         
         if ( listenerDefs.size > 0 ) {
            listenerDefs.forEach( ( value, key ) => video.addEventListener( key, value ) );
            
            cleanup = function RemoveListeners() {
               listenerDefs.forEach( ( value, key ) => video.removeEventListener( key, value ) );
               
               clearInterval( intervalId );
            };
         }
         
         video.load();
      }
      
      return cleanup;
   }, [ props.src ] );
   
   useEffect( () => {
      if ( videoRef.current != null ) {
         const video = videoRef.current;
         
         if ( !isVideoPaused() ) {
            play_video();
         }
         else {
            video.pause();
         }
      }
   }, [ props.playing ] );
   
   useEffect( () => {      
      if ( videoRef.current ) { 
         videoRef.current.muted = isVideoMuted();
      }
   }, [ videoRef, props.muted, isMuted ] );
   
   const playbackText = ( isVideoPaused() ) ? "play" : "pause";
   
   return (
      <div ref={ forwardedRef } className={ `videoplayer${ ( !isShowingLoadingIndicator ) ? "" : " loading" }` }>
         <video ref={ videoRef }
            disablePictureInPicture
            disableRemotePlayback
            x-webkit-airplay="deny"
            playsInline
            preload="true"
            loop={ isLooping ? true : null }
            autoPlay
            width="100%"
            height={ height }
            muted>
            <source src={ source } type={ `video/${ fileExtension }` } />
         </video>
         
         { props.children }
         
         { ( props.controls === true ) &&
            <div className="videoplayer-controls">
               <button className={ `${ ( isVideoMuted() ) ? "" : "hidden" }` } onClick={ handle_mute_button }>
                  <div className="status">
                     <div className="icon-container">
                        <div className={ `icon audio-${ ( isVideoMuted() ) ? "off" : "on" }` }></div>
                        <p className="tooltip">{ ( isVideoMuted() ) ? "unmute" : "mute"  }</p>
                     </div>
                  </div>
               </button>
               
               { true &&
                  <button className={ ( !isVideoPaused() ) ? "hidden" : undefined } onClick={ handle_playback_button }>
                     <div className="status">
                        <div className="playback-icon-container">
                           <div className={ `icon ${ playbackText }` }></div>
                           <p className="tooltip">{ playbackText }</p>
                        </div>
                     </div>
                  </button>
               }
            </div>
         }
         
         <div className="loading-indicator">
            <Spinner active={ true }/>
         </div>
      </div>
   );
} );

export default VideoPlayer;
