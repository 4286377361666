import React from 'react'
import styled from "styled-components";

const Container = styled.div`
  --bold-shadow-size: 2px;
  --bold-shadow-size-inner: 1px;
  --bold-shadow-blur: 1px;
  --bold-selected-weight: 700;
  --selected-font-color: #eadc5c;
  --background-color: white;

  @keyframes slideInFromLeft {
    0% {
      transform: translateX(+200%);
    }
    100% {
      transform: translateX(0);
    }
  }

  font-family: Futura PT, serif;
  animation: 1s ease-in-out 0s 1 slideInFromLeft;
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: black;
  height: 100%;
  max-height: 80vh;
  justify-content: start;
  flex-basis: 2vw;
`;

const AboutItem = styled.div`
  font-size: 10vh;
  font-weight: 500;
  padding-left: 1em;
  margin: 4vh;
  height: 5vh;
  color: white;

  transition: font-weight 0.1s ease-in-out, font-size 1s ease-in-out;

  &:hover {
    font-weight: var(--bold-selected-weight);
    cursor: pointer;
  }
`;


export default function About() {
  return (
    <Container>
      <AboutItem>Our Story</AboutItem>
      <AboutItem>Insights</AboutItem>
    </Container>
  )
}
