import React, { useEffect, useRef, useState } from "react";

import AppContext, { LayoutSizeClass } from "./../contexts/appcontext";

import * as Animate from "./../base/animate";
import * as Delay from "./../base/delay";
import InfoBlockView from "./infoblockview";
import ModalContainerView from "./modalcontainerview";
import VideoPlayer from "./../controls/videoplayer";
import { VisibilityState } from "./view";
import Vector2 from "./../base/math/vector2";

import "./../../css/logoview.css";

import PntLogoSvg from "./../../media/pnt-logo-padded.svg";

/**
 * Events that can occur in the LogoView.
 * @enum {String}
 */
const LogoEvent = Object.freeze( {
   ENTER_CHARACTER: "enter-char",
   LEAVE_CHARACTER: "leave-char",
   
   INTRO_DONE: "intro-done",
   
   SELECTED_WORK: "selected-work",
} );

/**
 * Intro progression states.
 * @enum {Number}
 */
const IntroState = Object.freeze( {
   NOT_READY: 0,
   READY: 1,
   ANIMATION_DONE: 10,
   DONE: 999
} );

/**
 * Mapping from the characters in the logo to their identifiers.
 * @type {Map<String, Array>}
 */
const logoCharacterMap = new Map( [
   [ "pixelp",  [ "Combined-Shape-Copy-42" ] ],
   [ "pixeli",  [ "Path-Copy-21", "Oval" ] ],
   [ "pixelx",  [ "Combined-Shape-Copy-43" ] ],
   [ "pixele",  [ "Combined-Shape-Copy-41" ] ],
   [ "pixell",  [ "Path-Copy-22" ] ],
   [ "plus",    [ "Combined-Shape" ] ],
   [ "texelt",  [ "Path-Copy-23" ] ],
   [ "texele1", [ "Combined-Shape-Copy-40" ] ],
   [ "texelx",  [ "Combined-Shape-Copy-44" ] ],
   [ "texele2", [ "Combined-Shape-Copy-39" ] ],
   [ "texell",  [ "Path-Copy-24" ] ]
] );

const characterColorDefs = [
   {
      color: "--color-styleguide-magenta",
      elements: [
         ...logoCharacterMap.get( "pixelp" ),
         ...logoCharacterMap.get( "pixele" ),
         ...logoCharacterMap.get( "texelt" ),
         ...logoCharacterMap.get( "texele2" )
      ]
   },
   {
      color: "--color-styleguide-yellow",
      elements: [
         ...logoCharacterMap.get( "pixeli" ),
         ...logoCharacterMap.get( "pixell" ),
         ...logoCharacterMap.get( "texele1" ),
         ...logoCharacterMap.get( "texell" )
      ]
   },
   {
      color: "--color-styleguide-blue",
      elements: [
         ...logoCharacterMap.get( "pixelx" ),
         ...logoCharacterMap.get( "plus" ),
         ...logoCharacterMap.get( "texelx" )
      ]
   }
];

/**
 * Encodes an SVG element to a base64 string.
 *
 * @param {SVGElement} svg SVG element to be encoded.
 *
 * @return {String} Base64 encoded string.
 */
function svg_to_base64( svg ) {
   const serializedSvg = new XMLSerializer().serializeToString( svg ); 
   const encodedSvg = `data:image/svg+xml; charset=utf8, ${ encodeURIComponent( serializedSvg ) }`;
   
   return encodedSvg;
}

/**
 * Extracts elements of an SVG and creates clip path SVG.
 *
 * @param {SVGElement} svg SVG to have elements extracted from.
 * @param {Array<String>} elementIds Identifiers for elements to be copied.
 *
 * @returns {SVGElement} SVG with elements as a clip path.
 */
function convert_to_clippath( svg, elementIds ) {
   const clipSvg = document.createElement( "svg" );
   
   const ignoredAttributes = new Set( [
      "id",
      "style",
      "version",
      // "width",
      // "height",
      "xmlns",
      "xml:space",
      "xmlns:serif",
      "xmlns:xlink"
   ] );
   
   Array.from( svg.attributes ).forEach( attribute => {
      if ( !ignoredAttributes.has( attribute.name.toLowerCase() ) ) {
         clipSvg.setAttribute( attribute.name, attribute.value ) 
      }
   } );
   
   const [ boxWidth, boxHeight ] = svg.getAttribute( "viewBox" ).split( " " ).splice( 2, 3 ).map( x => parseFloat( x ) );
   
   // const defs = document.createElement( "defs" );
   // clipSvg.appendChild( defs );
   
   const clipPath = document.createElement( "clipPath" );
   clipPath.id = "clip";
   clipPath.setAttribute( "clipPathUnits", "objectBoundingBox" );
   
   clipSvg.appendChild( clipPath );
   
   elementIds.forEach( elementId => {
      const element = svg.getElementById( elementId );
      if ( element ) {
         const elementCopy = document.createElement( element.tagName );
         
         Array.from( element.attributes ).forEach( attribute => {
            if ( !ignoredAttributes.has( attribute.name.toLowerCase() ) ) {
               let value;
               
               switch ( attribute.name ) {
                  case "d": {
                     const pattern = /[a-zA-Z]|(-?\d{1,}(?:\.\d{1,})?),(-?\d{1,}(?:\.\d{1,})?)/g;
                     const matches = attribute.value.matchAll( pattern );
                     
                     let isLastMatchPos = false;
                     value = "";
                     
                     for ( const match of matches ) {
                        if ( match[ 0 ].length > 1 ) {
                           const x = ( parseFloat( match[ 1 ] ) / boxWidth ).toFixed( 3 );
                           const y = ( parseFloat( match[ 2 ] ) / boxHeight ).toFixed( 3 );
                           
                           value += `${ ( isLastMatchPos ) ? " " : "" }${ x },${ y }`;
                           isLastMatchPos = true;
                        }
                        else {
                           value += match[ 0 ];
                           isLastMatchPos = false;
                        }
                     }
                     
                     break;
                  }
                  
                  case "cx": {
                     value = ( parseFloat( attribute.value ) / boxWidth ).toFixed( 3 );
                     break;
                  }
                  
                  case "cy": {
                     value = ( parseFloat( attribute.value ) / boxHeight ).toFixed( 3 );
                     break;
                  }
                  
                  case "r": {
                     // TODO: determine how to properly normalize this (Billy 9-21-2022)
                     value = ( parseFloat( attribute.value ) / Math.max( boxWidth, boxHeight ) ).toFixed( 3 );
                     break;
                  }
                  
                  default: {
                     value = attribute.value;
                     break;
                  }
               }
               
               elementCopy.setAttribute( attribute.name, value );
            }
         } );
         
         clipPath.appendChild( elementCopy );
      }
      else if ( BUILD_ENV_DEBUG ) {
         console.warn( `While creating a clip path svg, could not find element '${ elementId }'.` );
      }
   } );
   
   return clipSvg;
}

const PermissionDialog = React.forwardRef( ( props, forwardedRef ) => {
   function handle_button() {
      props.onSuccess();
      props.dismissModal();
   }
   
   return (
      <div ref={ forwardedRef } className="permission">
         <button onClick={ handle_button }>
            { props.children }
         </button>
      </div>
   );
} );

/**
 * Animated Pixel and Texel logo view.
 */
const LogoView = React.forwardRef( ( props, forwardedRef ) => {
   const appContext = new AppContext();
   
   /**
    * Reference to the page's logo element.
    */
   const logoRef = useRef( null );
   
   /**
    * Reference to the intro video's player.
    */
   const introVideoRef = useRef( null );
   
   /**
    * Reference to the background layer element.
    */
   const backgroundRef = useRef( null );
   
   /**
    * Reference to the info block element.
    */
   const infoBlockRef = useRef( null );
   
   const [ logoSvg, setLogoSvg ] = useState( null );
   
   const [ maskedCharacter, setMaskedCharacter ] = useState( null );
   
   const [ svgImage, setSvgImage ] = useState( null );
   
   /**
    * Interval identifier for when attract mode is enabled.
    */
   const [ attractModeIntervalId, setAttractModeIntervalId ] = useState( null );
   
   /**
    * True if the background video is ready to play through, false otherwise.
    */
   const [ isBackgroundVideoReady, setIsBackgroundVideoReady ] = useState( false );
   
   /**
    * Current progression state of the introduction.
    * @type {IntroState}
    */
   const [ introState, setIntroState ] = useState( IntroState.NOT_READY );
   
   const isActive = ( props.active === false ) ? false : true;
   
   /**
    * Processes the selection of a work item.
    *
    * @param {String} itemId Selected work item identifier.
    */
   function handle_selected_work( itemId ) {
      appContext.removeOverlay( "infoblock" )
      
      if ( props.onEvent ) {
         props.onEvent( LogoEvent.SELECTED_WORK, itemId );
      }
   }
   
   function handle_svg( svg ) {
      if ( BUILD_ENV_DEBUG ) {
         console.log( "logo svg loaded!" );
      }
      
      // adjust the stroke on the logo to minimize the edge swimming from
      // the background video.
      
      const group = svg.querySelector( "g > g" );
      group.setAttribute( "stroke-width", "4" );
      
      for ( const child of group.childNodes ) {
         // skip past "fill: " and just get the color.
         
         const style = child.getAttribute( "style" );
         const color = style.slice( 6, style.length - 1 );
         
         child.setAttribute( "stroke", color );
      }
      
      if ( props.onLogoLoad ) {
         let rawWidth = null;
         let rawHeight = null;
         
         if ( svg.hasAttribute( "viewBox" ) ) {
            [ rawWidth, rawHeight ] = svg.getAttribute( "viewBox" )
               .split( " " )
               .splice( 2, 3 );
         }
         else if ( svg.hasAttribute( "width" ) && svg.hasAttribute( "height" ) ) {
            [ rawWidth, rawHeight ] = [ "width", "height", ]
               .map( name => {
                  const value = svg.getAttribute( name );
                  
                  if ( /^\d+$/.test( value ) ) {
                     return parseInt( value );
                  }
                  else {
                     return null;
                  }
               } );
         }
         
         if ( BUILD_ENV_DEBUG && [ rawWidth, rawHeight ].find( x => x == null ) != null ) {
            console.warn( "The SVG logo requires either a 'viewBox' or 'width' and 'height' attributes." );
            return;
         }
         
         const [ boxWidth, boxHeight ] = [ rawWidth, rawHeight ].map( x => parseInt( x ) ); 
         
         setLogoSvg( {
            svg: svg,
            viewBoxWidth: boxWidth,
            viewBoxHeight: boxHeight
         } );
         
         logoCharacterMap.forEach( ( elementIds, key ) => {
            elementIds.forEach( elementId => {
               const element = svg.getElementById( elementId );
               
               element.addEventListener( "pointerenter", () => {
                  if ( appContext.layoutSizeClass == LayoutSizeClass.COMPACT ) {
                     return;
                  }
                  
                  setMaskedCharacter( prev => {
                     if ( prev ) {
                        hide_character( svg, prev, false );
                     }
                     
                     return key;
                  } );
                  
                  if ( props.onEvent ) {
                     props.onEvent( LogoEvent.ENTER_CHARACTER );
                  }
               } );
               
               element.addEventListener( "pointerleave", () => {
                  if ( appContext.layoutSizeClass == LayoutSizeClass.COMPACT ) {
                     return;
                  }
                  
                  setMaskedCharacter( prev => {
                     if ( prev ) {
                        hide_character( svg, prev, false );
                     }
                     
                     return null;
                  } );
                  
                  if ( props.onEvent ) {
                     props.onEvent( LogoEvent.LEAVE_CHARACTER );
                  }
               } );
               
               element.addEventListener( "pointerdown", event => {
                  if ( appContext.layoutSizeClass == LayoutSizeClass.COMPACT ) {
                     return;
                  }
                  
                  const workItem = appContext.getWorkItemForLetter( key );
                  
                  if ( workItem ) {
                     let color = "";
                     
                     for ( const def of characterColorDefs ) {
                        if ( def.elements.indexOf( elementId ) != -1 ) {
                           const index = def.color.lastIndexOf( "-" );
                           color = def.color.slice( index + 1 );   
                        }
                     }
                     
                     const downPos = event.pageVec2;
                     
                     const width = window.innerWidth;
                     const height = window.innerHeight;
                     
                     const center = new Vector2( width / 2, height / 2 );
                     const delta = downPos.subtract( center );
                     
                     const maxOffset = 20;
                     
                     const x = ( delta.x / center.x ) * maxOffset;
                     const y = ( delta.y / center.y ) * maxOffset;
                     
                     appContext.addOverlay( (
                        <ModalContainerView onDismiss={ () => appContext.removeOverlay( "infoblock" ) }>
                           <InfoBlockView ref={ infoBlockRef } start={ new Vector2( x, y ) } color="blue" item={ workItem } onSelected={ handle_selected_work }/>
                        </ModalContainerView>
                     ), "infoblock" );
                  }
                  else {
                     console.warn( `No project was associated with '${ key }'.` );
                  }
               } );
            } );
         } );
         
         // recolor the svg elements
         
         const originalXml = new XMLSerializer().serializeToString( svg );
         
         const copyDoc = new DOMParser().parseFromString( originalXml, "text/xml" );
         const copySvg = copyDoc.querySelector( "svg" );
         
         [ "width", "height", "viewBox" ].forEach( name => {
            if ( svg.hasAttribute( name ) ) {
               const value = svg.getAttribute( name );
               copySvg.setAttribute( name, value );
            }
            else {
               // We need to make sure there is a width and height set, otherwise
               // the SVG cannot be used in the canvas on Firefox.
               
               switch ( name ) {
                  case "width": {
                     copySvg.setAttribute( name, `${ boxWidth }px` );
                     break;
                  }
                  
                  case "height": {
                     copySvg.setAttribute( name, `${ boxHeight }px` );
                     break;
                  }
                  
                  default: {
                     // intentionally blank.
                     break;
                  }
               }
            }
         } );
         
         const computedStyle = getComputedStyle( document.documentElement );
         
         characterColorDefs.forEach( def => {
            const color = computedStyle.getPropertyValue( def.color );
            
            def.elements.forEach( elementId => {
               const element = copySvg.getElementById( elementId );
               if ( element ) {
                  element.setAttribute( "style", `fill:${ color }` );
                  
                  element.setAttribute( "stroke", color );
               }
               else if ( BUILD_ENV_DEBUG ) {
                  console.warn( `Could not find element '${ elementId }' in logo svg.` );
               }
            } );
         } );
         
         const serializedCopy = new XMLSerializer().serializeToString( copySvg );
         
         const image = new Image();
         
         image.addEventListener( "load", () => {
            setSvgImage( image );
         } );
         
         image.src = `data:image/svg+xml; charset=utf8, ${ encodeURIComponent( serializedCopy ) }`;
      }
   }
   
   function hide_character( svg, characterId, hidden = true ) {
      if ( backgroundRef.current ) {
         // const background = backgroundRef.current;
         const elementIds = logoCharacterMap.get( characterId );
         
         elementIds.forEach( elementId => {
            const element = svg.getElementById( elementId );
            element.style.visibility = ( hidden ) ? "hidden" : null;
         } );
      }
   }
   
   /**
    * Process the end of the intro sequence.
    */
   function end_intro() {
      setIntroState( IntroState.DONE );
      
      if ( props.onEvent ) {
         props.onEvent( LogoEvent.INTRO_DONE );
      }
   }
   
   /**
    * Process progress updates on the intro video.
    *
    * @param {Number} duration Total duration of the video in seconds.
    * @param {Number} currentTime Elapsed time of the video in seconds.
    */
   function handle_intro_progress( duration, currentTime ) {
      const progress = currentTime / duration;
      
      if ( introState < IntroState.ANIMATION_DONE && progress >= 0.6 && introVideoRef.current ) {
         setIntroState( IntroState.ANIMATION_DONE );
         
         Delay.untilNextFrame()
         .then( () => Animate.transition( introVideoRef.current, "hidden" ) )
         .then( () => end_intro() );
      }
   }
   
   useEffect( () => {
      if ( props.onProgress ) {
         const items = [ introState > IntroState.NOT_READY, isBackgroundVideoReady ];
         
         let progress;
         
         if ( items.length > 0 ) {
            const current = items.reduce( ( output, value ) => {
               return output + ( value ) ? 1 : 0;
            }, 0 );
            
            progress = current / items.length;
         }
         else {
            progress = 0;
         }
         
         props.onProgress( progress, "logo-view" );
      }
   }, [ props.onProgress, introState, isBackgroundVideoReady ] );
   
   useEffect( () => {
      // Skip the intro animation if a path has been set at page load.
      
      if ( isActive && !/^\s*$/.test( appContext.path ) ) {
         end_intro();
      }
   }, [ isActive, appContext.path ] );
   
   useEffect( () => {
      if ( logoRef.current && appContext.workItems.length > 0 ) {
         const logo = logoRef.current;
         
         handle_svg( logo );
      }
   }, [ logoRef, appContext.workItems ] );
   
   useEffect( () => {
      if ( backgroundRef.current && logoSvg && maskedCharacter ) {
         hide_character( logoSvg.svg, maskedCharacter );
      }
   }, [ backgroundRef, logoSvg, maskedCharacter ] );
   
   useEffect( () => {
      if ( logoSvg != null && svgImage != null && props.onLogoLoad ) {
         const scale = Math.min( logoSvg.svg.clientWidth / logoSvg.viewBoxWidth, logoSvg.svg.clientHeight / logoSvg.viewBoxHeight );
         
         props.onLogoLoad( svgImage, scale );
      }
   }, [ logoSvg, svgImage, appContext.layoutDimensions ] );
   
   // enable / disable compact "attract-mode"
   
   useEffect( () => {
      if ( logoSvg != null ) {
         if ( appContext.layoutSizeClass == LayoutSizeClass.COMPACT ) {            
            if ( attractModeIntervalId != null ) {
               clearInterval( attractModeIntervalId );
            }
            
            const characterIds = Array.from( logoCharacterMap.keys() );
            
            let indices = [ ...Array( characterIds.length ).keys() ].shuffle();
            let indicesIndex = 0;
            
            const intervalId = setInterval( () => {
               setMaskedCharacter( prev => {
                  if ( prev ) {
                     hide_character( logoSvg.svg, prev, false );
                  }
                  
                  const nextIndex = indices[ indicesIndex ];
                  const nextCharacterId = characterIds[ nextIndex ];
                  
                  indicesIndex += 1;
                  
                  if ( indicesIndex >= indices.length ) {
                     indices = [ ...Array( characterIds.length ).keys() ]
                        .replace( nextIndex, 1 )
                        .shuffle();
                        
                     indicesIndex = 0;
                  }
                  
                  return nextCharacterId;
               } );
            }, 3000 );
            
            setAttractModeIntervalId( intervalId );
         }
         else if ( attractModeIntervalId != null ) {
            clearInterval( attractModeIntervalId );
            
            setAttractModeIntervalId( null );
            setMaskedCharacter( prev => {
               if ( prev ) {
                  hide_character( logoSvg.svg, prev, false );
               }
               
               return null;
            } );
         }
      }
   }, [ appContext.layoutSizeClass, logoSvg ] );
   
   const areLayersHidden = ( isActive && introState >= IntroState.ANIMATION_DONE );
   
   const canBackgroundVideoPlay = isActive && introState == IntroState.DONE && isBackgroundVideoReady && ( props.visibility == VisibilityState.MOVING_IN || props.visibility == VisibilityState.IN );
   
   return (
      <div ref={ forwardedRef } className={ `logoview` }>
         <div className={ `layer background${ ( areLayersHidden ) ? "" : " hidden" }` }>
            <div ref={ backgroundRef } className="video-container">
               <div>
                  { true &&
                     <VideoPlayer 
                        src="https://static.pixelandtexel.com/videos/develop/PNT_Logo_Website_Alpha_3700x2002.mp4"
                        height="100%"
                        playing={ canBackgroundVideoPlay }
                        loop={ true }
                        onCanPlayThrough={ () => setIsBackgroundVideoReady( true ) }/>
                  }
               </div>
            </div>
         </div>
         
         <div className={ `layer logo${ ( areLayersHidden ) ? "" : " hidden" }` }>
            <PntLogoSvg ref={ logoRef } id="pnt-logo"/>
         </div>
         
         { introState != IntroState.DONE &&
            <div className="layer intro">
               <div ref={ introVideoRef } className="video-container">
                  <VideoPlayer
                     src="https://static.pixelandtexel.com/videos/develop/PNTWebAnimation.mp4"
                     height="100%"
                     playing={ isActive && introState >= IntroState.READY }
                     onCanPlayThrough={ () => setIntroState( IntroState.READY ) }
                     onProgress={ handle_intro_progress } 
                     onPermissionRequired={ onSuccess => {
                        appContext.addOverlay( (
                           <ModalContainerView onDismiss={ () => appContext.removeOverlay( "permission" ) }>
                              <PermissionDialog onSuccess={ onSuccess }>
                                 <p>This site requires auto-playing videos, please click to continue…</p>
                              </PermissionDialog>
                           </ModalContainerView>
                        ), "permission" );
                     } }/>
               </div>
            </div>  
         }
         
         <div className={ `more${ ( introState != IntroState.DONE ) ? " hidden" : "" }` }>
            <button onClick={ () => appContext.path = "navigation" }>
               <div>
                  <div className="icon">
                     <div className="icon-more"></div>
                  </div>
               </div>
            </button>
         </div>
      </div>      
   );
} );

export default LogoView;

export {
   LogoEvent
}
