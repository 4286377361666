/**
 * Returns the value at the specified key path.
 *
 * @param {Object} obj Object containing value.
 * @param {String} path Period delimited key path (ex: "one.two.three")
 *
 * @returns {Any} The value at the key path if it exists or undefined.
 */
function valueAtKeyPath(obj, path) {
  let value = obj;

  const components = path.split('.');

  for (const component of components) {
    value = value[component];

    if (BUILD_ENV_DEBUG && typeof value === 'undefined') {
      console.warn(`key path '${path}' does not exist on object.`);
      break;
    }
  }

  return value;
}

export { valueAtKeyPath };
