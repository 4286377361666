import { useContext } from "react";

/**
 * Convenience function for creating an action for a dispatch function.
 *
 * @param {Function} dispatch Dispatch function returned from React's useReducer.
 * @param {String} actionType String defining the action to perform.
 * @parma {any} actionPayload Optional data to pass as payload to the action.
 */
function dispatcher( dispatch, actionType, actionPayload ) {
   const action = { type: actionType, value: actionPayload };
   dispatch( action );
}

class ContextInstance {
   constructor( context ) {
      const [ state, dispatch, ...args ] = useContext( context );
      
      /**
       * Additional arguments that might be contained with the provider value.
       * @type {Array}
       */
      this._args = args;
      
      /**
       * Unmodified dispatch from useContext.
       * @type { function(object, object): object }
       */
      this._unmodifiedDispatch = dispatch;
      
      this._state = state;
      this._dispatch = dispatcher.bind( null, dispatch );
   }
   
   /**
    * Value for context provider.
    *
    * @returns {Array}
    */
   get providerValue() {
      return [ this._state, this._unmodifiedDispatch ];
   }
}

export default ContextInstance;
