import { cyrb53 } from "./../base/hash";

( function() {
   if ( typeof String.prototype.hashCode === "undefined" ) {
      Object.defineProperty( String.prototype, "hashCode", {
         get() {
            return cyrb53( this, 8088 );
         }
      } );
   }   
} )();
