import { makeApiRequest } from "./request";

import WorkItem from "./../models/workitem";

import { valueAtKeyPath } from "./utilities";

/**
 * Returns all the work items from the backend.
 *
 * @param {Function} onProgress Will be called with progress updates.
 *
 * @returns {Array} Array containing the request promise and the request abort function.
 */
function getAllWorkItems( onProgress ) {
   const [ pending, abortRequest ] = makeApiRequest( "workitems", null, onProgress );
   
   const promise = pending.then( response => {   
      if ( valueAtKeyPath( response, "sys.type" ) === "Array" ) {
         const assets = valueAtKeyPath( response, "includes.Asset" );
         const rawItems = response[ "items" ];
         
         const items = rawItems.map( item => {
            const fields = item[ "fields" ];
            
            if ( "images" in fields ) {
               const images = fields[ "images" ].map( imageDef => {
                  const imageAssetId = valueAtKeyPath( imageDef, "sys.id" );
                  
                  const imageAsset = assets.find( asset => {
                     const assetId = valueAtKeyPath( asset, "sys.id" );
                     return imageAssetId == assetId;
                  } );
                  
                  const imageUrl = valueAtKeyPath( imageAsset, "fields.file.url" );
                  return "https:" + imageUrl;
               } );
               
               fields[ "images" ] = images;
            }
            
            const sysId = valueAtKeyPath( item, "sys.id" );
            const id = ( sysId ) ? sysId : "";
            
            const data = Object.assign( {}, fields, { id: id } );
            
            const workItem = WorkItem.fromData( data );
            return workItem;
         } );
         
         return Promise.resolve( items );
      }
      else {
         return Promise.reject( new Error( `The format of the response to 'getAllWorkItems' was incorrect.` ) );
      }
   } );
   
   return [ promise, abortRequest ];
}

/**
 * Returns the order of the work items from the backend.
 *
 * @param {Function} onProgress Will be called with progress updates.
 *
 * @returns {Array} Array containing the request promise and the request abort function.
 */
function getWorkItemsOrder( onProgress ) {
   const [ pending, abortRequest ] = makeApiRequest( "workitemsorder", null, onProgress );
   
   const promise = pending.then( response => {
      const elements = valueAtKeyPath( response, "fields.elementOrder" );
      
      if ( Array.isArray( elements ) ) {
         return Promise.resolve( elements );
      }
      else {
         return Promise.reject( new Error( `The format of the response to 'getWorkItemsOrder' was incorrect.` ) );
      }
   } );
   
   return [ promise, abortRequest ];
}

export {
   getAllWorkItems,
   getWorkItemsOrder
}
