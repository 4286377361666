const queryPattern = /[?&]([^=\s&]+)(?:=([^&\s]+))?/g;

/**
 * Enumerate the keys and values from the html query.
 *
 * @param {Function} keyCallback Handler function for each key and value.
 */
function enumerateUrlQuery( keyCallback ) {
	const matches = window.location.search.matchAll( queryPattern );
	
	for ( const match of matches ) {
		const key = match[ 1 ].toLowerCase();
		
		const rawValue = match[ 2 ];
		const value = ( rawValue ) ? decodeURIComponent( rawValue ) : rawValue;
		
		keyCallback( key, value );
	}
}

export {
	enumerateUrlQuery
};
