/**
 * Interpolates at a consistent rate.
 *
 * @param {Number} value Value from 0.0 - 1.0.
 *
 * @return {Number} interpolation value.
 */
function linear( value ) {
   return value;
}

/**
 * Interpolates fast-to-slow.
 *
 * @param {Number} value Value from 0.0 - 1.0.
 *
 * @return {Number} interpolation value.
 */
function easeIn( value ) {
   const angle = value * ( Math.PI / 2 );
   const result = Math.sin( angle );
   
   return result;
}

/**
 * Interpolates slow-to-fast.
 *
 * @param {Number} value Value from 0.0 - 1.0.
 *
 * @return {Number} interpolation value.
 */
function easeOut( value ) {
   const pi2 = Math.PI / 2;
   const angle = pi2 + value * pi2;
   const result = 1 + Math.cos( angle );
   
   return result;
}

/**
 * Interpolates slow-fast-slow.
 *
 * @param {Number} value Value from 0.0 - 1.0.
 *
 * @return {Number} interpolation value.
 */
function easeInOut( value ) {
   const angle = Math.PI + value * Math.PI;
   const result = ( 1 + Math.cos(angle) ) / 2;
   
   return result;
}

export {
   linear,
   easeIn,
   easeOut,
   easeInOut
}
