import React, { useEffect, useLayoutEffect, useRef, useState } from "react";

import AppContext from "./../contexts/appcontext";

import * as Animate from "./../base/animate";
import YouTubePlayer from "./../controls/youtubeplayer";

import "./../../css/infoblockview.css";

const nameAbbreviations = new Map( [
   [ "amex", "american express" ],
   [ "gm", "general motors" ],
   [ "marriott", "marriott international" ]
] );

/**
 * Work info block.
 */
const InfoBlockView = React.forwardRef( ( props, forwardedRef ) => {
   const appContext = new AppContext();
   
   const viewRef = ( typeof forwardedRef !== "undefined" && forwardedRef != null ) ? forwardedRef : useRef( null );
   
   const contentsRef = useRef( null );
   
   const videoRef = useRef( null );
   const bannerRef = useRef( null );
   
   const logoRef = useRef( null );
   const detailsRef = useRef( null );
   const arrowRef = useRef( null );
   
   const [ videoIsReady, setVideoIsReady ] = useState( false );
   
   /**
    * True if the video should be playing (when it's ready), false otherwise.
    * @type {Boolean}
    */
   const [ isVideoPlaying, setIsVideoPlaying ] = useState( true );
   
   const item = props.item;
   const itemClients = ( item.clients.length > 1 ) ? item.clients : item.clients[ 0 ].split( "," );
   
   const clients = itemClients.map( x => {
      const name = x.toLowerCase();
      return ( nameAbbreviations.has( name ) ) ? nameAbbreviations.get( name ) : name;
   } );
   
   const logoUrl = ( item ) ? appContext.getPartnerImageUrl( clients ) : "";
   
   if ( BUILD_ENV_DEBUG && /^\s*$/.test( logoUrl ) ) {
      useEffect( () => {
         console.warn( `Could not find logo image url for clients: [${ item.clients.join( ", " ) }].` );
         console.log( appContext.partners.map( x => x.name ).sort().join( ", " ) );
      }, [ item.clients ] );
   }
   
   /**
    * True if the view is currently active, false otherwise.
    * @type {Boolean}
    */
   const [ isActive, setIsActive ] = useState( false );
   
   function handle_videoready() {
      // if ( videoIsReady )
      setVideoIsReady( true );
   }
   
   /**
    * Processes clicking of the arrow.
    */
   function handle_selected() {
      if ( props.onSelected ) {
         props.onSelected( item.id );
      }
   }
   
   useLayoutEffect( () => {
      if ( viewRef.current && props.start ) {
         const view = viewRef.current;
         view.style.transform = `translate(${ props.start.x.toFixed( 1 ) }%, ${ props.start.y.toFixed( 1 ) }%)`;
      }
   }, [ viewRef, props.start ] );
   
   useEffect( () => {
      if ( viewRef.current && contentsRef.current ) {
         const view = viewRef.current;
         const contents = contentsRef.current;
         
         view.style.transform = "";
         
         Animate.transition( view, "-hidden" )
         .then( () => setIsActive( true ) );
         
         Animate.transition( contents, "-hidden" );
      }
   }, [ viewRef, contentsRef ] );
   
   useEffect( () => {
      if ( isActive && bannerRef.current && logoRef.current && detailsRef.current && arrowRef.current ) {
         const banner = bannerRef.current;
         const logo = logoRef.current;
         const details = detailsRef.current;
         const arrow = arrowRef.current;
         
         Animate.transition( banner, "-hidden" )
         .then( () => {
            [ logo, details, arrow ].forEach( e => Animate.transition( e, "-hidden" ) );
         } );
      }
   }, [ isActive, bannerRef, logoRef, detailsRef, arrowRef ] );
   
   useEffect( () => {
      if ( videoRef.current && videoIsReady ) {
         Animate.transition( videoRef.current, "-hidden" );
      }
   }, [ videoRef, videoIsReady ] );
   
   const hasVideo = item && !/^\s*$/.test( item.videoUrl );
   
   const logoComponent = ( logoUrl ) ? <img src={ logoUrl }/> : <div></div>;
   
   return (
      <div ref={ viewRef } className={ `infoblockview${ ( props.color ) ? " " + props.color : "" }` }>
         <div ref={ contentsRef } className="contents hidden">
            <div ref={ videoRef } className="hidden">
               { hasVideo &&
                  <YouTubePlayer
                     src={ item.videoUrl }
                     controls={ true }
                     onReady={ handle_videoready }
                     playing={ videoIsReady && isVideoPlaying }
                     onTogglePlayback={ () => setIsVideoPlaying( prev => !prev ) } />
               }
            </div>
            
            <div ref={ bannerRef } className="banner hidden">
               <div>
                  { React.cloneElement( logoComponent, {
                    ref: logoRef,
                    className: "hidden"
                  } ) }
               </div>
               
               <div ref={ detailsRef } className="hidden">
                  <h1>{ ( item ) ? item.name : "name" }</h1>
               </div>
               
               <div>
                  <button onClick={ handle_selected }>
                     <div ref={ arrowRef } className="icon-container hidden">
                     <div className="icon icon-arrow"></div>
                     </div>
                  </button>
               </div>
            </div>
         </div>
      </div>
   );
} );

export default InfoBlockView;
