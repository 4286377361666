/**
 * Initiates an implicit transition on an element based off addition or removal of a classname.
 *
 * @param {HTMLElement} element Element to perform the transition on.
 * @param {String} className Classname to be added or removed (prefix with '+' to add or '-' to remove).
 *
 * @returns {Promise} Promise that resolves at the end of the transition.
 */
function transition( element, classname ) {
	return new Promise( ( resolve, reject ) => {
		element.addEventListener( "transitionend", ( event ) => {
			if ( event.type == "transitionend" ) {
				resolve();
			}
			else {
				reject( `The transition expected a 'transitionend' event type but received '${ event.type }'.` );
			}
		}, { once: true } );

		switch ( classname[ 0 ] ) {
			case "-": {
				element.classList.remove( classname.slice( 1 ) );
				break;
			}

			case "+": {
				classname = classname.slice( 1 );
				// intentionally falling through
			}

			default: {
				element.classList.add( classname );
				break;
			}
		}
	})
}

/**
 * Initiates an implicit animation on an element based off the addition of a class name.
 *
 * @param {HTMLElement} element Element to perform the animation on.
 * @param {String} className Classname to be added.
 *
 * @returns {Promise} Promise that resolves at the end of the animation.
 */
function animation( element, classname ) {
	return new Promise( ( resolve, reject ) => {
		element.addEventListener( "animationend", event => {
			if ( event.type == "animationend" ) {
				event.target.classList.remove( classname );
				resolve();
			}
			else {
				reject( new Error( `The animation expected an 'animationend' for the event type but received '${ event.type }'.` ) );
			}
		}, { once: true } );

		element.classList.add( classname );
		
		// TODO: this doesn't work if the classname already exists
	} );
}

export {
	transition,
	animation
}
