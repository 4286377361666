/**
 * Ratio of the logo image.
 * @enum {String}
 */
const Ratio = Object.freeze({
  SQUARE: "square",
  VERTICAL: "vertical",
  HORIZONTAL: "horizontal"
});

class PartnerItem {
  constructor(name, imageUrl = '', ratio = Ratio.HORIZONTAL) {
    this.name = name;
    this.imageUrl = imageUrl;
    this.ratio = ratio;
  }
}

export default PartnerItem;

export {
  Ratio
};
