import React from 'react';

import styled from 'styled-components';

import AppContext from './../contexts/appcontext';
import ModalContainerView from './modalcontainerview';
import ContactForm from './contact_form';

const StyledButton = styled.div`
  display: grid;
  height: 44px;
  grid-template-rows: 4px 40px;
  width: 140px;
  
  .compact & {
    width: 74px;
    height: 32px;
  }
`;

const TopPinkBar = styled.div`
  width: 100%;
  justify-self: end;
  background-color: var(--color-styleguide-magenta);
  pointer-events: none;
  
  .compact & {
    width: 0%;
  }
`;

const HeaderBarText = styled.h2`
  position: absolute;
  top: 4px;
  width: 132px;
  right: 5px;
  padding: 0;
  margin: 0;
  font-size: 27px;
  pointer-events: none;
  
  .compact & {
    font-size: 16pt;
    right: unset;
  }
`;

export default function ContactUsButton() {
  const appContext = new AppContext();

  return (
    <StyledButton
      onClick={(event) => {
        var buttonRectInfo = event.target.getBoundingClientRect();
        appContext.addOverlay(
          <ModalContainerView
            onDismiss={() => appContext.removeOverlay('contactus')}>
            <ContactForm
              clientTop={buttonRectInfo.top - event.target.offsetTop}
              clientRight={
                document.body.clientWidth -
                buttonRectInfo.right -
                event.target.offsetLeft
              }
            />
          </ModalContainerView>,
          'contactus'
        );
      }}>
      <TopPinkBar />
      <HeaderBarText>{!appContext.isCompactSizeClass ? "Contact Us" : "Contact"}</HeaderBarText>
    </StyledButton>
  );
}
