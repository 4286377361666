import { remap } from "./model";

/**
 * Single work item.
 */
class WorkItem {
   constructor() {
      this.id = "";
      
      this.name = "";
      this.displayName = "";
      this.videoUrl = "";
      
      /** @type {Array<String>} */
      this.description = [];
      
      /** @type {Array<String>} */
      this.clients = [];
      
      /** @type {Array<String>} */
      this.services = [];
      
      /** @type {Array<String>} */
      this.types = [];
      
      this.objective = "";
      this.results = "";
      
      /** @type {Array<String>} */
      this.imageUrls = [];
      
      this.slug = "";
   }
}

/**
 * Returns a new WorkItem from a generic object.
 *
 * @param {Object} obj Object containing work item data.
 *
 * @returns {WorkItem} A WorkItem on successful conversion or null on failure.
 */
WorkItem.fromData = function( obj ) {
   /** @type {WorkItem} */
   let result;
   
   const overrides = {
      description: obj[ "description" ].split( "\n" ).filter( s => !/^\s*$/.test( s ) )
   };
   
   if ( typeof obj === "object" ) {
      result = remap( obj, WorkItem, overrides, new Map( [
         [ "client", "clients" ],
         [ "type", "types" ],
         [ "images", "imageUrls" ]
      ] ) );
   }
   else {
      result = null;
   }
   
   return result;
}

export default WorkItem;
