import { makeApiRequest } from './request';

import Service from "../models/service";

import { valueAtKeyPath } from "./utilities";

function getAllServices(onProgress) {
    const [pending, abortRequest] = makeApiRequest('services', null, onProgress);

    const promise = pending.then((response) => {
      const items = response.items.map((item) => {
        const title = item.fields.title;
        const description = item.fields.description;
        let headerImage = "";
        if (item.fields.image) {
            const assetId = item.fields.image.sys.id;
            const asset = response.includes.Asset.find((asset) => {
                return asset.sys.id === assetId;
            });
            headerImage = "https:" + asset.fields.file.url;
        }
        return new Service(title, description, headerImage);
      });
      if (items.length > 0) {
        return Promise.resolve(items.filter((item) => item));
      } else {
        return Promise.reject(
          new Error(
            "The format of the response to 'getAllServices' was incorrect."
          )
        );
      }
    });
  
    return [promise, abortRequest];
}

/**
 * Returns the order of the service items from the backend.
 *
 * @param {Function} onProgress Will be called with progress updates.
 *
 * @returns {Array} Array containing the request promise and the request abort function.
 */
function getServicesOrder(onProgress) {
  const [pending, abortRequest] = makeApiRequest("servicesorder", null, onProgress);
  
  const promise = pending.then(response => {
    const elements = valueAtKeyPath(response, "fields.elementOrder");
    
    if (Array.isArray(elements)) {
       return Promise.resolve(elements);
    }
    else {
       return Promise.reject(new Error(`The format of the response to 'getServicesOrder' was incorrect.`));
    }
  });
  
  return [promise, abortRequest];
}

function getSizzleReel(onProgress) {
    const [pending, abortRequest] = makeApiRequest('servicessizzlereel', null, onProgress);
  
    const promise = pending.then((response) => {
      const videoUrl = response.fields.videoUrl;
      if (videoUrl) {
        return Promise.resolve(videoUrl);
      } else {
        return Promise.reject(
          new Error(
            "The format of the response to 'getSizzleReel' was incorrect."
          )
        );
      }
    });
  
    return [promise, abortRequest];
  }

export { getAllServices, getServicesOrder, getSizzleReel };