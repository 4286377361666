/**
 * Remaps properties from a generic Object into a class instance.
 *
 * @param {Object} obj Object containing class properties.
 * @param {Class} class_ Class to create instance of.
 * @apram {Object} override Object with keys that will override the values in the original object.
 * @param {Map<String, String>} mappings Mappings from a different name to the class name.
 *
 * @return {Object} New instance of the specified class with mapped values.
 */
function remap( obj, class_, override = {}, mappings = new Map() ) {
   // if ( typeof mappings === "undefined" || mappings == null ) {
   //    mappings = new Map();
   // }
   
   const instance = new class_();
   const instanceKeys = Object.getOwnPropertyNames( instance );
   
   const entries = Object.entries( obj ).reduce( (result, [key, value]) => {
      if ( instanceKeys.indexOf( key ) != -1 || ( mappings.has( key ) && instanceKeys.indexOf( mappings.get( key ) ) != -1 ) ) {
         if ( mappings.has( key ) ) {
            key = mappings.get( key );
         }
         
         result.push( [key, value] );
      }
      
      return result;
   }, [] );
   
   const swizzled = Object.fromEntries( entries );
   const remapped = Object.assign( instance, swizzled, override );
   
   return remapped;
}

export {
   remap
}
