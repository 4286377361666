import { useEffect, useState } from "react";

/**
 * Manages observing intersections with the referenced elements.
 *
 * @param {Number|Array} threshold Single or a collection of intersection values.
 * @param {Array<React.ref>} observingRefs References containing elements being observed.
 * @param {Function} onUpdatedRatios Function called when the intersection ratios are updated.
 */
function useIntersectionManager( threshold, observingRefs, onUpdatedRatios ) {
   const [ intersectionObserver ] = useState( new IntersectionObserver(
      observed_intersection,
      {
         root: null,
         rootMargin: "0px",
         threshold: threshold
      }
   ) );
   
   /**
    * Processes the observed intersections.
    *
    * @param {Array<IntersectionObserverEntry>} entries Intersection entries.
    * @param {InteractionObserver} observer Observer of the intersections.
    */
   function observed_intersection( entries, observer ) {
      const sortedThreshold = ( Array.isArray( threshold ) ) ? threshold.sort() : [ threshold ];
      
      const ratios = observingRefs.filter( ref => ref.current ).map( ref => {
         const entry = entries.find( e => e.target == ref.current );
         
         let value;
         
         if ( entry ) {
            // since the intersection value can vary, find the closest matching
            // threshold value.
            
            const deltas = sortedThreshold.map( ( thresholdValue, index ) => ({ 
               index: index,
               ratio: Math.abs( thresholdValue - entry.intersectionRatio )
            }) );
            
            const index = deltas.sort( ( a, b ) => a.ratio - b.ratio )[ 0 ].index;
            value = sortedThreshold[ index ];
         }
         else {
            value = undefined;
         }
         
         return value;
      } );
      
      if ( onUpdatedRatios ) {
         onUpdatedRatios( ratios );
      }
   }
   
   // properly observe and unobserve elements as the references changes.
   
   useEffect( () => {
      const targets = observingRefs
                        .filter( r => r.current )
                        .map( r => r.current );
      
      targets.forEach( target => intersectionObserver.observe( target ) );
      
      return function unobserve_targets() {
         targets.forEach( target => intersectionObserver.unobserve( target ) );
      }
   }, observingRefs.map( ref => ref.current ) );
}

export {
   useIntersectionManager
}
