import React, { useRef, useState, useEffect } from "react";
import InfoList from "./infolist";
import WorkSection from "./work/worksection";
import styled, {css} from "styled-components";
import Services from "./services";
import About from "./work/about";
import WorkServicesList from "./work/workserviceslist";
import AppContext, { LayoutSizeClass } from "../../contexts/appcontext";
import AboutView from "../aboutview";
import PartnersView from "../partnersview";
import WorkItemView from "../workitemview";
import { useScrollManager } from "./../../base/scrollmanager";
import { VisibilityState, visibility_state, useVisibilityStates } from "./../view";

const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const Contents = styled.div`
  display: grid;
  // grid-template-columns: 1fr 1fr 1fr 1.2fr 0.8fr;
  grid-template-columns: repeat(5, 1fr);
  
  width: 100%;
  height: 100%;
  
  & > * {
    overflow: hidden;
  }
  
  .compact & {
    grid-template-columns: repeat(4, 1fr);
    width: 300vw;
  }
  
  .compact & > * {
    width: 100vw;
    height: 100%;
    flex: unset;
    box-sizing: border-box;
    padding-top: 20vh;
    
    // overflow: scroll;
    
    @supports (padding-top: 20svh) {
      padding-top: 20svh;
    }
  }
`;

const SectionContainer = styled.div`
  ${(props) => {
    if (props.isView === true) {
      return css`
        grid-column: 2 / 5;
      
        overflow: scroll;
        ${(props.addPadding === true) ? "padding-bottom: 10vh;" : ""}
        
        .compact & {
          grid-column: unset;
          padding-bottom: unset;
        }
      `;
    }
    else {
      return css`
        display: flex;
        align-items: end;
      `;
    }
  }}
`;

const DescriptionContainer = styled.div`
  ${(props) => {
    if (props.workView) {
      return css`
        grid-column: 3 / 5;
        overflow: scroll;
        padding-top: 20vh;
        padding-bottom: 15vh;
        
        .compact & {
          grid-column: unset;
        }
        
        @supports (padding-top: 20svh) {
          padding-top: 20svh;
        }
      `;
    }
    else {
      return css`
        display: flex;
        align-items: end;
      `;
    }
  }}
`;

const WorkItemViewContainer = styled.div`
  @keyframes descriptionSlideInFromLeft {
    0% {
      opacity: 0;
      transform: translateX(+25%);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }

  opacity: 0;
  animation: 0.5s ease-out 0s 1 descriptionSlideInFromLeft;

  // Keep animation last frame
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards
`;

const infoListItems = [
  "Services",
  "Work",
  "Brands",
  "About",
];

/**
 * Section name to index.
 * @enum {Number}
 */
const NamedSectionIndex = Object.freeze({
  SERVICES: 0,
  WORK: 1,
  BRANDS: 2,
  ABOUT: 3
});

const InfoSection = React.forwardRef((props, forwardedRef) => {
  const appContext = new AppContext();
  const containerRef = (typeof forwardedRef !== "undefined" && forwardedRef != null) ? forwardedRef : useRef( null );
  const [section, setSection] = useState(-1);
  const [secondLevelIdx, setSecondLevelIdx] = useState(-1);
  const [workDescriptionView, setWorkDescriptionView] = useState(-1);
  const [servicesDescriptionView, setServicesDescriptionView] = useState(-1);
  const [workItemNames, setWorkItemNames] = useState([]);
  const [workItems, setWorkItems] = useState([]);
  const [servicesItems, setServicesItems] = useState([]);
  const [isFirstTime, setIsFirstTime] = useState(window.location.search == "");
  const [lastInteractionColumn, setLastInteractionColumn] = useState(0);
  const [
    compactSectionIndex,
    scrollToCompactSection,
    cancelCompactScroll
  ] = useScrollManager(
    containerRef,
    element => { 
      return Array.from(element.querySelectorAll(":scope > div > div"))
              .map(e => e.offsetLeft - element.offsetLeft);
    },
    {
      duration: 0.5
    }
  );
  const [compactSectionPushed, setCompactSectionPushed] = useState(true);
  
  const sections = [
    <Services items={servicesItems} sizzleReelVideoSrc={appContext.sizzleReel} />,
    <WorkServicesList
      items={workItemNames}
      selectedIdx={secondLevelIdx}
      onSelection={(idx) => {
        setSecondLevelIdx(idx);
        if (idx != -1) {
          setLastInteractionColumn(2);
          scrollToCompactSection(2);
          setCompactSectionPushed(true);
        }
      }}
      pushed={compactSectionPushed}/>,
    <PartnersView />,
    <AboutView />
  ];
  
  const [columnRefs] = useState([...Array(sections.length).keys()].map(() => useRef(null)));
  
  const columnVisibilityStates = useVisibilityStates(columnRefs);
  
  function handle_back( event ) {
    if (compactSectionIndex > 0) {
      scrollToCompactSection(compactSectionIndex - 1);
      setLastInteractionColumn(prev => prev - 1);
      setCompactSectionPushed(false);
    }
  }
  
  useEffect(() => {
    // when switch from the normal size class to the compact one we want to adjust
    // the users view to the last view they interacted with.
    
    if (appContext.isCompactSizeClass) {
      scrollToCompactSection( lastInteractionColumn, false );
    }
  }, [appContext.isCompactSizeClass]);
  
  useEffect(() => {
    appContext.backButtonOnClick = handle_back;
  }, [compactSectionIndex]);
  
  useEffect(() => {
    if (containerRef.current) {
      if ( BUILD_ENV_DEBUG ) {
        console.warn("[DEBUG] resetting scroll offset because it's wrong sometimes :(");
      }
      
      containerRef.current.scrollTo({
        left: 0,
        behavior: "instant"
      });
    }
  }, [containerRef]);
  
  useEffect(() => {
    const [first, remainder] = appContext.pathComponents;
    
    const idx = infoListItems.findIndex(x => x.toLowerCase() == first);
    if (idx != -1) {
      setSection(idx);
      if (first == "work" && remainder) {
        let itemIdx = appContext.orderedWorkItems.findIndex(x => x.id == remainder);
        itemIdx = itemIdx != -1 ? itemIdx : appContext.orderedWorkItems.findIndex(x => x.slug == remainder);
        
        if (itemIdx != -1) {
          setSecondLevelIdx(itemIdx);
          
          if (appContext.isCompactSizeClass) {
            scrollToCompactSection(2);
            setLastInteractionColumn(2);
          }
        }
        else {
          console.warn(`Could not find matching work item for id '${remainder}'.`);
        }
      }
      else if (["partners", "work", "services", "about"].indexOf(first) != -1 && appContext.isCompactSizeClass) {
        scrollToCompactSection(1);
        setLastInteractionColumn(1);
      }
    }
    else if (first == "navigation" && isFirstTime) {
      if (appContext.layoutSizeClass != LayoutSizeClass.COMPACT) {
        setSection(NamedSectionIndex.SERVICES);
      }
      
      setIsFirstTime(false);
    }
  }, [appContext.path, appContext.workItems, isFirstTime]);
  
  useEffect(() => {
    if (section === -1 || secondLevelIdx === -1) return;
    if (section == NamedSectionIndex.WORK) {
      setWorkDescriptionView(secondLevelIdx);
    }
    else if (section == NamedSectionIndex.SERVICES) {
      setServicesDescriptionView(secondLevelIdx);
    }
    else {
      setWorkDescriptionView(-1);
      setServicesDescriptionView(-1);
    }
  }, [section, secondLevelIdx]);

  useEffect(() => {
    if (appContext.workItems.length === 0) return;
    const items = appContext.orderedWorkItems;
    setWorkItemNames(items.map((workItem) => workItem.displayName));
    setWorkItems(items);
  }, [appContext.workItems]);

  useEffect(() => {
    if (appContext.orderedServices.length === 0) return;
    setServicesItems(appContext.orderedServices);
  }, [appContext.services]);
  
  useEffect(() => {
    if (cancelCompactScroll) {
      const cancel = cancelCompactScroll;
      return function cleanup() {
        cancel();
      }
    }
  }, [cancelCompactScroll]);
  
  useEffect(() => {
    appContext.backButtonEnabled = compactSectionIndex > 0;
  }, [compactSectionIndex, appContext.isCompactSizeClass]);
  
  useEffect(() => {
    // reset the scroll position to the top when switching sections.
    
    if (section != -1 && columnRefs[1].current) {
      const element = columnRefs[1].current;
      
      element.scrollTo({
        top: 0,
        behavior: "instant"
      });
    }
  }, [section]);
  
  useEffect(() => {
    // reset the scroll position to the top when switching work items.
    
    if (workDescriptionView != -1 && columnRefs[2].current) {
      const element = columnRefs[2].current;
      
      element.scrollTo({
        top: 0,
        behavior: "instant"
      });
    }
  }, [workDescriptionView]);

  function appending_classname(component, classname) {
    const result = ( component.props.className ) ? component.props.className + " " + classname : classname;
    
    return result;
  } 

  return (
    <Container ref={containerRef}>
      <Contents>
        <InfoList
          ref={columnRefs[0]}
          visibility={visibility_state(columnVisibilityStates, 0)}
          pushed={compactSectionPushed}
          setSection={(idx) => {
            setLastInteractionColumn((idx != -1) ? 1 : 0);
            setSection(idx);
            setSecondLevelIdx(-1);
            setWorkDescriptionView(-1);
            setServicesDescriptionView(-1);
            
            if (appContext.layoutSizeClass == LayoutSizeClass.COMPACT) {
              scrollToCompactSection(1);
              setCompactSectionPushed(true);
            }
          }}
          descriptionViewIsVisible={workDescriptionView !== -1 || servicesDescriptionView !== -1}
          selection={(section >= 0) ? section : undefined}
          items={infoListItems}
        />
        <SectionContainer ref={columnRefs[1]} className="no-scrollbar" isView={section != NamedSectionIndex.WORK} addPadding={section != NamedSectionIndex.BRANDS}>
          {section !== -1 &&
            React.cloneElement(sections[section], {
              className: appending_classname(sections[section], "no-scrollbar"),
              visibility: visibility_state(columnVisibilityStates, 1),
            })
          }
        </SectionContainer>
  
        <DescriptionContainer ref={columnRefs[2]} className="no-scrollbar" workView={section === NamedSectionIndex.WORK}>
          {workDescriptionView !== -1 && 
            <WorkItemViewContainer key={workDescriptionView}>
              <WorkItemView item={workItems[workDescriptionView]} visibility={visibility_state(columnVisibilityStates, 2)} />
            </WorkItemViewContainer>
          }
        </DescriptionContainer>
      </Contents>
    </Container>
  );
} );

const WorkServicesDescriptionContainer = styled.div`
  @keyframes showAfter {
    30% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  background-color: purple;
  width: 100%;
  height: 100%;
  opacity: 0;
  animation: showAfter 1s forwards;
  transition: all 0.5s ease-in-out;
`;

function WorkServicesDescription(props) {
  return (
    <WorkServicesDescriptionContainer>{props.testDescription}</WorkServicesDescriptionContainer>
  );
}

export default InfoSection;
