import { useRef, useState } from "react";

import { useIntersectionManager } from "./../base/intersectionmanager";

/**
 * The visibility state of the view.
 * @enum {String}
 */
const VisibilityState = Object.freeze( {
   /** View is in full view */
   IN: "in",
   
   /** View is partially visible and moving into view. */
   MOVING_IN: "moving-in",
   
   /** View is partially visible and moving out of view. */
   MOVING_OUT: "moving-out",
   
   /** View is not visible. */
   OUT: "out"   
} );

/**
 * Returns a VisbilityState based off the current and previous intersection values.
 *
 * @param {Object} state Current and previous intersection values.
 * @param {Number} index Index into the values to calculate for.
 *
 * @returns {VisibilityState} VisibilityState if it could be determined or undefined. 
 */
function visibility_state( state, index ) {
   const current = state.current[ index ];
   const previous = ( typeof state.previous[ index ] !== "undefined" ) ? state.previous[ index ] : current;
   
   const threshold = 0.1;
   const value = [ 0, 0.25, 0.75, 1 ].find( x => Math.abs( x - current ) <= threshold );
   
   let result;
   
   switch ( value ) {
      case 0: {
         result = VisibilityState.OUT;
         break;
      }
      
      case 0.25:
      case 0.75: {
         result = ( current > previous ) ? VisibilityState.MOVING_IN : VisibilityState.MOVING_OUT;
         break;
      }
      
      case 1: {
         result = VisibilityState.IN;
         break;
      }
      
      default: {
         result = undefined;
         break;
      }
   }
   
   return result;
}

/**
 * Uses the intersection manager to manages visibility states for the observed elements.
 *
 * @param {Array<React.ref>} observingRefs References containing elements being observed.
 *
 * @returns {Array} Visibility states.
 */
function useVisibilityStates( observingRefs ) {
   /**
    * Visibility states for each observed element.
    * @type {Array}
    */
   const [ visibilityStates, _setVisibilityStates ] = useState( {
      current: observingRefs.map( () => undefined ),
      previous: observingRefs.map( () => undefined )
   } );
   
   /**
    * Reference to the visibility states so that we can modify them inside a different scope.
    */
   const visibilityStatesRef = useRef( visibilityStates );
   
   /**
    * Used to properly set the visibility states.
    */
   const setVisibilityStates = ( value ) => {
      visibilityStatesRef.current = value;
      _setVisibilityStates( value );
   };
   
   useIntersectionManager(
      [ 0, 0.25, 0.75, 1 ],
      observingRefs,
      ( ratios ) => {
         const previous = visibilityStatesRef.current.current.slice();
         
         // we need to carry over any previous values that weren't updated.
         const updated = ratios.map( ( ratio, index ) => {
            const prevValue = previous[ index ];
            if ( ratio != undefined || prevValue == undefined ) {
               return ratio;
            }
            else if ( prevValue <= 0.5 ) {
               return 0;
            }
            else if ( prevValue > 0.5 ) {
               return 1;
            }
         } );
         
         setVisibilityStates( {
            current: updated,
            previous: previous
         } );
      }
   );
   
   return visibilityStates;
}

export {
   VisibilityState,
   visibility_state,
   useVisibilityStates
}
