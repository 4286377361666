/**
 * Defines a reducer function to be used with React.useReducer.
 *
 * @param { function() } handler Function to handle the reduction.
 *
 * @returns { function(Object, Object) : Object } Reducer function.
 */
function define( handler ) {
   return function reducer( state, action ) {
      /** @type {Object} */
      let result;
      
      if ( action != null && typeof action === "object" ) {
         if ( ( typeof action.type === "string" || action.type instanceof String ) && !/^\s+$/.test( action.type ) ) {
            const normalizedAction = action.type.split( "/" )
            .map( x => x.trim().toLowerCase() )
            .join( "/" );
            
            result = handler( state, normalizedAction, action.value );
            if ( !result ) {
               throw new Error( `The reducer function's handler does not support action '${ action.type }'.` );
            }
         }
         else {
            throw new Error( "The reducer function requires the action to contain the key 'type' with a non-empty string value." );
         }
      }
      else {
         throw new Error( "The reducer function requires a valid action that is an object." );
      }
      
      return result;  
   }
}

/**
 * Splits the action into its first component and remaining components.
 *
 * @param {String} action String with actions delimited by '/'.
 *
 * @return {Array<String>} [first component, remaining components or undefined if none exist]
 */
function actionComponents( action ) {
   /** @type {Array<String>} */
   let components;
   
   const index = action.indexOf( "/" );
   if ( index != -1 ) {
      const first = action.substring( 0, index );
      const remainder = action.substring( index + 1 );
      
      components = [ first, !/^\s*$/.test(remainder) ? remainder : undefined ];
   }
   else {
      components = [ action, undefined ];
   }
   
   return components;
}

export {
   define,
   actionComponents
}
