import React, { useEffect, useState, useRef } from 'react';

import styled, { css } from 'styled-components';

import AppContext from '../contexts/appcontext';
import ProgressUpdater from '../base/progressupdater';
import RemoteImage from '../controls/remoteimage';
import { VisibilityState } from './view';
import { Ratio } from './../models/partnerItem';

const QuiltView = styled.div`
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-columns: repeat(5, 1fr);
  
  overflow: scroll;
  
  box-sizing: border-box;
  padding-top: 5vh;
  
  .compact & {
    grid-template-columns: repeat(2, 1fr);
    padding-top: unset;
  }
  
  @media screen and (min-width: 450px) {
    .compact & {
      grid-template-columns: repeat(4, 1fr);
      padding: 0 10%;
    }
  }
`;

const CenteredImage = styled.div`
  display: flex;
  
  align-items: center;
  justify-content: center;
  
  width: 100%;
  height: 100%;
  
  .compact & {
    height: unset;
    aspect-ratio: 5 / 3;
  }
  
  .compact &.horizontal {
    aspect-ratio: 2.2;
  }

  & > * {
    aspect-ratio: 1;
    pointer-events: none;
  }
  
  &.square > * {
    width: 60%;
    height: 60%;
  }
  
  &.vertical > * {
    width: 60%;
  }
  
  .compact &.vertical > * {
    width: unset;
    height: 60%; 
  }
  
  &.horizontal > * {
    width: 75%;
  }
  
  .compact &.horizontal > * {
    width: 50%;
    aspect-ratio: 1.8;
  }
  
  & img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const PartnersView = React.forwardRef((props, forwardedRef) => {
  const appContext = new AppContext();

  const [imageProgress] = useState(new ProgressUpdater());
  const [imagesReady, setImagesReady] = useState(false);
  const [wasVisible, setWasVisible] = useState(
    props.visibility == VisibilityState.IN
  );

  function update_progress(progress, id) {
    imageProgress.use(progress, id);
    if (imageProgress.isComplete) {
      setImagesReady(true);
    }
  }
  
  function getClassName(partner) {
    switch (partner.ratio) {
      case Ratio.SQUARE: {
        return "square";
      }
      
      case Ratio.VERTICAL: {
        return "vertical";
      }
      
      default: {
        return "horizontal";
      }
    }
  }

  useEffect(() => {
    if (!wasVisible && props.visibility == VisibilityState.IN) {
      setWasVisible(true);
    }
  }, [props.visibility, wasVisible]);

  return (
    <QuiltView ref={forwardedRef} className="no-scrollbar">
      {appContext.orderedPartners.map((partner, index) => {
        return (
          <CenteredImage className={getClassName(partner)} key={partner.name + index} ratio={partner.ratio}>
            <RemoteImage
              src={partner.imageUrl}
              visible={imagesReady && wasVisible}
              onProgress={update_progress}
            />
          </CenteredImage>
        );
      })}
    </QuiltView>
  );
});

export default PartnersView;
