import { makeApiRequest } from './request';

import PartnerItem from '../models/partnerItem';
import CompanyItem from '../models/companyItem';

import { valueAtKeyPath } from './utilities';

function getAllPartnersInOrder(onProgress) {
  const [pending, abortRequest] = makeApiRequest('partners', null, onProgress);

  const promise = pending.then((response) => {
    const items = response.fields.elementOrder;
    if (items && items.length > 0) {
      return Promise.resolve(items);
    } else {
      return Promise.reject(
        new Error(
          "The format of the response to 'getAllPartnersInOrder' was incorrect."
        )
      );
    }
  });

  return [promise, abortRequest];
}

function getAllCompanies(onProgress) {
  const [pending, abortRequest] = makeApiRequest('companies', null, onProgress);

  const promise = pending.then((response) => {
    const items = response.items.map((item) => {
      const name = item.fields.name;
      const id = item.fields?.image?.sys?.id;
      if (!id) return null;
      const asset = response.includes.Asset.find((asset) => {
        return asset.sys.id === id;
      });
      const imageUrl = asset?.fields?.file?.url;
      const height = asset?.fields?.file?.details?.image?.height;
      const width = asset?.fields?.file?.details?.image?.width;

      return new CompanyItem(name, imageUrl, height, width);
    });
    if (items.length > 0) {
      return Promise.resolve(items.filter((item) => item));
    } else {
      return Promise.reject(
        new Error(
          "The format of the response to 'getAllCompanies' was incorrect."
        )
      );
    }
  });

  return [promise, abortRequest];
}

export { getAllPartnersInOrder, getAllCompanies };
