/**
 * Timer whose updates are linked to the display's refresh.
 */
class DisplayTimer {
   constructor() {
      /**
       * Duration of the timer in seconds.
       * @type {Number}
       */
      this.duration = 0;
      
      /**
       * True if the timer is active or false otherwise.
       * @type {Boolean}
       */
      this.isActive = false;
      
      /**
       * Request ID for last requestAnimationFrame.
       * @type {Number}
       */
      this._requestId = null;
   }
   
   /**
    * Starts the timer with the specified duration.
    *
    * @param {Function(DisplayTimer, Number, Boolean)} onUpdate Periodic update callback while timer is active (timer, elapsed time, finished).
    * @param {Number} duration Duration of the timer in seconds.
    */
   start( onUpdate, duration ) {
      if ( !this.isActive ) {
         this.isActive = true;
         this.duration = duration;
         
         const durationInMS = duration * 1000;
         const startDate = new Date();
         
         const step = () => {
            const now = new Date();
            const elapsedTime = now.getTime() - startDate.getTime();
            
            if ( elapsedTime < durationInMS ) {
               onUpdate( this, elapsedTime / 1000, false );
               this._requestId = requestAnimationFrame( step );
            }
            else {
               this.isActive = false;
               this._requestId = null;
               
               onUpdate( this, this.duration, true );
            }
         };
         
         this._requestId = requestAnimationFrame( step );
      }
      else {
         console.warn( "Cannot start a timer that is already active." );
      }
   }
   
   /**
    * Stops the timer if it's currently active.
    */
   stop() {
      if ( this.isActive ) {
         this.isActive = false;
         
         if ( this._requestId ) {
            cancelAnimationFrame( this._requestId );
            this._requestId = null;
         }
      }
   }
}

/**
 * Creates a new timer and starts it.
 *
 * @param {Function(DisplayTimer, Number)} onUpdate Periodic update callback while timer is active (timer, elapsed time).
 * @param {Number} duration Duration of the timer in seconds.
 *
 * @returns {DisplayTimer} Newly created and started timer.
 */
DisplayTimer.timer = function CreateDisplayTimer( onUpdate, duration ) {
   const timer = new DisplayTimer();
   timer.start( onUpdate, duration );
   
   return timer;
}

export default DisplayTimer;
