/**
 * Request option keys.
 * @enum {String}
 */
const RequestOptions = Object.freeze({
    /**
     * HTTP Request headers in a Map<key, value>.
     */
    HEADER: "header",
    
    FORMDATA: "formdata",
    
    RESPONSE_TYPE: "responsetype"
});

/**
 * Errors encountered during requests.
 */
class RequestError extends Error {
    /**
     * Creates a new RequestError.
     *
     * @param {Number} statusCode Request's response status code.
     * @param {String} url Request's URL.
     */
    constructor( statusCode, url, ...params ) {
        super( ...params );
        
        // Maintains proper stack trace for where our error was thrown (only available on V8)
        if ( Error.captureStackTrace ) {
            Error.captureStackTrace( this, RequestError );
        }
        
        this.name = "RequestError";
        this.statusCode = statusCode;
        this.url = url;
    }
}

/**
 * Performs an asynchronous web request.
 *
 * @param {string} url URL to send the request.
 * @param {string} method HTTP request method to use, such as "GET", "POST", etc.
 * @param {Map} options Options to pass to the request, such as headers, form data, etc.
 * @param {Function} onProgress Function called with progress updates.
 *
 * @returns {Array} An array consisting of a promise that returns the response data on success or errors on failure and function to abort the request.
 */
function makeRequest( url, method = "GET", options = new Map(), onProgress = null ) {
    /**
     * Function used to abort the request.
     * @type {Function}
     */
    let abortFunc;
    
    const requestPromise = new Promise( ( resolve, reject ) => {
        let request = new XMLHttpRequest();
        
        abortFunc = () => request.abort();

        // the response type needs to be set before the request is open
        if ( options.has( RequestOptions.RESPONSE_TYPE ) ) {
            request.responseType = options.get( RequestOptions.RESPONSE_TYPE );
        }

        request.open( method, url );

        let formData = null;

        options.forEach( ( value, key ) => {
            switch ( key ) {
                case RequestOptions.HEADER: {
                    value.forEach( ( headerValue, header ) => request.setRequestHeader( header, headerValue ) );
                    break;
                }

                case RequestOptions.FORMDATA: {
                    formData = value;
                    break;
                }

                case RequestOptions.RESPONSE_TYPE: {
                    // intentionally blank, handled above. Maybe think about removing
                    // from the map above so that it doesn't get processed here?
                    // (billy 4-23-2020).
                    break;
                }

                default:
                    console.warn( `an unsupported option was specified: ${ key }.` );
            }
        });

        request.addEventListener( "load", () => {
            if ( request.status >= 200 && request.status < 300 ) {
                if ( onProgress ) {
                    // make sure that we send progress completion.
                    onProgress( 1, url );
                }

                switch ( request.responseType ) {
                    case "":
                    case "text": {
                        resolve( request.responseText );
                        break;
                    }
                    
                    case "document": {
                        resolve( request.responseXML );
                        break;
                    }

                    default: {
                        resolve( request.response );
                        break;
                    }
                }
            }
            else {
                let message;
                
                if ( request.responseType != "arraybuffer" && request.responseType != "blob" ) {
                    message = ( request.responseText ) ? request.responseText : request.statusText;
                }
                else {
                    switch ( request.status ) {
                        case 400: { message = "Bad request."; break; }
                        case 401: { message = "Unauthorized."; break; }
                        case 403: { message = "Forbidden."; break; }
                        case 404: { message = "Not found."; break; }
                        case 405: { message = "Method not allowed."; break; }
                        default:  { message = "Unknown."; break; }
                    }
                }

                const error = new RequestError( request.status, url, message );
                reject( error );
                
                // reject( new Error(`[${request.status}: ${request.responseURL}]${ message != "" ? " " + message : "" }`) );
            }
        });

        request.addEventListener( "error", () => {
            reject( new RequestError( undefined, url, "Request encountered an error." ) );
        } );

        // request.addEventListener("abort", () => {
        //     reject(new Error(request.responseText));
        // });

        if ( onProgress && typeof onProgress != "undefined" ) {
            request.addEventListener( "progress", e => {
                if ( e.lengthComputable ) {
                    var progress = e.loaded / e.total;
                    onProgress( progress, url );
                }
                else {
                    // we can't calculate the progress, so report nothing for now
                    onProgress( 0, url );
                }
            } );
        }

        if ( onProgress && typeof onProgress != "undefined" ) {
            onProgress( 0, url );
        }

        request.send( formData );
    });
    
    return [ requestPromise, abortFunc ];
}

export {
    RequestOptions,
    RequestError,
    makeRequest
};
