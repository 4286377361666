import { useState, useRef } from "react";

/**
 * Creates a useState React hook that is backed by a reference.
 *
 * @param {Any} initial Initial value or initializing function.
 *
 * @returns {Array} Getter and setter.
 */
function useRefState( initial ) {
   const [ getter, _setter ] = useState( initial );
   const stateRef = useRef( getter );
   
   const setter = ( value ) => {
      stateRef.current = value;
      _setter( value );
   };
   
   return [ stateRef, setter ];
}

export {
   useRefState
}
