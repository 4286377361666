class Vector2 {
    /**
     * Creates a new Vector2
     * @param {Number} x X-Axis value
     * @param {Number} y y-Axis value
     */
    constructor( x = 0, y = 0 ) {
        /**
         * @type {Number}
         */
        this.x = x;

        /**
         * @type {Number}
         */
        this.y = y;

    }

    /**
     * Returns the magnitude of the vector.
     * @returns {Number} Magnitude.
     */
    get magnitude() {
        const result = Math.sqrt( ( this.x * this.x ) + ( this.y * this.y ) );
        return result;
    }

    /**
     * Returns the vector as unit vector (normalized).
     * @returns {Vector2} Unit vector.
     */
    get unitVector() {
        const m = this.magnitude;
        const result =  new Vector2( this.x / m, this.y / m );
        return result;
    }

    /**
     * Sets this vector's values based on another vectors.
     * @param {Vector2} other Vector2 whose values will be copied to this vector.
     */
    setFrom( other ) {
        this.x = other.x;
        this.y = other.y;
    }

    /**
     * Returns whether this vector is equal to another.
     * @param {Vector2} other Another Vector2 to compare against.
     * @returns {Boolean} True if equal, false otherwise.
     */
    isEqualTo( other ) {
        const result = this.x == other.x && this.y == other.y;
        return result;
    }
    
    /**
     * Adds another Vector to this Vector.
     * @param {Vector2} other Vector to add to this one.
     * @returns {Vector2} Result Vector from adding the other vector to this one.
     */
    add( other ) {
        const result = new Vector2( this.x + other.x, this.y + other.y );
        return result;
    }
    
    /**
     * Subtracts another Vector from this Vector.
     * @param {Vector2} other Vector to subtract from this one.
     * @returns {Vector2} Result Vector from subtracting the other vector from this one.
     */
    subtract( other ) {
        const result = new Vector2( this.x - other.x, this.y - other.y );
        return result;
    }
    
    /**
     * Multiplies the vector by a scalar value.
     * @param {Number} scalar Scalar value.
     */
    multiply( scalar ) {
        const result = new Vector2( this.x * scalar, this.y * scalar );
        return result;
    }
    
    /**
     * Returns the dot product of this vector and another.
     * @param {Vector2} other Another Vector.
     * @returns {Number} Dot product of both vectors.
     */
    dot( other ) {
        const result = ( this.x * other.x ) + ( this.y * other.y );
        return result;
    }
    
    /**
     * Returns the angle between this vector and another.
     * @param {Vector2} other Another vector.
     * @returns {Number} Angle between both vectors.
     */
    angleBetween( other ) {
        const dotProduct = this.dot( other );
        const magnitudes = this.magnitude * other.magnitude;
        
        const result = Math.acos( dotProduct / magnitudes );
        return result;
    }
    
    toString() {
        const result = `(${ this.x }, ${ this.y })`;
        return result;
    }
}

/**
 * Creates a new vector with values from another vector.
 * @param {Vector2} other Vector to copy values from.
 * @returns {Vector2} New vector.
 */
Vector2.createFrom = function( other ) {
    return new Vector2( other.x, other.y );
}

Vector2.zero = Object.freeze( new Vector2() );

export default Vector2;
