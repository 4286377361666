import React, { useEffect, useLayoutEffect, useRef, useState } from "react";

import AppContext from "./../contexts/appcontext";

import "./../../css/sharemenuview.css";

/**
 * Displays a menu of sharing options.
 */
const ShareMenuView = React.forwardRef( ( props, forwardedRef ) => {
   const appContext = new AppContext();
   
   const viewRef = ( typeof forwardedRef !== "undefined" && forwardedRef != null ) ? forwardedRef : useRef( null );
   
   /**
    * Collection of selection items to be displayed.
    * @type {Array<String>}
    */
   const items = [
      "copy link to clipboard"
   ];
   
   function handle_selection( index ) {
      switch ( index ) {
         case 0: {
            if ( window["ClipboardItem"] ) {
               const type = "text/plain";
               const blob = new Blob( [ props.url ], { type } );
               
               const data = [ new ClipboardItem( {
                  [ type ]: blob
               } ) ];
               
               navigator.clipboard.write( data )
               .then( () => {
                  props.dismissModal();
               } )
               .catch( error => console.error( `Could not copy item to clipboard: ${ error }` ) );
            }
            else {
               // work-around for ClipboardItem missing browsers like Firefox.
               
               const temp = document.createElement( "textarea" );
               temp.style = "position: absolute; width: 1px; height: 1px; left: 5000vw; right: 5000vh";
               temp.value = props.url;
               
               document.body.appendChild( temp );
               temp.select();
               
               const result = document.execCommand( "copy" );
               if ( result ) {
                  props.dismissModal();
               }
               else if ( BUILD_ENV_DEBUG ) {
                  console.warn( "execCommand(\"copy\") failed to copy to the clipboard." );
               }
               
               document.body.removeChild( temp );
            }
            
            break;
         }
         
         default: {
            if ( BUILD_ENV_DEBUG ) {
               console.warn( `Invalid index '${ index }' for selection.` );
            }
            break;
         }
      }
   }
   
   useLayoutEffect( () => {
      if ( viewRef.current && props.start ) {
         const view = viewRef.current;
         
         if ( !appContext.isCompactSizeClass ) {
            const left = ( props.start.x / window.innerWidth ) * 100;
            const top = ( ( props.start.y + 50 ) / window.innerHeight ) * 100;
            
            view.style.left = `${ left.toFixed( 2 ) }%`;
            view.style.top = `${ top.toFixed( 2 ) }%`;
         }
         else {
            view.style.left = undefined;
            view.style.top = undefined;
         }
      }
   }, [ viewRef, props.start, appContext.isCompactSizeClass ] );
   
   return (
      <div ref={ viewRef } className="sharemenuview">
         <div>
            {
               items.map( ( item, index ) => (
                  <button key={ index } onClick={ () => handle_selection( index ) }>
                     <div>
                        <p>{ item }</p>
                     </div>
                  </button>
               ) )
            }
         </div>
      </div>
   );
} );

export default ShareMenuView;
