import React from "react";

import ContextInstance from "./contextinstance";
import * as Reducer from "./reducer";

import "./../extensions/array";

/**
 * Pattern used to simplify names.
 * @type {RegEx}
 */
const simplificationPattern = /[-+\:\s\'\.]/g;

/**
 * Size class for layout.
 * @enum {String}
 */
const LayoutSizeClass = Object.freeze( {
   NORMAL: "normal",
   COMPACT: "compact"
} );

const components = new Map([
   // [ "backend", Backend ]
]);

const nonComponents = {
   layout: {
      sizeClass: LayoutSizeClass.NORMAL,
      dimensions: {
         width: 0,
         height: 0
      }
   },
   
   /**
    * Delimited ('/') navigation path of the application.
    * @type {String}
    */
   path: "",
   
   /**
    * Current user settings.
    * @type {Object}
    */
   settings: {
      /**
       * True if the videos start muted, false otherwise.
       * @type {Boolean}
       */
      videosMuted: false
   },
   
   /**
    * Elements to ignore when trying to block scroll sections.
    * @type {Array<HTMLElement>}
    */
   sectionScrollIgnoreElements: [],
   
   /**
    * Data from backend.
    * @type {Object}
    */
   backendData: null,
   
   backButtonEnabled: false,
   
   backButtonOnClick: default_back_onclick,
   
   /**
    * Collection of currently displayed overlays.
    * @type {Array<React.Component>}
    */
   overlays: []
};

function default_back_onclick() {
   if ( BUILD_ENV_DEBUG ) {
      console.warn( `onClick has not been defined.` );
   }
}

const initialState = [ ...components.entries() ].reduce( ( output, component ) => {
   if ( component[ 1 ].defaultState != null && typeof component[ 1 ].defaultState !== "undefined" ) {  
      Object.defineProperty( output, component[ 0 ], {
         value: component[ 1 ].defaultState,
         enumerable: true
      } );
   }
   else if ( BUILD_ENV_DEBUG ) {
      console.error( `App context component '${ component[ 0 ] }' does not have a 'defaultState'.` );
   }
   
   return output;
}, Object.assign( {}, nonComponents ) );

const context = React.createContext( initialState );

const reducer = Reducer.define( ( state, action, data ) => {
   /** @type {Object} */
   let result = null;
      
   const [ category, actions ] = Reducer.actionComponents( action );
   if ( components.has( category ) ) {
      const categoryReducer = components.get( category ).reducer;
      const categoryAction = typeof data !== "undefined" && data != null ? { type: actions, value: data } : { type: actions };
      
      result = {
         ...state,
         [ category ]: categoryReducer( state[ category ], categoryAction ) 
      };
   }
   else {
      // throw new Error(`the action category '${category}' does not exist in the state.`);
      // handle the non-components.
      
      switch ( category ) {
         case "backenddata": {
            if ( actions == "set" ) {
               const workItems = ( data[ "workItems" ] ) ? data[ "workItems" ] : [];
               const workItemIndices = ( data[ "workItemIndices" ] ) ? data[ "workItemIndices" ] : [];
               const partners = ( data[ "partners" ] ) ? data[ "partners" ] : [];
               const partnerIndices = ( data[ "partnerIndices" ] ) ? data[ "partnerIndices" ] : [];
               const services = ( data[ "services" ] ) ? data[ "services" ] : [];
               const serviceIndices = ( data[ "serviceIndices" ] ) ? data[ "serviceIndices" ] : [];
               const sizzleReel = ( data[ "sizzleReel" ] ) ? data[ "sizzleReel" ] : "";
               const letterWorkItemIndices = ( data[ "letterWorkItemIndices" ] ) ? data[ "letterWorkItemIndices" ] : new Map();
               
               result = {
                  ...state,
                  backendData: {
                     workItems: workItems,
                     workItemIndices: workItemIndices,
                     partners: partners,
                     partnerIndices: partnerIndices,
                     services: services,
                     serviceIndices: serviceIndices,
                     sizzleReel: sizzleReel,
                     letterWorkItemIndices: letterWorkItemIndices
                  }
               };
            }
            
            break;
         }
         
         case "layout": {
            if ( actions == "set" ) {
               result = {
                  ...state,
                  layout: data
               }
            }
            
            break;
         }
         
         case "path": {
            if ( actions == "set" ) {
               result = {
                  ...state,
                  path: data
               }   
            }
            
            break;
         }
         
         case "settings": {
            if ( actions == "set" ) {
               result = {
                  ...state, 
                  settings: data
               }
            }
            
            break;
         }
         
         case "backbuttonenabled": {
            if ( actions == "set" ) {
               result = {
                  ...state,
                  backButtonEnabled: data
               }
            }
            
            break;
         }
         
         case "backbuttononclick": {
            if ( actions == "set" ) {
               result = {
                  ...state,
                  backButtonOnClick: data
               }
            }
            
            break;
         }
         
         case "sectionscrollignore": {
            const index = state.sectionScrollIgnoreElements.indexOf( data );
            
            if ( actions == "add" ) {
               if ( index == -1 ) {
                  result = {
                     ...state,
                     sectionScrollIgnoreElements: state.sectionScrollIgnoreElements.concat( data )
                  }
               }
            }
            else if ( actions == "remove" ) {
               if ( index != -1 ) {
                  result = {
                     ...state,
                     sectionScrollIgnoreElements: state.sectionScrollIgnoreElements.replace( index, 1 )
                  }
               }
            }
            
            break;
         }
         
         case "overlay": {
            if ( actions === "add" ) {
               if ( data.component && data.overlayId ) {
                  const index = state.overlays.findIndex( x => x.id == data.overlayId );
                  const overlay = { id: data.overlayId, component: data.component }
                  
                  if ( index == -1 ) {
                     result = {
                        ...state,
                        overlays: state.overlays.concat( overlay )
                     }  
                  }
                  else {
                     result = {
                        ...state,
                        overlays: state.overlays.replace( index, 1, overlay )
                     }
                  }
               }
               else if ( BUILD_ENV_DEBUG ) {
                  console.warn( `Attempted to add an overlay with specifying both a 'component' and an 'overlayId'.` );
               }
            }
            else if ( actions === "remove" ) {
               const index = state.overlays.findIndex( overlay => overlay.id == data );
               if ( index != -1 ) {
                  result = {
                     ...state,
                     overlays: state.overlays.replace( index, 1 )
                  } 
               }
            }
            
            break;
         }
         
         default: {
            console.warn( `unsupported non-component action: '${ action }'.` );
            break;
         }
      }
   }
   
   return result;
} );

/**
 * Pattern for the first and remainder parts of a path component.
 * @type {RegEx}
 */
const pathComponentsPattern = /^([^\s\/]+)(?:\/([^\s]+))?$/;

/**
 * Simplifies the specified name.
 *
 * @param {String} name Name to be simplified.
 *
 * @returns {String} Simplified name.
 */
function simplify( name ) {
   const result = name.replace( simplificationPattern, "" ).toLowerCase();
   return result;
}

class AppContext extends ContextInstance {
   constructor() {
      super( context );
   }
   
   dispatch( actionType, actionPayload ) {
      if ( BUILD_ENV_DEBUG ) {
         console.warn( "Deprecated function being used. This will be removed in the future." );
      }
      
      this._dispatch( actionType, actionPayload );
   }
   
   /**
    * Returns the current width and height.
    * @type {Object}
    */
   get layoutDimensions() {
      const dimensions = this._state.layout.dimensions;
      return dimensions;
   }
   
   /**
    * Returns the current layout size class.
    * @type {LayoutSizeClass}
    */
   get layoutSizeClass() {
      const sizeClass = this._state.layout.sizeClass;
      return sizeClass;
   }
   
   /**
    * True if the current size class is compact, false otherwise.
    * @type {Boolean}
    */
   get isCompactSizeClass() {
      return this._state.layout.sizeClass == LayoutSizeClass.COMPACT;
   }
   
   /**
    * Sets the layout dimensions and will calculate and set the size class.
    *
    * @param {Object} dimensions Dimensions containing the width and the height.
    */
   set layoutDimensions( dimensions ) {
      const sizeClass = ( dimensions.width >= 900 ) ? LayoutSizeClass.NORMAL : LayoutSizeClass.COMPACT;
      
      this._dispatch( "layout/set", {
         sizeClass: sizeClass,
         dimensions: {
            width: dimensions.width,
            height: dimensions.height
         }
      } );
   }
   
   /**
    * Delimited navigation path.
    * @type {String}
    */
   get path() {
      return this._state.path;
   }
   
   set path( path ) {
      this._dispatch( "path/set", path );   
   }
   
   /**
    * Returns the first and remaining path components.
    * @type {Array<String>}
    */
   get pathComponents() {
      const match = this.path.match( pathComponentsPattern );
      
      let first;
      let remainder;
      
      if ( match ) {
         first = match[ 1 ];
         remainder = match [ 2 ];
      }
      else {
         first = "";
      }
      
      return [ first, remainder ];
   }
   
   /**
    * True if the videos should start muted, false otherwise.
    * @type {Boolean}
    */
   get videosMuted() {
      return this._state.settings.videosMuted;
   }
   
   set videosMuted( muted ) {
      const updatedSettings = {
         ...this._state.settings,
         videosMuted: muted
      };
      
      this._dispatch( "settings/set", updatedSettings );
   }
   
   /**
    * Returns the currently downloaded work items.
    * @type {Array<WorkItem>}
    */
   get workItems() {
      const workItems = ( this._state.backendData?.workItems ) ? this._state.backendData?.workItems : [];
      return workItems;
   }
   
   /**
    * Returns the ordered collection of currently downloaded work items.
    * @type {Array<WorkItem>}
    */
   get orderedWorkItems() {
      const indices = ( this._state.backendData?.workItemIndices ) ? this._state.backendData?.workItemIndices : [];
      
      const allItems = this.workItems;
      const items = indices.map( index => allItems[ index ] );
      
      return items;
   }
   
   /**
    * Returns the currently downloaded services.
    * @type {Array<Service>}
    */
   get services() {
      const services = ( this._state.backendData?.services ) ? this._state.backendData?.services : [];
      return services;
   }
   
   /**
    * Returns the ordered collection of currently downloaded services.
    * @type {Array<Service>}
    */
   get orderedServices() {
      const indices = ( this._state.backendData?.serviceIndices ) ? this._state.backendData?.serviceIndices : [];
      
      const allItems = this.services;
      const items = indices.map( index => allItems[ index ] );
      
      return items;
   }
   
   /**
    * Returns the currently downloaded services.
    * @type {string}
    */
   get sizzleReel() {
      const sizzleReel = ( this._state.backendData?.sizzleReel ) ? this._state.backendData?.sizzleReel : "";
      return sizzleReel;
   }
   
   /**
    * Returns the currently downloaded partners.
    * @type {Array<PartnerItem>}
    */
   get partners() {
      const partners = ( this._state.backendData?.partners ) ? this._state.backendData?.partners : [];
      return partners;
   }
   
   /**
    * Returns the ordered collection of currently downloaded partners.
    * @type {Array<PartnerItem>}
    */
   get orderedPartners() {
      const indices = ( this._state.backendData?.partnerIndices ) ? this._state.backendData?.partnerIndices : [];
      
      const allItems = this.partners;
      const items = indices.map( index => allItems[ index ] );
      
      return items;
   }
   
   /**
    * True if the back button is enabled, false otherwise. (Cannot be enabled in the compact size class).
    * @type {Boolean}
    */
   get backButtonEnabled() {
      return this._state.backButtonEnabled && this._state.layoutSizeClass != LayoutSizeClass.COMPACT;
   }
   
   set backButtonEnabled( enabled ) {
      this._dispatch( "backbuttonenabled/set", enabled );
   }
   
   /**
    * The current on click function for the back button.
    * @type {Function}
    */
   get backButtonOnClick() {
      return this._state.backButtonOnClick;
   }
   
   set backButtonOnClick( onClick ) {
      this._dispatch( "backbuttononclick/set", onClick );
   }
   
   /**
    * Returns the current overlays.
    * @type {Array<React.Component>}
    */
   get overlays() {
      return this._state.overlays;
   }
   
   /**
    * Return the first work item with a matching name.
    *
    * @param {String} name Name to be matched.
    *
    * @returns {WorkItem} Found work item or null if it doesn't exist.
    */
   findWorkItemForName( name ) {
      const simplifiedName = simplify( name );
      
      const workItem = this.workItems.find( x => simplify( x.name ) == simplifiedName );
      return workItem;  
   }
   
   /**
    * Returns the image URL for the specified partner.
    *
    * @param {String|Array<String>} name Partner's name.
    *
    * @returns {String} Image URL.
    */
   getPartnerImageUrl( names ) {
      if ( !Array.isArray( names ) ) {
         names = [ names ];
      }
      
      let imageUrl = "";
      
      for ( const name of names ) {
         const simplifiedName = simplify( name );
         
         const partner = this.partners.find( p => {
            return simplify( p.name ) == simplifiedName;
         } );
         
         if ( partner ) {
            imageUrl = ( partner ) ? partner.imageUrl : "";
            break;
         }
      }
      
      return imageUrl;
   }
   
   /**
    * Returns the associated WorkItem for the specified letter.
    *
    * @param {String} letterId Letter unique identifier.
    *
    * @returns {WorkItem} Associated WorkItem if it exists or null.
    */
   getWorkItemForLetter( letterId ) {
      let workItem = null;
      
      const index = this._state.backendData?.letterWorkItemIndices.get( letterId );
      
      if ( index != null ) {
         workItem = this.workItems[ index ];
      }
      
      return workItem;
   }
   
   /**
    * Sets the backend data.
    *
    * @param {Array<WorkItem>} workItems Collection of work items.
    * @param {Array<PartnerItem>} partners Collection of ordered partners.
    */
   setBackendData( workItems, workItemsOrderIndices, partners, partnerOrderIndices, services, serviceIndices, sizzleReel, letterWorkItemIndices ) {
      if ( workItems && workItemsOrderIndices && partners && partnerOrderIndices && services && sizzleReel ) {
         this._dispatch( "backenddata/set", {
            workItems: workItems,
            workItemIndices: workItemsOrderIndices,
            partners: partners,
            partnerIndices: partnerOrderIndices,
            services: services,
            serviceIndices: serviceIndices,
            sizzleReel: sizzleReel,
            letterWorkItemIndices: letterWorkItemIndices
         } );
      }
      else if ( BUILD_ENV_DEBUG ) {
          console.warn( "To set the backend data 'workItems', 'workItemsOrderIndices', 'partners', 'partnerOrderIndices', 'services', 'serviceIndices', 'sizzleReel', and 'letterWorkItemIndices' must be specified." );
      }
   }
   
   /**
    * Returns whether the specified element is being ignore for section block scrolling.
    * 
    * @param {HTMLElement} element Element to check.
    *
    * @returns {Boolean} True if it's being ignored, false otherwise.
    */
   isIgnoringElementForSectionScrolling( element ) {
      let isIgnored = false
      let current = element;
      
      while ( current != null && current.id != "container" ) {
         if ( this._state.sectionScrollIgnoreElements.indexOf( current ) != -1 ) {
            isIgnored = true;
            break;
         }
         else {
            current = current.parentElement;
         }
      }
      
      return isIgnored;
   }
   
   /**
    * Ignore or remove ignoring an element for section block scrolling.
    *
    * @param {HTMLElement} element Element to be ignored or not ignored.
    * @param {Boolean} ignore True to ignore, false to not ignore.
    */
   ignoreElementForSectionScrolling( element, ignore ) {
      this._dispatch( `sectionscrollignore/${ ( ignore === true ) ? "add" : "remove" }`, element );
   }
   
   /**
    * Adds an overlay to the application.
    *
    * @param {React.Component} overlay Component to overlay all contents.
    * @param {String} overlayId Identifier to assign to the overlay.
    *
    * @returns {Number} Added overlay's identifier.
    */
   addOverlay( overlay, overlayId ) {
      this._dispatch( "overlay/add", {
         component: overlay,
         overlayId: overlayId
      } );
   }
   
   /**
    * Removes the specified overlay.
    *
    * @param {Number} overlayId Identifier for the overlay to remove.
    */
   removeOverlay( overlayId ) {
      this._dispatch( "overlay/remove", overlayId );
   }
}

export {
   LayoutSizeClass,
   AppContext as default,
   context,
   initialState,
   reducer,
};
