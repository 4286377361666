import { useEffect } from "react";

import { makeRequest } from "./../contexts/backend";

/**
 * Makes an API request while providing basic parsing of the response.
 *
 * @param {String} action Request action a '/' delimited path string.
 * @param {Object} data Object providing variables for the request and form data if necessary.
 * @param {Function} onProgress Function that will receive progress updates.
 *
 * @return {Array} Request promise and abort function.
 */
function makeApiRequest( action, data, onProgress ) {
   const [ pending, abort ] = makeRequest( action, data, onProgress );
   
   const final = pending.then(response => {
      let result;
      
      try {
         const parsedResponse = JSON.parse( response );
         
         let isError = false;
         // let responseData;
         // 
         // for ( const entry of Object.entries(parsedResponse) ) {
         //    switch ( entry[ 0 ].toLowerCase() ) {
         //       case "status": {
         //          if ( entry[ 1 ].toLowerCase() != "ok" ) {
         //             // anything other than "ok" will be treated as an error.
         //             isError = true;   
         //          }
         //          
         //          break;
         //       }
         //       
         //       case "data": {
         //          responseData = entry[ 1 ];
         //          break;
         //       }
         //       
         //       default: {
         //          // intentionally blank, we will just ignore keys we don't know about.
         //          break;
         //       }
         //    }
         // }
         
         const responseData = parsedResponse;
         
         if (!isError) {
            result = Promise.resolve( responseData );
         }
         else {
            const errorMessage = ( typeof responseData === "string" || responseData instanceof String ) ? responseData : "reason not provided";
            result = Promise.reject( new Error( `There was an error with the request: '${ action }': ${ errorMessage }` ) );
         }
      }
      catch ( e ) {
         result = Promise.reject( `The response to the request: '${ action }' was invalid.` );
      }
      
      return result;
   });
   
   return [ final, abort ];
}

/**
 * React hook for calling api calls and having them abort the request automatically on unmount.
 *
 * @param {function(): Array} apiCall Closure that calls the api and returns the request promise and abort function.
 * @param {Array} dependencies Collection of dependencies (similar to second parameter is useEffect).
 *
 * @returns {Promise} Promise containing the response on success.
 */
function useApiEffect( apiCall, dependencies = [] ) {
   let promiseResolve;
   let promiseReject;
   
   let promise = new Promise( ( resolve, reject ) => {
      promiseResolve = resolve;
      promiseReject = reject;
   } );
   
   useEffect( () => {
      /** 
       * Used during cleanup to abort the api requests if they have not been completed.
       * @type {Array<Function>}
       */
      let abortApiRequests = [];
      
      const apiRequest = apiCall();
      
      if ( apiRequest && Array.isArray( apiRequest ) ) {
         const requests = ( !Array.isArray( apiRequest[ 0 ] ) ) ? [ apiRequest ] : apiRequest;
         
         const promises = new Array( requests.length );
         abortApiRequests = new Array( requests.length );
         
         requests.forEach( ( [ request, abortRequest ], index ) => {
            abortApiRequests[ index ] = abortRequest;
            
            const updatedRequest = request.then( response => {
               abortApiRequests[ index ] = null;
               return Promise.resolve( response );
            } );
            
            promises[ index ] = updatedRequest;
         } );
         
         Promise.all( promises )
         .then( responses => {
            const response = ( responses.length > 1 ) ? responses : responses[ 0 ];
            promiseResolve( response );
         } )
         .catch( error => {
            promiseReject( error );
         } );
         
         return function ApiRequestCleanup() {
            abortApiRequests.filter( x => x !== null )
            .forEach( abortRequest => abortRequest() );
         }
      }
   }, dependencies );
   
   return promise;
}

export {
   makeApiRequest,
   useApiEffect
}
