class ProgressUpdater {
   constructor() {
      /** @type {Map<String, Number>} */
      this._progress = new Map();
      
      this._useFunc = function progressupdate_update( progress, identifier ) {
         this._progress.set( identifier, progress );
      }.bind( this );
   }
   
   /**
    * Total progress, value from 0.0 - 1.0.
    *
    * @type {Number}
    */
   get totalProgress() {
      const values = Array.from( this._progress.values() );
      const sum = values.reduce( ( result, value ) => result + value, 0 );
      const progress = ( values.length > 0 ) ? sum / values.length : 0;
      
      return progress;
   }
   
   /**
    * True if progress is complete, false otherwise.
    * @type {Boolean}
    */
   get isComplete() {
      return this.totalProgress == 1;
   }
   
   /**
    * Returns a function that used as a progress tracking callback.
    *
    * @type {Function}
    */
   get use() {
      return this._useFunc;
   }
}

export default ProgressUpdater;
