import React, { useEffect, useRef } from 'react';

import styled, { keyframes, css } from 'styled-components';
import AppContext from '../contexts/appcontext';

const PositioningDiv = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 20vw;
  height: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: env( safe-area-inset-right );
  
  @media screen and (max-width: 449px) {
    .compact & {
      position: fixed;
    }
  }
`;

const AnchoringDiv = styled.div`
  position: relative;
  width: 140px;
  height: 44px;
  
  .compact & {
    width: 74px;
    height: 32px;
  }
`;

const TopLevelDiv = styled.div`
  position: absolute;
  top: 0;
  right: 0;

  width: 140px;
  transition: width 0.5s ease-in-out;
  height: 32px;
  pointer-events: auto;
  
  &.open {
    width: 500px;
  }
  
  @media screen and (max-width: 450px) {
    .compact & {
      width: 100vw;
    }
  }
  
  &.closing {
    transition-delay: 0.25s;
  }
`;

const MainGrid = styled.div`
  position: relative;
  top: 0;
  right: 0;
  display: grid;
  grid-template-rows: 4px 40px 1fr;
  background-color: black;
  
  @media screen and (min-width: 450px) {
    .compact & {
      right: -15px;
    }
  }
`;

const HeaderBar = styled.div`
  display: flex;
  padding: 0 13px;
  justify-content: space-between;
  align-items: center;
  
  .compact & {
    padding: 0 0 0 16px;
  }
`;

const HeaderBarText = styled.h2`
  position: absolute;
  top: 4px;
  width: 132px;
  right: 5px;
  padding: 0;
  margin: 0;
  font-size: 27px;
  
  .compact & {
    position: unset;
    top: unset;
    width: unset;
    right: unset;
    padding: unset;
    
    margin: -12px 2px 0 0;
    
    font-size: 16pt;
  }
  
  @media screen and (min-width: 450px) {
    .compact & {
      margin: -12px 5px 0;
    }
  }
`;

const TopPinkBar = styled.div`
  width: 140px;
  justify-self: end;
  background-color: var(--color-styleguide-magenta);
  transition: width 0.5s ease-in-out;
  
  .compact & {
    width: 0%;
  }
  
  .open & {
    width: 100%;
  }
  
  .closing & {
    transition-delay: 0.25s;
  }
`;

const CloseButtonKeyframes = keyframes`
  from {
    transform: scale(1) rotate(180deg);
  }
  69% {
    transform: scale(1);

  }
  70% {
    transform: scale(1.5) rotate(359deg);
  }
  to { 
    transform: scale(1.0) rotate(360deg);
  }
`;

const CloseButton = styled.div`
  height: 21px;
  width: 21px;
  margin-left: 25%;
  opacity: 0;
  background-color: var(--color-styleguide-magenta);
  clip-path: polygon(
    20% 0%,
    0% 20%,
    30% 50%,
    0% 80%,
    20% 100%,
    50% 70%,
    80% 100%,
    100% 80%,
    70% 50%,
    100% 20%,
    80% 0%,
    50% 30%
  );
  
  .open & {
    margin-left: 0;
    opacity: 1;
    
    animation: ${CloseButtonKeyframes} 0.5s cubic-bezier(0.4, 0, 1, 1);
  }

  transition: margin-left 0.5s ease-in-out, opacity 0.5s ease-in-out;
  
  .closing & {
    animation-delay: 0.125s;
    transition-delay: 0.125s;
  }
`;

const FormGrid = styled.form`
  display: grid;
  gap: 0;
  grid-template-rows: 1fr 48px;
`;

const HeightChangeDiv = styled.div`
  height: 0px;
  transition: height 0.5s ease-in-out;
  overflow: hidden;
  transition-delay: 0.5s;
  
  .open & {
    height: 500px;
  }
  
  .closing & {
    transition-delay: 0s;
  }
`;

const FormContentGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  padding: 35px;
  box-sizing: border-box;
  
  .compact & {
    grid-template-columns: 1fr;
    gap: 10px;
    padding: 15px 30px;
  }
`;

const PlacementDiv = styled.div`
  grid-column: ${(props) => props.gridColumn};
  grid-row: ${(props) => props.gridRow};
  display: flex;
  flex-direction: column;
  opacity: 0;
  
  .compact & {
    grid-column: unset;
    grid-row: unset;
  }

  .open & {
    transition: ${(props) => 'opacity ' + props.timingIn + ' ease-in-out'};
    transition-delay: 0.5s;
    opacity: 1;
  }
  
  .closing & {
    transition: ${(props) => 'opacity ' + props.timingOut + ' ease-in-out'};
  }
  
  &:not(:last-of-type) {
    height: 58px;
  }
  
  &:last-of-type {
    height: 150px;
  }
`;

const TextInputSharedCSS = css`
  border: none;
  border-top: solid 1px var(--color-styleguide-magenta);
  border-radius: 0;
  
  background-color: #191919;
  color: white;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
  font-size: 16px;
`;

const StyledInput = styled.input`
  ${TextInputSharedCSS}
  height: 25px;
  width: ${(props) => (props.width ? props.width : '100%')};
  
  .compact & {
    width: 100%;
  }
`;

const StyledTextArea = styled.textarea`
  ${TextInputSharedCSS}
  height: auto;
  resize: none;
  margin: 0;
  width: 100%;
`;

const StyledButtonOpeningKeyframes = keyframes`
  from {
  }

  to {
    pointer-events: auto;
  }
`;

const StyledButtonClosingKeyframes = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

const StyledButton = styled.button`
  color: white;
  background-color: var(--color-styleguide-magenta);
  text-decoration: none;
  font-size: 21px;
  font-weight: 900;
  border: none;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding: 0;
  margin: 0;
  
  opacity: 0;
  pointer-events: none;
  animation: ${StyledButtonClosingKeyframes} 0.5s ease-in-out;
  
  .open & {
    opacity: 1;
    pointer-events: auto;
    animation: ${StyledButtonOpeningKeyframes} 0.5s ease-in-out;
  }

  ${(props) =>
    props.disabled &&
    css`
      background-color: grey;
    `}

  ${(props) =>
    props.successfullySent &&
    css`
      background-color: var(--color-styleguide-blue);
    `}

  transition: opacity 0.5s ease-in-out;
  transition-delay: 0.5s;
`;

const StyledLabel = styled.label`
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 7px;

  ${(props) =>
    props.required &&
    css`
      &:after {
        content: ' *';
        color: var(--color-styleguide-magenta);
      }
    `}
`;

const StyledRequiredLabel = styled.label`
  color: var(--color-styleguide-magenta);
  font-size: 13px;
  font-weight: 900;
  margin-top: 15px;
`;

export default function ContactForm(props) {
  const [name, setName] = React.useState('');
  const [companyName, setCompanyName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [projectDetails, setProjectDetails] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [successfullySent, setSuccessfullySent] = React.useState(false);
  const appContext = new AppContext();
  const topLevelRef = useRef(null);

  // Check that name is not empty or has whitespaces
  function isNameValid(name) {
    return name !== null && name.match(/^ *$/) === null;
  }

  // Check that email is not empty or has whitespaces and that it's a valid email address
  function isEmailValid(email) {
    return (
      email !== null &&
      email.match(/^ *$/) === null &&
      email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/) !== null
    );
  }

  // function to clear form
  function clearForm() {
    setName('');
    setCompanyName('');
    setEmail('');
    setPhone('');
    setProjectDetails('');
  }

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    if (!successfullySent) {
      try {
        const headers = new Headers();
        headers.append(
          'x-functions-key',
          '_Fbye3XK4DOZH3rwDiZ_CbnMEQueWk97wzsEiBk9benzAzFuHAxZcA=='
        );

        var form_data = new FormData();
        form_data.append('name', name);
        form_data.append('email', email);
        form_data.append('details', projectDetails);
        form_data.append('phone', phone);
        form_data.append('company', companyName);

        await fetch('https://api.pixelandtexel.com/api/ContactUs', {
          method: 'POST',
          headers: headers,
          body: form_data,
        }).then((response) => {
          if (response.status === 200) {
            if ( BUILD_ENV_DEBUG ) {
              console.log('success');
            }
            setSuccessfullySent(true);
            clearForm();
          } else if (response.status === 204) {
            if ( BUILD_ENV_DEBUG ) {
              console.log('no response from server');
            }
            setSuccessfullySent(true);
            clearForm();
          } else {
            if ( BUILD_ENV_DEBUG ) {
              console.log('Some error occurred ' + response.status);
            }
            response.json().then((data) => {
              console.log('Error message: ', data);
            });
          }
        });
      } catch (err) {
        console.error(err);
      }
    } else {
      closeForm();
    }
  };

  useEffect(() => {
    setOpen(true);
  }, []);
  
  useEffect(() => {
    if (topLevelRef.current) {
      const topLevel = topLevelRef.current;
      
      if (open) {
        if ( BUILD_ENV_DEBUG ) {
          console.log("opening");
        }
        topLevel.classList.add("open");
      }
      else if (topLevel.classList.contains("open")) {
        if ( BUILD_ENV_DEBUG ) {
          console.log("closing");
        }
        topLevel.classList.add("closing");
        
        // just in-case closing doesn't take effect immediately, necessary?
        requestAnimationFrame(() => topLevel.classList.remove("open"));
      }
    }
  }, [open]);

  function closeForm() {
    setOpen(false);
    setSuccessfullySent(false);
    clearForm();
    setTimeout(() => {
      appContext.removeOverlay('contactus');
    }, 750);
  }

  return (
    <PositioningDiv>
      <AnchoringDiv>
        <TopLevelDiv ref={topLevelRef}>
          <MainGrid >
            <TopPinkBar />
            <HeaderBar>
              <CloseButton onClick={() => closeForm()} />
              <HeaderBarText>{!appContext.isCompactSizeClass ? "Contact Us" : "Contact"}</HeaderBarText>
            </HeaderBar>
            <HeightChangeDiv>
              <FormGrid onSubmit={handleFormSubmit}>
                <FormContentGrid>
                  <PlacementDiv
                    timingIn='140ms'
                    timingOut='760ms'>
                    <StyledLabel htmlFor='name' required={true}>
                      Name
                    </StyledLabel>
                    <StyledInput
                      type='text'
                      name='name'
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                        setSuccessfullySent(false);
                      }}
                    />
                  </PlacementDiv>
                  <PlacementDiv
                    timingIn='280ms'
                    timingOut='560ms'>
                    <StyledLabel htmlFor='companyName'>
                      Company Name
                    </StyledLabel>
                    <StyledInput
                      type='text'
                      value={companyName}
                      name='companyName'
                      onChange={(e) => {
                        setCompanyName(e.target.value);
                        setSuccessfullySent(false);
                      }}
                    />
                  </PlacementDiv>
                  <PlacementDiv
                    timingIn='420ms'
                    timingOut='420ms'>
                    <StyledLabel htmlFor='email' required={true}>
                      Email Address
                    </StyledLabel>
                    <StyledInput
                      value={email}
                      type='email'
                      name='email'
                      onChange={(e) => {
                        setEmail(e.target.value);
                        setSuccessfullySent(false);
                      }}
                    />
                  </PlacementDiv>
                  <PlacementDiv
                    timingIn='560ms'
                    timingOut='280ms'>
                    <StyledLabel htmlFor='phone'>Phone</StyledLabel>
                    <StyledInput
                      value={phone}
                      type='phone'
                      name='phone'
                      width='66%'
                      onChange={(e) => {
                        setPhone(e.target.value);
                        setSuccessfullySent(false);
                      }}
                    />
                  </PlacementDiv>

                  <PlacementDiv
                    gridColumn='1 / span 2'
                    timingIn='700ms'
                    timingOut='140ms'>
                    <StyledLabel htmlFor='details'>Project Details</StyledLabel>
                    <StyledTextArea
                      name='details'
                      rows='4'
                      value={projectDetails}
                      onChange={(e) => {
                        setProjectDetails(e.target.value);
                        setSuccessfullySent(false);
                      }}
                    />
                    <StyledRequiredLabel onClick={() => setOpen(!open)}>
                      * Required Field
                    </StyledRequiredLabel>
                  </PlacementDiv>
                </FormContentGrid>
                <StyledButton
                  successfullySent={successfullySent}
                  disabled={
                    (!isNameValid(name) || !isEmailValid(email)) &&
                    !successfullySent
                  }>
                  {successfullySent
                    ? "Thanks! We'll be in touch soon."
                    : 'Submit'}
                </StyledButton>
              </FormGrid>
            </HeightChangeDiv>
          </MainGrid>
        </TopLevelDiv>
      </AnchoringDiv>
    </PositioningDiv>
  );
}
