import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import AppContext, { LayoutSizeClass } from "./../contexts/appcontext";

import { useApiEffect } from "./../backend/request";
import { getLogoLetterWorkItems } from "./../backend/logolettersapi";
import { getAllWorkItems, getWorkItemsOrder } from "./../backend/worksapi";
import { getAllCompanies, getAllPartnersInOrder } from "./../backend/partnersapi";
import { getAllServices, getServicesOrder, getSizzleReel } from "../backend/servicesapi";

import PartnerItem, { Ratio } from "./../models/partnerItem";

import Cursor from "./../controls/cursor";
import ContactForm from "./contact_form";
import ContactUsButton from "./contact_form_button";
import LogoView, { LogoEvent } from "./logoview";
import InfoView from "./infoview/infoview";
import ModalContainerView from "./modalcontainerview";
import * as YouTube from "./../controls/youtubeplayer";

import * as Animate from "./../base/animate";
import * as Delay from "./../base/delay";
import * as Page from "./../base/page";
import ProgressUpdater from "./../base/progressupdater";
import Publisher from "./../base/publisher";
import { useScrollManager } from "./../base/scrollmanager";
import Vector2 from "./../base/math/vector2";
import { visibility_state, useVisibilityStates } from "./view";

import "./../extensions/pointerevent";

import "./../../css/appview.css";

import PntLogoColoredSvg from "./../../media/pnt-logo-colored.svg?url";

let wheelThrottling = false;
let wheelDirection = 0;
let wheelTimeout = null;

/**
 * Location of the pointer when it's first down.
 * @type {Vector2}
 */
let pointerStart = null;

/**
 * Monitors an html elements pointer events.
 */
class PointerWatcher {
   constructor() {
      /**
       * True if the callbacks should be called, false otherwise.
       * @type {Boolean}
       */
      this.enabled = true;
      
      /**
       * Called when a pointer enter event occurs.
       * @type {Function(PointerEvent)}
       */
      this.onenter = null;
      
      /**
       * Called when a pointer leave event occurs.
       * @type {Function(PointerEvent)}
       */
      this.onleave = null;
      
      /**
       * Called when a pointer move event occurs.
       * @type {Function(PointerEvent)}
       */
      this.onmove = null;
      
      /**
       * Element whose pointer events are being listened to.
       * @type {HTMLElement}
       */
      this._element = null;
      
      /**
       * True if the move events are current being throttled, false otherwise.
       * @type {Boolean}
       */
      this._throttling = false;
      
      /**
       * Processes pointer enter events.
       *
       * @param {PointerEvent} event Triggered event.
       */
      this._onenter = function onenter( event ) {
         if ( this.enabled ) {
            if ( this.onenter ) {
               this.onenter( event );
            }
            
            this._addListenersFor( [ "move" ] );
         }
      }.bind( this );
      
      /**
       * Processes pointer leave events.
       *
       * @param {PointerEvent} event Triggered event.
       */
      this._onleave = function onleave( event ) {
         if ( this.enabled ) {
            if( this.onleave ) {
               this.onleave( event );
            }
            
            this._removeListenersFor( [ "move" ] );
         }
      }.bind( this );
      
      /**
       * Processes pointer move events.
       *
       * @param {PointerEvent} event Triggered event.
       */
      this._onmove = function onmove( event ) {
         if ( this.enabled && this.onmove ) {
            if ( !this._throttling ) {
               requestAnimationFrame( () => {
                  this.onmove( event );
                  
                  this._throttling = false;
               } );
            }
            else {
               this._throttling = true;
            }
         }
      }.bind( this );
      
      /**
       * Map of short event names to event definitions.
       * @type {Map<String, Array>}
       */
      this._events = [
         [ "enter", this._onenter ],
         [ "leave", this._onleave ],
         
         [ "move", this._onmove ]
      ].reduce( ( output, def ) => {
         output.set( def[ 0 ], [ `pointer${ def[ 0 ] }`, def[ 1 ] ] );
         return output;
      }, new Map() );
   }
   
   /**
    * Returns fence event definitions (ex. enter, leave).
    * @type {Array}
    */
   get _fenceEvents() {
      return [ "enter", "leave" ];
   }
   
   /**
    * Begins listening to pointer events on the specified element.
    *
    * @param {HTMLElement} element Element whose pointer events will be listened to.
    */
   attachTo( element ) {
      if ( this._element != null ) {
         this.detach();
      }
      
      this._element = element;
      this._addListenersFor( this._fenceEvents );
   }
   
   /**
    * Stops listening to pointer events on the attached element.
    */
   detach() {
      if ( this._element ) {
         this._removeListenersFor( this._fenceEvents );
         this._element = null;
      }
   }
   
   /**
    * Returns event definitions for a collection of event short names.
    *
    * @param {Array} names Collection of event short names.
    *
    * @returns {Array<Array>} Collection of event definitions.
    */
   _eventsFor( names ) {
      const events = names.map( x => this._events.get( x ) );
      return events;
   }
   
   /**
    * Adds event listeners for event definitions matching the collection of event short names.
    *
    * @param {Array<String>} names Collection of event short names.
    */
   _addListenersFor( names ) {
      if ( this._element ) {
         this._eventsFor( names ).map( def => this._element.addEventListener( def[ 0 ], def[ 1 ] ) );
      }
      else if ( BUILD_ENV_DEBUG ) {
         console.warn( `Cannot add events '${ names }' because there is no attached element.` );
      }
   }
   
   /**
    * Removes event listeners for event definitions matching the collection of event short names.
    *
    * @param {Array<String>} names Collection of event short names.
    */
   _removeListenersFor( names ) {
      if ( this._element ) {
         this._eventsFor( names ).map( def => this._element.removeEventListener( def[ 0 ], def[ 1 ] ) );
      }
      else if ( BUILD_ENV_DEBUG ) {
         console.warn( `Cannot remove events '${ names }' because there is no attached element.` );
      }
   }
}

const LoadingOverlay = React.forwardRef( ( props, forwardedRef ) => {
   return (
      <div ref={ forwardedRef } className="loading-overlay">
         { false && <p>Loading…</p> }
      </div>
   );
} );

/**
 * Main container view of the site.
 */
const AppView = React.forwardRef( ( props, forwardedRef ) => {
   const appContext = new AppContext();

   /**
    * Reference to the view's main element.
    */
   const appViewRef = ( typeof forwardedRef !== "undefined" && forwardedRef != null ) ? forwardedRef : useRef( null );
   
   /**
    * Reference to the sections container.
    */
   const contentsRef = useRef( null );
   
   /**
    * Reference to the cursor element.
    */
   const cursorRef = useRef( null );
   
   /**
    * Reference to the loading overlay's element.
    */
   const loadingOverlayRef = useRef( null );
   
   const headerLogoRef = useRef( null );
   
   /**
    * True if the cursor is hidden on the page, false if it's visible.
    */
   const [ isCursorHidden, setIsCursorHidden ] = useState( true );
   
   const [ cursorBackground, setCursorBackground ] = useState( null );
   
   /**
    * Manages scrolling between the site's sections.
    */
   const [ sectionIndex, scrollToSection, cancelScroll ] = useScrollManager(
      appViewRef,
      element => { 
         return Array.from( element.querySelectorAll( ".contents > section" ) )
                  .map( e => e.offsetLeft );
      },
      {
         duration: 1,
         compactDuration: 0.5
      }
   );
   
   /**
    * Section index to be animated to on load.
    * @type {Number}
    */
   const [ animationIndex, setAnimationIndex ] = useState( null );
   
   const [ watcher ] = useState( new PointerWatcher() );
   
   const [ pointerPosPublisher ] = useState( new Publisher() );
   
   const [ isLoading, setIsLoading ] = useState( true ); 
   
   const [ loadingProgress ] = useState( new ProgressUpdater() );
   
   const [ isShowingLogo, setIsShowingLogo ] = useState( true );
   
   /**
    * True if the intro has complete, false otherwise.
    */
   const [ isIntroDone, setIsIntroDone ] = useState( false );
   
   /**
    * True if the cursor is inside a logo character, false otherwise.
    * @type {Boolean}
    */
   const [ isInLogoCharacter, setIsInLogoCharacter ] = useState( false );
   
   const sectionDefs = [
      [ "logo", <LogoView onLogoLoad={ update_cursor_background } active={ isShowingLogo } onEvent={ handle_logo_event } onProgress={ loadingProgress.use } /> ],
      [ "browser", <InfoView /> ]
   ];
   
   /**
    * References to the root elements of each section.
    * @type {Array<HTMLElement>}
    */
   const [ sectionRefs ] = useState( sectionDefs.map( () => useRef( null ) ) );
   
   /**
    * Visibility states for each of the sections.
    */
   const sectionVisibilityStates = useVisibilityStates( sectionRefs );
   
   const sections = sectionDefs.map( ( def, index ) => [
      def[ 0 ],
      React.cloneElement( def[ 1 ], {
         ref: sectionRefs[ index ],
         visibility: visibility_state( sectionVisibilityStates, index )
      } )
   ] );
   
   /**
    * Updates the stored dimensions of the site.
    */
   function update_dimensions() {
      appContext.layoutDimensions = { width: window.innerWidth, height: window.innerHeight };
   }
   
   /**
    * Process events from the LogoView.
    *
    * @param {LogoEvent} event Event that occurred.
    * @param {Any} eventData Optional data associated with the event.
    */
   function handle_logo_event( event, eventData ) {
      switch ( event ) {
         case LogoEvent.ENTER_CHARACTER: {
            setIsInLogoCharacter( true );
            break;
         }
         
         case LogoEvent.LEAVE_CHARACTER: {
            setIsInLogoCharacter( false );
            break;
         }
         
         case LogoEvent.INTRO_DONE: {
            setIsIntroDone( true );
            break;
         }
         
         case LogoEvent.SELECTED_WORK: {
            appContext.path = `work/${ eventData }`;
            break;
         }
         
         default: {
            if ( BUILD_ENV_DEBUG ) {
               console.warn( `Could not process unknown LogoView event '${ event }'.` );
            }
            
            break;
         }
      }
   }
   
   /**
    * Sets the cursor background or updates the offset.
    *
    * @param {HTMLImageElement} image Image to be set as the cursor background (currently set background image will be used if not specified).
    * @param {Number} imageScale Scale to draw the background at.
    */
   function update_cursor_background( image, imageScale = 1 ) {
      if ( appViewRef.current && ( image || cursorBackground ) ) {
         if ( typeof image === "undefined" ) {
            image = cursorBackground.image;
            imageScale = cursorBackground.scale;
         }
         
         const view = appViewRef.current;
         
         const viewWidth = view.clientWidth;
         const viewHeight = view.clientHeight;
         
         const imageWidth = image.width * imageScale;
         const imageHeight = image.height * imageScale;
         
         const offset = new Vector2( ( viewWidth - imageWidth ) / 2, ( viewHeight - imageHeight ) / 2 );
         
         setCursorBackground( {
            image: image,
            scale: imageScale,
            offset: offset
         } );
      }
   }
   
   const pointerEvents = [
      [ "pointermove", handle_pointermove ],
      [ "pointerup", handle_pointerup ],
      [ "pointercancel", handle_pointercancel ]
   ];
   
   /**
    * Removes the pointer events that were added after the pointer was down.
    */
   function remove_pointerevents() {
      if ( contentsRef.current ) {
         const container = contentsRef.current;
         
         pointerEvents.forEach( def => container.removeEventListener( def[ 0 ], def[ 1 ] ) );
      }
   }
   
   /**
    * Process pointer move events.
    *
    * @param {PointerEvent} event Triggered event.
    */
   function handle_pointermove( event ) {
      // TODO: throttle
      
      const translation = event.pageVec2.subtract( pointerStart );
      // const threshold = 3;
      
      if ( translation.y > 0 ) {
         remove_pointerevents();
         previous_section();
      }
      else if ( translation.y < 0 ) {
         remove_pointerevents();
         next_section();
      }
   }
   
   /**
    * Process pointer cancel events.
    *
    * @param {PointerEvent} event Triggered event.
    */
   function handle_pointercancel( event ) {
      remove_pointerevents();
   }
   
   /**
    * Process pointer up events.
    *
    * @param {PointerEvent} event Triggered event.
    */
   function handle_pointerup( event ) {
      handle_pointercancel( event );
   }
   
   /**
    * Process pointer down events.
    *
    * @param {PointerEvent} event Triggered event.
    */
   function handle_pointerdown( event ) {
      if ( !appContext.isIgnoringElementForSectionScrolling( event.target ) ) {
         pointerStart = ( typeof event.nativeEvent !== "undefined" ) ? event.nativeEvent.pageVec2 : event.pageVec2;
         
         if ( contentsRef.current ) {
            const container = contentsRef.current;
            
            pointerEvents.forEach(  def => container.addEventListener( def[ 0 ], def[ 1 ] ) );
         }
      }
   }
      
   /**
    * Initiates navigation to the next section.
    */
   function next_section() {
      const nextIndex = sectionIndex + 1;
      if ( nextIndex < sections.length ) {
         setAnimationIndex( nextIndex );
      }
   }
   
   /**
    * Initiates navigation to the previous section.
    */
   function previous_section() {
      const nextIndex = sectionIndex - 1;
      if ( nextIndex >= 0 ) {
         setAnimationIndex( nextIndex );
      }
   }
   
   /**
    * Process wheel events.
    *
    * @param {WheelEvent} event Triggered event.
    */
   function handle_wheel( event ) {      
      const target = event.target;
      
      if ( !wheelThrottling ) {
         let direction;
         
         if ( event.deltaY > 0 ) {
            direction = 1;
         }
         else if ( event.deltaY < 0 ) {
            direction = -1;
         }
         else {
            direction = 0;
         }
         
         requestAnimationFrame( () => {
            if ( wheelTimeout != null ) {
               clearTimeout( wheelTimeout );
            }
            
            if ( !appContext.isIgnoringElementForSectionScrolling( target ) ) {
               if ( wheelDirection == 0 ) {
                  // TODO: determine if we need to adjust for scroll direction on macs (Billy 9-8-2022).
                  
                  if ( direction > 0 ) {
                     next_section();
                  }
                  else if ( direction < 0 ) {
                     previous_section();
                  }
                  
                  wheelDirection = direction;
               }
               
               wheelTimeout = setTimeout( () => {
                  wheelDirection = 0;
               }, 100 );
            }
            
            wheelThrottling = false;
         } );
      }
      else {
         wheelThrottling = true;
      }
   }
   
   /**
    * Process keydown events.
    *
    * @param {KeyboardEvent} event Triggered event.
    */
   function handle_keydown( event ) {
      if ( !cancelScroll && appContext.overlays.length == 0 /*&& event.shiftKey*/ ) {
         let nextIndex = -1;
         
         switch ( event.code ) {
            case "Digit1": {
               nextIndex = 0;
               break;
            }
            
            case "Digit2": {
               nextIndex = 1;
               break;
            }
            
            default: {
               // intentionally blank.
               break;
            }
         }
         
         if ( nextIndex != -1 ) {
            setAnimationIndex( nextIndex );
         }
      }
   }
   
   function handle_logo_button() {
      if ( sectionIndex != 0 ) {
         appContext.path = "";
      }
   }
      
   // --- Set the initial path.
   
   useEffect( () => {
      let initialPath = "";
      
      // determine which pathing system that we're working from: pathname or query.
      
      if ( /^\s*$/.test( document.location.search ) ) {
         const pathname = document.location.pathname;
         
         if ( pathname != "/" ) {
            // strip the leading and trailing slashes.
            
            const endIndex = ( pathname[ pathname.length - 1 ] == "/" ) ? pathname.length - 1 : pathname.length;
            const normalizedPathname = pathname.slice( 1, endIndex );
            
            const [ first, ...remainder ] = normalizedPathname.split( "/" );
            
            const validFirstElements = [
               "partners",
               "work",
               "services",
               "about"
            ];
            
            const normalizedFirst = first.toLowerCase();
            
            if ( validFirstElements.indexOf( normalizedFirst ) != -1 ) {
               if ( normalizedFirst != "work" || remainder.length < 1 ) {
                  initialPath = normalizedFirst;
               }
               else {
                  initialPath = `${ normalizedFirst }/${ remainder[ 0 ] }`;
               }
            }
            else if ( BUILD_ENV_DEBUG ) {
               console.warn( `Cannot set an initial path from '${ normalizedPathname }', unsupported values.` );
            }
         }
      }
      else {
         Page.enumerateUrlQuery( ( key, value ) => {
            switch ( key ) {
               case "about": {
                  initialPath = "about";
                  break;
               }
               
               case "navigation": {
                  initialPath = "navigation";
                  break;
               }
               
               case "partners": {
                  initialPath = "partners";
                  break;
               }
               
               case "services": {
                  initialPath = "services";
                  break;
               }
               
               case "work": {
                  initialPath = `work${ ( typeof value !== "undefined" ) ? `/${ value }` : "" }`;
                  break;
               }
               
               default: {
                  if ( BUILD_ENV_DEBUG ) {
                     console.warn( `[DEBUG] unsuppored query parameter '${ key }'${ ( typeof value !== "undefined" ) ? ` with value '${ value }'` : "" }.` );   
                  }
                  
                  break;
               }
            }
         } );
      }
      
      appContext.path = initialPath;
   }, [] );
   
   // --- Navigate according to the path
   
   useEffect( () => {
      if ( !isLoading ) {
         if ( BUILD_ENV_DEBUG ) {
            console.log( `current path: '${ appContext.path }'.` );
         }
         
         const [ first ] = appContext.pathComponents;
         
         switch ( first ) {
            case "about":
            case "navigation":
            case "partners":
            case "services":
            case "work": {
               setAnimationIndex( 1 );
               break;
            }
            
            case "": {
               setAnimationIndex( 0 );
               break;
            }
            
            default: {
               if ( BUILD_ENV_DEBUG ) {
                  console.warn( `Unsupported path '${ appContext.path }'.` );
               }
               
               break;
            }
         }
      }
   }, [ appContext.path, isLoading ] );
   
   // --- Fetch data
   
   useApiEffect( () => {
      if ( isLoading ) {
         if ( BUILD_ENV_DEBUG ) {
            console.log( "fetching backend data…" );
         }
         
         appContext.addOverlay( <LoadingOverlay ref={ loadingOverlayRef } props={ loadingProgress } />, "loading" );
         
         // the youtube loading progress used to be in it's own effect,
         // but the the total loading was not being kept track of, so the
         // quick solution was to group the youtube loading here as a "fake"
         // api request, that's why the abort function is empty (Billy 11-10-2022).
         
         loadingProgress.use( 0, "youtube-api" );
         
         return [
           getAllWorkItems( loadingProgress.use ),
           getWorkItemsOrder( loadingProgress.use ),
           getAllCompanies( loadingProgress.use ),
           getAllPartnersInOrder( loadingProgress.use ),
           getAllServices( loadingProgress.use ),
           getServicesOrder( loadingProgress.use ),
           getSizzleReel( loadingProgress.use ),
           getLogoLetterWorkItems( loadingProgress.use ),
           [ YouTube.initialize(), () => {} ]
         ];
      }
   }, [ isLoading ] )
   .then( ( [ workItems, workItemsOrder, companies, partnersOrder, services, servicesOrder, sizzleReel, letterWorkItems ] ) => {
      if ( BUILD_ENV_DEBUG ) {
         console.log( "fetching backend data complete!" );
      }
      
      loadingProgress.use( 1, "youtube-api" );
      
      const simplificationPattern = /[-+:\s\']/g;
      
      let workItemIndices = [];
      
      if ( workItemsOrder.length > 0 ) {
         const nameToIndex = workItems.reduce( ( results, item, index ) => {
            const simplified = item.name.replace( simplificationPattern, "" ).toLowerCase();
            results.set( simplified, index );
            
            return results;
         }, new Map() );
         
         workItemIndices = workItemsOrder.reduce( ( results, name ) => {
            const simplifiedName = name.replace( simplificationPattern, "" ).toLowerCase();
            const index = nameToIndex.get( simplifiedName );
            
            if ( index != null ) {
               results.push( index );
            }
            else {
               console.warn( `Could not find work item named: '${ name }'.` );
               
               if ( BUILD_ENV_DEBUG ) {
                  console.log( workItems.map( x => x.name ) );
               }
            }
            
            return results;
         }, [] );
      }
      else {
         console.warn( "No order was specified for the work items." );
      }
      
      const letterWorkItemIndices = new Map();
      
      for ( const [ letter, workItemId ] of Object.entries( letterWorkItems ) ) {
         const index = workItems.findIndex( i => i.id == workItemId );
         
         if ( index != -1 ) {
            letterWorkItemIndices.set( letter, index );
         }
         else {
            console.warn( `Could not find work item associated with letter '${ letter }'.` )
         }
      }
      
      let partners = [];
      let partnerIndices = [];
      
      if ( partnersOrder.length > 0 ) {
         const nameToIndex = companies.reduce( ( results, item, index ) => {
            const simplified = item.name.replace( simplificationPattern, "" ).toLowerCase();
            results.set( simplified, index );
            
            return results;
         }, new Map() );
         
         partnerIndices = partnersOrder.reduce( ( results, name ) => {
            const simplifiedName = name.replace( simplificationPattern, "" ).toLowerCase();
            const index = nameToIndex.get( simplifiedName );
            
            if ( index != null ) {
               results.push( index );
            }
            else {
               console.warn( `Could not find parnter named: '${ name }'.` );
            }
            
            return results;
         }, [] );
      }
      
      partners = companies.map( company => {
         let ratio = Ratio.HORIZONTAL;
         
         if ( company.imageUrl ) {
            const value = company.height / company.width;
            
            if ( value >= 1.25 ) {
               ratio = Ratio.VERTICAL;
            }
            else if ( value >= 0.6 ) {
               ratio = Ratio.SQUARE;
            }
         }
         
         return new PartnerItem( company.name, company.imageUrl, ratio );
      } );
      
      let serviceIndices = [];
      
      if ( servicesOrder.length > 0 ) {
         const titleToIndex = services.reduce( ( results, item, index ) => {
            const simplified = item.title.replace( simplificationPattern, "" ).toLowerCase();
            results.set( simplified, index );
            
            return results;
         }, new Map() );
         
         serviceIndices = servicesOrder.reduce( ( results, title ) => {
            const simplifiedTitle = title.replace( simplificationPattern, "" ).toLowerCase();
            const index = titleToIndex.get( simplifiedTitle );
            
            if ( index != null ) {
               results.push( index );
            }
            else {
               console.warn( `Could not find work item named: '${ title }'.` );
               
               if ( BUILD_ENV_DEBUG ) {
                  console.log( services.map( x => x.title ) );
               }
            }
            
            return results;
         }, [] );
      }
      else {
         console.warn( "No order was specified for the work items." );
      }
      
      appContext.setBackendData( workItems, workItemIndices, partners, partnerIndices, services, serviceIndices, sizzleReel, letterWorkItemIndices );
      setIsLoading( false );
   } )
   .catch( error => console.error( `Error while loading initial data: ${ error }` ) );
   
   // --- Remove loading overlay when finished loading
   
   useEffect( () => {
      if ( !isLoading && loadingOverlayRef.current ) {
         Animate.transition( loadingOverlayRef.current, "hidden" )
         .then( () => {
            appContext.removeOverlay( "loading" );
         } );
      }
   }, [ isLoading, loadingOverlayRef ] );
   
   // --- Update cursor
   
   useEffect( () => {
      if ( appViewRef.current != null && cursorRef.current != null ) {
         const appView = appViewRef.current;
         
         watcher.attachTo( appView );
         
         watcher.onenter = () => {
            setIsCursorHidden( false );
            
            document.body.style.cursor = "none";
         }
         
         watcher.onleave = () => {
            setIsCursorHidden( true );
            
            document.body.style.cursor = "auto";
         }
         
         let lastLocation = new Vector2( -1, -1 );
         
         watcher.onmove = event => {
            const location = event.clientVec2;
            
            if ( location.x != lastLocation.x || location.y != lastLocation.y ) {
               pointerPosPublisher.publish( location.x, location.y );
               lastLocation = location;
            }
         }
         
         return function cleanup() {
            watcher.detach();
         }
      }
   }, [ appViewRef, cursorRef, pointerPosPublisher ] );
   
   useEffect( () => {
      if ( !isLoading && animationIndex != null && headerLogoRef.current ) {
         const headerLogo = headerLogoRef.current;
         
         if ( animationIndex != sectionIndex ) {
            new Promise( resolve => {
               if ( sectionIndex == 0 ) {
                  // animate the logo to the header.
                  
                  headerLogo.classList.add( "animating-out" );
                  headerLogo.classList.remove( "hidden" );
                  
                  setIsShowingLogo( false );
                  
                  Delay.untilNextFrame()
                  .then( () => Animate.transition( headerLogo, "-full" ) )
                  .then( () => {
                     headerLogo.classList.remove( "animating-out" );
                     resolve();
                  } );  
               }
               else {
                  // animate the logo back to full.
                  
                  headerLogo.classList.add( "animating-in" );
                  
                  Delay.untilNextFrame()
                  .then( () => Animate.transition( headerLogo, "full" ) )
                  .then( () => {
                     setIsShowingLogo( true );
                     return Delay.wait(0.12);
                  } )
                  // .then( () => Animate.transition( headerLogo, "faded-out" ) )
                  .then( () => {
                     headerLogo.classList.remove( "animating-in" );//, "faded-out" );
                     headerLogo.classList.add( "hidden" );
                  } );
               }
            } )
            
            scrollToSection( animationIndex )
            .then( () => setAnimationIndex( null ) );
         }
         else {
            setAnimationIndex( null );
         }
      }
   }, [ animationIndex, isLoading, headerLogoRef ] );
   
   useEffect( () => {
      if ( !isLoading ) {
         document.addEventListener( "keydown", handle_keydown );
         
         return function keydown_cleanup() {
            document.removeEventListener( "keydown", handle_keydown );
         }
      }
   }, [ isLoading ] );
   
   useEffect( () => {
      let resizeThrottling = false;
      
      function onresize() {
         if ( !resizeThrottling ) {
            requestAnimationFrame( () => {
               update_dimensions();
               update_cursor_background();
               
               resizeThrottling = false;
            } );
            
            resizeThrottling = true;
         }
         else {
            resizeThrottling = true;
         }
      }
      
      window.addEventListener( "resize", onresize );
      
      return function cleanup() {
         window.removeEventListener( "resize", onresize );
      }
   }, [ appViewRef, cursorBackground ] );
   
   // set the initial layout size class.
   useLayoutEffect( () => update_dimensions(), [] );
   
   useEffect( () => {
      // Align current section to the left of the browser on resize.
      
      if ( contentsRef.current && appViewRef.current ) {
         const view = appViewRef.current;
         
         const contents = contentsRef.current;
         const contentSections = Array.from( contents.querySelectorAll( ":scope > section" ) );
         
         if ( sectionIndex < contentSections.length ) {
            const section = contentSections[ sectionIndex ];
            
            view.scrollTo( {
               left: section.offsetLeft,
               behavior: "instant"   
            } );
         }
      }
   }, [ appContext.layoutDimensions, sectionIndex ] );
   
   useEffect( () => {
      // we don't want any pointer events processing while we're in compact mode.
      watcher.enabled = appContext.layoutSizeClass != LayoutSizeClass.COMPACT;
   }, [ appContext.layoutSizeClass ] );
   
   // offset to the current section on initial load.
   
   useLayoutEffect( () => {
      if ( !isLoading ) {
         scrollToSection( sectionIndex, false, true );
      }
   }, [ isLoading ] );
   
   // cleanup
   
   useEffect( () => {
      if ( cancelScroll ) {
         const cancel = cancelScroll;
         
         return function cleanup() {
            cancel();
         }
      }
   }, [ cancelScroll ] );
   
   const cursorIndicator = ( !isInLogoCharacter ) ? { type: "dot", options: { immediate: true } } : { type: "play", options: { immediate: true } };
   
   const currentCursorBackground = ( cursorBackground && appContext.overlays.length == 0 && sectionIndex == 0 && animationIndex == null && isIntroDone ) ? cursorBackground : null;
   
   const isBackVisible = appContext.isCompactSizeClass && !/^\s*$/.test( appContext.path );
   
   return (
      <div ref={ appViewRef } className={ `appview${ ( appContext.layoutSizeClass == LayoutSizeClass.NORMAL ) ? "" : " compact" }` }>
         <div ref={ contentsRef } className="contents">
            {
               sections.map( ( def, index ) => (
                  <section key={ def[ 0 ] }>
                    { ( index == 0 || !isLoading ) &&
                       def[ 1 ]
                    }
                  </section>
               ) )
            }
         </div>
         
         <div className={ `header${ ( !isIntroDone ) ? " hidden" : "" }` }>
            <div className={ `layer background${ ( sectionIndex == 0 || cancelScroll != null ) ? " hidden" : "" }` }>
               <div></div>
               <div></div>
            </div>   
         
            <div className="layer foreground">
               { isBackVisible &&
                  <div className="back">
                     <button
                        className={ ( appContext.backButtonEnabled ) ? undefined : "hidden" }
                        onClick={ appContext.backButtonOnClick }>
                        <div className="chevron-container">
                           <div className="chevron"></div>
                        </div>
                     </button>
                  </div>
               }
               
               <div className="logo">
                  <button onClick={ handle_logo_button }>
                     <div ref={ headerLogoRef } className="logo-container full hidden">
                        <img src={ PntLogoColoredSvg }/>
                     </div>
                  </button>
               </div>
               
               <div className="contact">
                  <div className="contactus-anchor">
                     <ContactUsButton/>
                     
                  </div>
               </div>
            </div>
         </div>
         
         { ( true || typeof window[ "Touch" ] === "undefined" ) &&
            <Cursor ref={ cursorRef } hidden={ isCursorHidden } background={ currentCursorBackground } positionPublisher={ pointerPosPublisher } indicator={ cursorIndicator }/>
         }
         
         { appContext.overlays.length > 0 &&
            <div className="overlays">
               {
                  appContext.overlays.map( overlay => 
                     React.cloneElement( overlay.component, { key: overlay.id } )
                  )
               }
            </div>
         }
         
         <div className={ `copyright${ ( !appContext.isCompactSizeClass || sectionIndex == 0 ) ? "" : " hidden" }` }>
            <p>
               { `© Pixel and Texel, 2011-${ ( new Date() ).getFullYear() }.` }
            </p>
         </div>
         
         { BUILD_ENV_DEBUG &&
            <p className="debug page-width">
               { `${ appContext.layoutDimensions.width }px${ ( appContext.layoutSizeClass == LayoutSizeClass.COMPACT ) ? " [compact]" : "" }` }
            </p>
         }
      </div>
   );
} );

export default AppView;
