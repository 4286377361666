import React, { useEffect, useRef } from 'react'
import styled from "styled-components";

import AppContext from "./../../../contexts/appcontext";
import { VisibilityState } from "./../../view";

const Container = styled.div`
  --bold-shadow-size: 2px;
  --bold-shadow-size-inner: 1px;
  --bold-shadow-blur: 1px;
  --bold-selected-weight: 700;
  --selected-font-color: #eadc5c;
  --background-color: white;

  @keyframes itemSlideInFromLeft {
    0% {
      opacity: 0;
      transform: translateX(+50%);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }

  font-family: Futura PT, serif;
  width: 100%;
  height: 100%;
  
  display: flex;
  align-items: end;
`;

const Content = styled.div`
  width: 100%;
  height: 80%;
  
  .compact & {
    height: 100%;
  }
  
  overflow: scroll;
`;

const Items = styled.div`
  width: 100%;
  
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: 100px;
  
  @media screen and (max-width: 1024px) {
    :not(.compact &) {
      grid-auto-rows: 60px;
    }
  }
  
  @media screen and (min-width: 1025px) and (max-width: 1919px) {
    :not(.compact &) {
      grid-auto-rows: 70px;
    }
  }
  
  .compact & {
    grid-auto-rows: 70px;
    padding-bottom: 10vh;
    
    @supports (padding: max(0px)) {
      padding-bottom: max(10vh, calc(env(safe-area-inset-bottom) + 20px));
    }
  }
  
  @media screen and (min-width: 450px) {
    .compact & {
      grid-auto-rows: 50px;
    }
  }
`;

const WorkItem = styled.div`
  display: flex;
  align-items: center;

  // padding-left: 5vw;
  // min-height: 5.35vmax;
  
  box-sizing: border-box;
  
  .compact & {
    padding: 0 10%;
  }
  
  @media screen and (min-width: 450px) {
    .compact & {
      padding: 0 15%;
    }
  }

  & > p {
    font-size: 46pt;
    font-weight: ${(props) => (props.selectedItem === props.item ? 'var(--bold-selected-weight)' : '400')};
    
    color: ${(props) => (props.selectedItem === props.item ? 'var(--selected-font-color)' : 'white')};
    
    padding: 0;
    margin: 0;
    
    white-space: nowrap;
    
    transition: font-weight 0.1s ease-in-outt;
  }
  
  @media screen and (max-width: 1024px) {
    :not(.compact &) > p {
      font-size: 25pt;
    }
  }
  
  @media screen and (min-width: 1025px) and (max-width: 1919px) {
    :not(.compact &) > p {
      font-size: 32pt;
    }
  }
  
  .compact & > p {
    font-size: 28pt;
  }
  
  animation: 0.5s ease-out 0s 1 itemSlideInFromLeft;
  opacity: 0;
  animation-delay: calc(${(props) => parseFloat(props.key)} * 0.1)s;

  // Keep animation last frame
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards
  
  cursor: auto;
  :hover > p {
    font-weight: var(--bold-selected-weight);
  }

  // This could be more dynamic with SASS
  // but since we only have a couple of items on view on load, 
  // it really doesn't matter
  :nth-child(1) { animation-delay: 0.05s }
  :nth-child(2) { animation-delay: 0.10s }
  :nth-child(3) { animation-delay: 0.15s }
  :nth-child(4) { animation-delay: 0.20s }
  :nth-child(5) { animation-delay: 0.25s }
  :nth-child(6) { animation-delay: 0.30s }
  :nth-child(7) { animation-delay: 0.35s }
  :nth-child(8) { animation-delay: 0.40s }
  :nth-child(9) { animation-delay: 0.45s }
  :nth-child(10) { animation-delay: 0.50s }
  :nth-child(11) { animation-delay: 0.55s }
  :nth-child(12) { animation-delay: 0.60s }
  :nth-child(13) { animation-delay: 0.65s }
  :nth-child(14) { animation-delay: 0.70s }
  :nth-child(15) { animation-delay: 0.75s }
  :nth-child(16) { animation-delay: 0.80s }
  :nth-child(17) { animation-delay: 0.85s }
  :nth-child(18) { animation-delay: 0.90s }
`;


const WorkServicesList = React.forwardRef((props, forwardedRef) => {
  const appContext = new AppContext();
  
  const viewRef = ( typeof forwardedRef !== "undefined" && forwardedRef != null ) ? forwardedRef : useRef( null );
  
  const contentRef = useRef(null);
  const itemsRef = useRef(null);
  
  const selectedItem = (typeof props.selectedIdx !== "undefined" && props.items && props.selectedIdx < props.items.length) ? props.items[props.selectedIdx] : undefined;
  
  useEffect(() => {
    // we want the list to be bisected at the bottom so the user knows
    // that there more content if they scroll.
    
    if (contentRef.current != null && itemsRef.current != null && Array.isArray(props.items) && props.items.length > 0) {
      const content = contentRef.current;
      
      const items = itemsRef.current;
      const first = items.querySelector("div:first-of-type");
      
      const firstTop = content.offsetTop - first.offsetTop;
      
      const count = (content.clientHeight - firstTop) / first.clientHeight;
      
      let bisectCount = Math.floor(count) + 0.6;
      
      if (count < bisectCount) {
        bisectCount -= 1;
      }
      
      const adjustedTop = content.clientHeight - (bisectCount * first.clientHeight);
      const requiredMargin = adjustedTop - firstTop;
      
      items.style.marginTop = `${ requiredMargin.toFixed(2) }px`;
    }
  }, [contentRef, itemsRef, props.items]);
  
  // un-select the current selection when returning while in compact navigation.
  
  useEffect(() => {
    if (appContext.isCompactSizeClass && props.visibility == VisibilityState.MOVING_IN && props.selectedIdx >= 0 && props.pushed !== true) {
      props.onSelection(-1);
    }
  }, [props.selectedIdx, props.visibility, props.pushed]);
  
  return (
    <Container ref={viewRef}>
      <Content ref={contentRef} className="no-scrollbar">
        <Items ref={itemsRef}>
          {props.items.map((item, idx) => {
            return (
              <WorkItem key={idx} item={item} selectedItem={selectedItem} onClick={() => props.onSelection(idx)}>
                <p>{item}</p>
              </WorkItem>
            );
          })}
        </Items>
      </Content>
    </Container>
  )
});

export default WorkServicesList;
