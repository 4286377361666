import React, { useEffect, useRef, useState } from 'react';

import * as Animate from './../base/animate';

import './../../css/modalcontainerview.css';

/**
 * Displays a centered component with a darkened backdrop.
 */
const ModalContainerView = React.forwardRef((props, forwardedRef) => {
  const viewRef =
    typeof forwardedRef !== 'undefined' && forwardedRef != null
      ? forwardedRef
      : useRef(null);

  const [dismiss, setDismiss] = useState(false);

  function handle_pointerdown(event) {
    if (!viewRef.current || event.target == viewRef.current) {
      setDismiss(true);
    }
  }
  
  function handle_dismiss() {
    setDismiss(true);
  }

  useEffect(() => {
    if (viewRef.current) {
      Animate.transition(viewRef.current, '-no-reveal');
    }
  }, [viewRef]);

  useEffect(() => {
    if (viewRef.current) {
      const view = viewRef.current;
      view.addEventListener('pointerdown', handle_pointerdown);

      return function cleanup() {
        view.removeEventListener('pointerdown', handle_pointerdown);
      };
    }
  }, [viewRef]);

  useEffect(() => {
    if (viewRef.current && dismiss) {
      const view = viewRef.current;

      Animate.transition(view, 'dismiss')
      .then( () => {
        if (props.onDismiss) {
          props.onDismiss();
        }
      } );
    }
  }, [viewRef, dismiss, props.onDismiss]);

  return (
    <div ref={viewRef} className='modalcontainerview no-reveal'>
      {
        React.Children.map( props.children, child => {
          return React.cloneElement( child, {
            dismissModal: handle_dismiss
          } )
        } )
      }
    </div>
  );
});

export default ModalContainerView;
