( function() {
   if ( typeof Array.prototype.replace === "undefined" ) {
      /**
       * Returns a shallow copy of an array that has elements removed or replaced.
       *
       * @param {Number} start The index at which to start changing array.
       * @param {Number} deleteCount An integer indicating the number of elements in the array to remove from start.
       * @param {Any} items The elements to add to the array, beginning from start.
       *
       * @returns {Array} Shallow copy array with elements removed or replaced.
       */
      Array.prototype.replace = function( start, deleteCount, ...items ) {
         const before = this.slice( 0, start );
         const after = this.slice( Math.min( start + deleteCount, this.length ) );
         
         const result = before.concat( items, after );
         return result;
      }
   }
   
   if ( typeof Array.prototype.shuffle === "undefined" ) {
      /**
       * Returns a randomly shuffled copy of the array.
       *
       * @returns {Array} Shallow shuffled copy of the original array.
       */
      Array.prototype.shuffle = function shuffle() {
         const length = this.length;
         const copy = new Array( length );
         
         if( length > 0 ) {
            copy[ 0 ] = this[ 0 ];
         
            for( let i = 1; i < length; ++i ) {
               // using Fisher-Yates algorithm
               // REF: http://en.wikipedia.org/wiki/Fisher-Yates_shuffle
         
               const swapIndex = Math.floor( Math.random() * ( i + 1 ) );
         
               copy[ i ] = copy[ swapIndex ];
               copy[ swapIndex ] = this[ i ];
            }
         }
         
         return copy;
      }
   }
} )();
