import { useState } from "react";

import AppContext from "./../contexts/appcontext";
import DisplayTimer from "./displaytimer";
import * as Interpolation from "./interpolation";

const defaultOptions = Object.freeze( {
   duration: 1
} );

/**
 * Manages scrolling between sections.
 */
function useScrollManager( containerRef, getOffsets, options = {}, initialSection = 0 ) {
   const appContext = new AppContext();
   
   /**
    * Currently visible section index.
    * @type {Number}
    */
   const [ sectionIndex, setSectionIndex ] = useState( initialSection );
   
   /**
    * Function to cancel the current scroll (only valid while scrolling is in progress).
    * @type {Function}
    */
   const [ cancelScroll, setCancelScroll ] = useState( null );
   
   /**
    * Scrolling timer when active.
    * @type {DisplayTimer}
    */
   const [ scrollingTimer, setScrollingTimer ] = useState( null );
   
   const combinedOptions = Object.assign( {}, defaultOptions, options );
   
   /**
    * Returns the correct duration from the options depending on what's been
    * specified and what the site's current size class is.
    *
    * @returns {Number} Duration in seconds.
    */
   function get_duration() {
      if ( !appContext.isCompactSizeClass ) {
         return combinedOptions.duration;
      }
      else {
         const duration = ( combinedOptions.compactDuration ) ? combinedOptions.compactDuration : combinedOptions.duration;
         return duration;
      }
   }
   
   /**
    * Scrolls to the specified section.
    *
    * @param {Number} index Index of the section to be scrolled to.
    * @param {Boolean} animated True for smooth scroll, false for instant scroll.
    * @param {Boolean} force True to force scroll (only when animated is false).
    */
   function scrollto_section( index, animated = true, force = false ) {
      return new Promise( ( resolve, reject ) => {
         if ( containerRef.current && ( force || index != sectionIndex ) && scrollingTimer == null ) {
            const container = containerRef.current;
            const sectionOffsets = getOffsets( container );
            
            if ( sectionOffsets && index < sectionOffsets.length ) {
               const endOffset = sectionOffsets[ index ];
               
               if ( animated === true && !force ) {
                  const startOffset = sectionOffsets[ sectionIndex ];
                  const offsetDelta = endOffset - startOffset;
                  
                  const animationTimer = DisplayTimer.timer( ( timer, elapsedTime, isFinished ) => {
                     const percent = elapsedTime / timer.duration;
                     const interpolatedValue = Interpolation.easeInOut( percent );
                     
                     const offset = startOffset + ( offsetDelta * interpolatedValue );
                     
                     container.scrollTo( {
                        left: offset,
                        behavior: "instant"
                     } );
                     
                     if ( isFinished ) { 
                        setSectionIndex( index );
                        setScrollingTimer( null );
                        setCancelScroll( null );
                        
                        resolve();
                     }
                  }, get_duration() );
                  
                  setScrollingTimer( animationTimer );
                  setCancelScroll( () => () => animationTimer.stop() );
               }
               else if ( animated == false ) {
                  container.scrollTo( {
                     left: endOffset,
                     behavior: "instant"
                  } );
                  
                  setSectionIndex( index );
                  resolve();
               }
            }
            else {
               if ( BUILD_ENV_DEBUG ) {
                  console.warn( `Attempted to scroll to section out of range: ${ index }(${ ( sectionOffsets ) ? sectionOffsets.length : 0 })` );
               }
               
               reject();
            }
         }
      } );
   }
   
   return [ sectionIndex, scrollto_section, cancelScroll ];
}

export {
   useScrollManager
}
