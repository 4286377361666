/**
 * Delays for the specified number of seconds.
 *
 * @param {Number} seconds Seconds to delay.
 *
 * @returns {Promise}
 */
function wait(seconds) {
	return new Promise(resolve => {
		setTimeout(resolve, seconds * 1000)
	})
}

/**
 * Delays until the next animation frame.
 */
function untilNextFrame() {
  return new Promise( resolve => {
    requestAnimationFrame( resolve );
  } );
}

export {
  wait,
  untilNextFrame 
}
