import Vector2 from "./../base/math/vector2";

( function() {
   if ( window[ "PointerEvent" ] ) {
      if ( typeof PointerEvent.prototype.screenVec2 === "undefined" ) {
         Object.defineProperty( PointerEvent.prototype, "screenVec2", {
            get() {
               return new Vector2( this.screenX, this.screenY );
            }
         } );
      }
      
      if ( typeof PointerEvent.prototype.clientVec2 === "undefined" ) {
         Object.defineProperty( PointerEvent.prototype, "clientVec2", {
            get() {
               return new Vector2( this.clientX, this.clientY );
            }
         } );
      }
      
      if ( typeof PointerEvent.prototype.pageVec2 === "undefined" ) {
         Object.defineProperty( PointerEvent.prototype, "pageVec2", {
            get() {
               return new Vector2( this.pageX, this.pageY );
            }
         } );
      }
   }
} )();
