import { makeApiRequest } from "./request";

import { valueAtKeyPath } from "./utilities";

/**
 * Returns the work items associated with each logo letter.
 *
 * @param {Function} onProgress Will be called with progress updates.
 *
 * @returns {Array} Array containing the request promise and the request abort function.
 */
function getLogoLetterWorkItems( onProgress ) {
   const [ pending, abortRequest ] = makeApiRequest( "logoletters", null, onProgress );
   
   const promise = pending.then( response => {
      const fields = valueAtKeyPath( response, "fields" );
      if ( fields ) {
         let missingEntries = [];
         
         const entries = Array.from( Object.entries( fields ) ).reduce( ( result, [ key, value ] ) => {
            if ( key != "name" ) {
               const workItemId = valueAtKeyPath( value, "sys.id" );
               
               if ( workItemId ) {
                  result.push( [ key, workItemId ] );
               }
               else {
                  missingEntries.push( key );
               }
            }
            
            return result;
         }, [] );
         
         if ( missingEntries.length == 0 ) {
            const items = Object.fromEntries( entries );
            return Promise.resolve( items );
         }
         else {
            return Promise.reject( new Error( `One or more letters were missing entries: [${ missingEntries.join( ", " ) }]` ) );
         }
      }
      else {
         return Promise.reject( new Error( "The format of the response to 'getLogoLetterWorkItems' was incorrect." ) );
      }
   } );
   
   return [ promise, abortRequest ];
}

export {
   getLogoLetterWorkItems
}
