import React, { useEffect, useRef, useState } from "react";

import AppContext from "./../contexts/appcontext";

import * as Animate from "./../base/animate";
import * as Delay from "./../base/delay";

import "./../../css/control-cursor.css";

/**
 * Indicator animation transition state.
 * @enum {String}
 */
const TransitionState = Object.freeze( {
   FADE_OUT: "fade out",
   
   /** Fading original content out. */
   FADING_OUT: "fading out",
   
   FADE_IN: "fade in",
   
   /** Fading new content in. */
   FADING_IN: "fading in",
   
   /** Transition complete. */
   DONE: "done"
} );

/**
 * Custom cursor.
 */
const Cursor = React.forwardRef( ( props, forwardedRef ) => {
   const appContext = new AppContext();
   
   /**
    * Reference to the cursor's main element.
    */
   const mainRef = ( typeof forwardedRef !== "undefined" && forwardedRef != null ) ? forwardedRef : useRef( null );
   
   /**
    * Current definition of indicator being displayed.
    */
   const [ indicator, setIndicator ] = useState( { type: "dot" } );
   
   /**
    * Indicator to be displayed during the fade-in transition.
    */
   const [ nextIndicator, setNextIndicator ] = useState( null );
   
   /**
    * Current transition state.
    */
   const [ transition, setTransition ] = useState( TransitionState.DONE );
   
   const [ onRender, setOnRender ] = useState( null );
   
   /**
    * Cursor middle indicator element.
    */
   const indicatorElemRef = useRef( null );
   
   /**
    * Reference to the background canvas element.
    */
   const backgroundRef = useRef( null );
   
   useEffect( () => {
      const indicatorType = ( typeof props.indicator !== "undefined" && props.indicator && !/^\s*$/.test( props.indicator ) ) ? props.indicator.type.trim().toLowerCase() : "dot";
      
      const dataDiffers = ( typeof props.data === "undefined" && typeof indicator.data === "undefined" ) || props.data != indicator.data;
      
      if ( indicatorType != indicator.type && dataDiffers ) {
         const options = ( typeof props.indicator === "object" && "options" in props.indicator && typeof props.indicator[ "options" ] === "object" ) ? props.indicator[ "options" ] : null;
         
         if ( !options || !( "immediate" in options ) || options.immediate !== true ) {
            setNextIndicator( { type: indicatorType, data: props.indicator.data } );
            
            switch ( transition ) {
               case TransitionState.DONE:
               case TransitionState.FADING_IN: {
                  setTransition( TransitionState.FADE_OUT );
                  break;
               }
               
               case TransitionState.FADE_IN:
               case TransitionState.FADE_OUT:
               case TransitionState.FADING_OUT: {
                  // intentionally blank
                  break;
               }
               
               default: {
                  if ( BUILD_ENV_DEBUG ) {
                     console.warn( `Cursor encountered an invalid transition state '${ transition }'.` );
                  }
                  break;
               }
            }
         }
         else {
            setIndicator( { type: indicatorType, data: props.indicator.data } );
         }
      }
   }, [ props.indicator, props.data ] );
   
   useEffect( () => {
      if ( indicatorElemRef.current ) {
         const element = indicatorElemRef.current;
         
         switch ( transition ) {
            case TransitionState.FADE_OUT: { 
               Animate.transition( element, "hidden" )
               .then( () => {
                  setTransition( TransitionState.FADE_IN );
                  setIndicator( nextIndicator );
               } );
               
               setTransition( TransitionState.FADING_OUT );
               break;
            }
            
            case TransitionState.FADING_IN:
            case TransitionState.FADING_OUT:
            case TransitionState.DONE: {
               // intentionally blank
               break;
            }
            
            case TransitionState.FADE_IN: {
               Delay.untilNextFrame()
               .then( () => Animate.transition( element, "-hidden" ) )
               .then( () => {
                  setTransition( TransitionState.DONE );
               } );
               
               setNextIndicator( null );
               
               setTransition( TransitionState.FADING_IN );
               break;
            }
            
            default: {
               if ( BUILD_ENV_DEBUG ) {
                  console.warn( `Cursor transition encountered an invalid transition state '${ transition }'.` );
               }
               break;
            }
         }
      }
   }, [ transition, indicatorElemRef ] );
   
   /**
    * Updates the contents in the context.
    *
    * @param {CanvasRenderingContext2D} context Context to render into.
    * @parma {Number} width Width of the canvas.
    * @param {Number} height Height of the canvas.
    */ 
   function render_in_context( context, width, height, offsetX, offsetY ) {      
      context.clearRect( 0, 0, width, height );
      context.drawImage( props.background, -offsetX, -offsetY );
   }
   
   function update( context, width, height, pointerX, pointerY ) {
      const main = mainRef.current;
      
      const halfWidth = main.clientWidth / 2;
      const halfHeight = main.clientHeight / 2;
      
      if ( pointerX && pointerY ) {
         main.style.left = `${ pointerX - halfWidth  }px`;
         main.style.top = `${ pointerY - halfHeight }px`;
      }
      
      context.clearRect( 0, 0, width, height );
      
      if ( props.background ) {
         const background = props.background;
         
         // I'm not sure why the -3 is needed and why it makes it look right :( magic numbers 1 : billy 0 (Billy 9-19-2022).
         
         const offsetLeft = background.offset.x + halfWidth - 3;
         const offsetTop = background.offset.y + halfHeight - 3;
         
         context.translate( -pointerX + offsetLeft, -pointerY + offsetTop );
         context.scale( background.scale, background.scale );
         
         context.drawImage( background.image, 0, 0 );
         
         context.resetTransform();
      }
   }
   
   useEffect( () => {
      if ( props.positionPublisher && backgroundRef.current && mainRef.current ) {
         const canvas = backgroundRef.current;
         const context = canvas.getContext( "2d" );
         
         const width = canvas.clientWidth;
         const height = canvas.clientHeight;
         
         const halfWidth = width / 2;
         const halfHeight = height / 2;
         
         context.beginPath();
         context.ellipse( halfWidth, halfHeight, halfWidth, halfHeight, 0, 0, Math.PI * 2 );
         context.closePath();
         
         context.clip();
         
         const onUpdate = update.bind( null, context, width, height );
         setOnRender( onUpdate );
         
         props.positionPublisher.addListener( onUpdate );
         
         return function cleanup() {
            if ( props.positionPublisher ) {
               props.positionPublisher.removeListener( onUpdate );
               setOnRender( null );
            }
         }
      }
   }, [ props.positionPublisher, mainRef, backgroundRef, props.background ] );
   
   // handle cases where the background is remove but the cursor has not
   // moved and needs to be redrawn.
   
   useEffect( () => {
      if ( backgroundRef.current && !props.background ) {
         const canvas = backgroundRef.current;
         const context = canvas.getContext( "2d" );
         
         const width = canvas.clientWidth;
         const height = canvas.clientHeight;
         
         context.clearRect( 0, 0, width, height );
      }
   }, [ backgroundRef, props.background ] );
   
   useEffect( () => {
      if ( onRender && mainRef.current ) {
         onRender();
      }
   }, [ onRender, appContext.layoutDimensions, mainRef ] );
   
   /**
    * Returns the correct component for the specified indicator.
    */
   function indicatorComponent() {
      let component;
      
      switch ( indicator.type ) {
         case "dot": {
            component = ( <div className="dot"></div> );
            break;
         }
         
         case "play": {
            component = (
               <div>
                  <div className="icon">
                     <div className="icon-play"></div>
                  </div>
               </div>
            );
            break;
         }
         
         case "text": {
            component = ( <p>{ indicator.data }</p> );
            break;
         }
         
         default: {
            component = ( <></> );
            
            if ( BUILD_ENV_DEBUG ) {
               console.warn( `'${ indicator }' is not a valid indicator.` );
            }
            break;
         }
      }
      
      if ( component ) {
         const updatedClassName = ( component.props.className ) ? component.props.className.split( " " ) : [];
         
         if ( transition == TransitionState.FADE_IN || transition == TransitionState.FADING_IN ) {
            updatedClassName.push( "hidden" );
         }
         
         component = React.cloneElement( component, {
            ref: indicatorElemRef,
            className: updatedClassName.join( " " )
         } );
      }
      
      return component;
   }
   
   /** @type {Array<String>} */
   const classes = [];
   
   if ( props.hidden === true ) {
      classes.push( "hidden" );
   }
   
   const isBackgroundEnabled = true;
   
   return (
      <div ref={ mainRef } id="cursor" className={ classes.join( " " ) }>
         { isBackgroundEnabled &&
            <div className="layer">
               <canvas ref={ backgroundRef } width="96" height="96"></canvas>
            </div>
         }
         <div className="layer">
            { indicatorComponent() }
         </div>
      </div>
   );
} );

export default Cursor;
